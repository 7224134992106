/* --------------------------------------*/
/* COMMON SYTLES STARTS */
/* -------------------------------------*/


/* font family style starts */
/* -------------------------*/
@font-face {
    font-family: America;
    src: url(../fonts/GT-America-Standard-Regular.woff);
  }
  @font-face {
    font-family: AmericaMedium;
    src: url(../fonts/GT-America-Standard-Medium.woff);
  }
  @font-face {
    font-family: AmericaCompressed;
    src: url(../fonts/GT-America-Compressed-Bold.woff);
  }

  @font-face {
    font-family: Source Sans Pro;
    src: url(../fonts/SourceSansPro-Regular.woff);
  }

  @font-face {
    font-family: Source Sans Pro Light;
    src: url(../fonts/SourceSansPro-Light.woff);
  }

  @font-face {
    font-family: Source Sans Pro Bold;
    src: url(../fonts/SourceSansPro-Bold.woff);
  }
  
  .ff-ss-black{
      font-family: America;
  }
  
  .ff-ss-el{
      font-family: America;
  }
  
  .ff-ss-l{
      font-family: America;
  }
  
  .ff-ss-r{
      font-family: America;
  }
  
  .ff-ss-b{
      font-family: America;
  }
  
  .ff-ss-sb{
      font-family: America;
  }
  /* font family style ends */
  /* -----------------------*/
  
  
  /* font weight style starts */
  /* -------------------------*/
  body
  {
      font-family: America;
      color: #28323B;
  }
  .font-weight300
  {
      font-weight: 300 !important;
  }
  .fw-semi-bold{
      font-weight: 400 !important;
  }
  .fw-400{
      font-weight: 400;
  }
  .fw-500, .fw500{
      font-weight: 500;
      font-family: 'AmericaMedium';
  }
  .font-weight-500
  {
      font-weight: 500;
      font-family: 'AmericaMedium';
  }
  .fw-600{
    font-weight: 500;
    font-family: 'AmericaMedium';
  }
  .fw600{
    font-weight: 500;
    font-family: 'AmericaMedium';
  }
  .fw-only-600{
    font-weight: 600;
  }
  .font-weight-600
  {
    font-weight: 500;
    font-family: 'AmericaMedium';
  }
  .fw-700{
      font-weight: 700;
  }
  .font-weight-700 {
      font-weight: 700;
  }
  /* font weight style ends */
  /* -----------------------*/
  
  
  /* font size style starts */
  /* -----------------------*/
  .fs0_6em{
      font-size: 0.6em;
  }
  .fs0_85em{
      font-size: .85em;
  }
  .fs1_25em{
      font-size: 1.25em;
  }
  .fs1_75em{
      font-size: 1.75em;
  }
  .fs2em{
      font-size: 2em;
  }
  .fs3em{
      font-size: 3em;
  }
  .fs36{
      font-size: 36px;
  }
  .font11
  {
      font-size: 11px !important;
  }
  .fs12 {
      font-size: 12px !important;
  }
  .fontSize12 
  {
      font-size: 12px;
  }
  .fontSize15 
  {
      font-size: 15px;
  }
  .fs-12px{
      font-size: 12px;
  }
  .fs13 {
      font-size: 13px;
  }
  .fontSize14
  {
      font-size: 14px !important;
  }
  .fontSize13
  {
      font-size: 13px !important;
  }
  .fs14
  {
      font-size: 14px !important;
  }
  .fs15
  {
      font-size: 15px !important;
  }
  .fs16{
      font-size: 16px;
  }
  .font16
  {
      font-size: 16px !important;
  }
  .fs18 {
      font-size: 18px;
  }
  .fs17 {
      font-size: 17px !important;
  }
  
  .fontSize19 
  {
      font-size: 19px;
  }
  
  .fs20{
      font-size: 20px !important;
  }
  .fs22{
      font-size: 22px !important;
  }
  .fs23{
      font-size: 23px !important;
  }
  .fs24{
      font-size: 24px;
  }
  .fs30{
  
      font-size: 30px;
  }
  .fs32{
  
      font-size: 32px;
  }
  .fs35 {
      font-size: 35px;
  }
  .fs40{
      font-size: 40px;
  }
  
  /* font size style ends */
  /* ---------------------*/
  
  
  /* width style starts */
  /* -------------------*/
  
  .w1p{
      width: 1%;
  }
  .w3p{
      width: 3%;
  }
  .w5p{
      width: 5%;
  }
  .w6p{
      width: 6%;
  }
  .w7p{
      width: 7%;
  }
  .w8p{
      width: 8%;
  }
  .w8 {
      width: 8px;
  }
  .w10p{
      width: 10%;
  }
  .w10px{
      width: 10px;
  }
  .w12p{
      width: 12%;
  }
  .w14p{
      width: 14%;
  }
  .w15p{
      width: 15% !important;
  }
  .w15px{
      width: 15px !important;
  }
  .w18p{
      width: 18%;
  }
  .w18px{
    width: 18px !important;
  }
  .w20p{
      width: 20%;
  }
  .w20px{
      width: 20px;
  }
  .w180px{
      width: 180px;
  }
  .w21p{
      width: 21%;
  }
  .w20px{
      width: 20px;
  }
  .w23p {
      width: 23%;
  }
  .w24p{
      width: 24% !important;
  }
  .w25p{
      width: 25% !important;
  }
  .w25px{
      width: 25px !important;
  }
  .w30p{
      width: 30%;
  }
  .w29p{
      width: 29%;
  }
  .w30px{
      width: 30px !important;
  }
  .width30p{
      width: 30%;
  }
  .w33p{
      width: 33%;
  }
  .w35p{
      width: 35% !important;
  }
  .width-40per{
      width: 40%;
  }
  .w40p{
      width: 40%;
  }
  .w40px{
      width: 40px;
  }
  .w42p{
      width: 42%;
  }
  .w44p{
      width: 44%;
  }
  .w45p{
      width: 45%;
  }
  .w47p{
      width: 47%;
  }
  .w50p{
      width: 50% !important;
  }
  .width50p{
      width: 50%;
  }
  .w35px{
      width: 35px;
  }
  .w50px{
      width: 50px;
  }
  .w50p{
      width: 50%;
  }
  .w55p{
      width: 55%;
  }
  .w55 {
      width: 55px !important;
  }
  .w60p{
      width: 60% !important;
  }
  .w60{
      width: 60px;
  }
  .w65p{
      width: 65%;
  }
  .w70p{
      width: 70% !important;
  }
  .w70px{
      width: 70px;
  }
  .width70p{
      width: 70%;
  }
  .width90p{
      width: 90%;
  }
  .w74p{
      width: 74% !important;
  }
  
  .w75p{
      width: 75% !important;
  }
  .w77p{
      width: 77% !important;
  }
  .w80p{
      width: 80%;
  }
  .w85p{
      width: 85%;
  }
  .w90p{
      width: 90%;
  }
  .w90px{
      width: 90px;
  }
  .w100px{
      width: 100px;
  }
  .w95p{
      width: 95%;
  }
  .w100p{
      width: 100% !important;
  }
  
  .w120px{
      width: 120px;
  }
  .w125px{
      width: 125px !important;
  }
  
  .w200px{
      width: 200px !important;
  }
  .w250px{
      width: 250px !important;
  }
  .w300px{
      width: 300px !important;
  }
  
  .width-100-perc{
      width: 100%;
  }
  .w145 {
      width: 145px !important;
  }
  .w1{
      width: 1px; 
  }
  /* width style ends */
  /* -----------------*/
  
  /* height style starts */
  /* -------------------*/
  .height-100-perc, body, app-root, html, .content-wrap{
      height: 100%;
  }
  
  .height-50-perc{
      height: 50%;
  }
  
  .height-45-perc{
      height: 45%;
  }
  
  .height-24{
      height: 24px;
  }
  
  .height-55{
      height: 55px
  }
  
  .h35p{
      height: 35px;
  }

  .h300p{
      height: 300px !important;
  }

  .h360p{
      height: 360px !important;
  }

  .h400p{
      height: 400px !important;
  }
  .h40p{
      height: 40px !important;
  }

  .h440p{
      height: 440px;
  }
  .h465p{
      height: 465px;
  }
  .h80p{
      height: 80%;
  }
  .h85p{
      height: 85%;
  }
  .h90p{
      height: 90%;
  }
  
  .height41
  {
      height: 41px !important;
  }
  
  .h40{
      height: 40px;
  }
  /* height style ends */
  /* ------------------*/
  
  /* padding style starts */
  /* ---------------------*/
  .p0{
      padding: 0px !important;
  }
  
  .p10{
      padding: 10px;
  }
  .p15{
      padding: 15px !important;
  }
  .p20
  {
      padding: 20px; 
  }
  .p24
  {
      padding: 24px; 
  }
  .p25
  {
      padding: 25px; 
  }
  .p30
  {
      padding: 30px !important;
  }
  
  .p50
  {
      padding: 50px !important; 
  }
  
  .p40
  {
      padding: 40px !important; 
  }
  
  /* padding top starts */
  .pt0{
      padding-top: 0px !important;
  }
  .pt3{
      padding-top: 3px;
  }
  .pt1em{
      padding-top: 1em !important;
  }
  
  .pt2em{
      padding-top: 2em !important;
  }
  .pt2{
      padding-top: 2px !important;
  }
  .pt5{
      padding-top: 5px;
  }
  .pt10{
      padding-top: 10px !important;
  }
  .pt15
  {
      padding-top: 15px !important;
  }
  .pt16
  {
      padding-top: 16px !important;
  }
  .pt20{
      padding-top: 20px !important;
  }
  .pt25{
      padding-top: 25px !important;
  }
  .pt30{
      padding-top: 30px !important;
  }
  .pt35
  {
      padding-top: 35px !important;
  }
  .pt40{
      padding-top: 40px !important;
  }
  .pt50{
      padding-top: 50px !important;
  }
  .pt80{
      padding-top: 80px;
  }
  .pt100{
      padding-top: 100px;
  }
  /* padding top ends */
  
  /* padding right starts */
  .pr0{
      padding-right: 0px !important;
  }
  .pr3{
      padding-right: 3px;
  }
  .pr5{
      padding-right: 5px;
  }
  .pr10{
      padding-right: 10px;
  }
  .pr15{
      padding-right: 15px !important;
  }
  .pr16{
      padding-right: 16px !important;
  }
  .pr20 {
      padding-right: 20px !important;
  }
  .pr25 {
      padding-right: 25px !important;;
  }
  .pr30{
      padding-right: 30px !important;
  }
  .pr32{
      padding-right: 32px !important;
  }
  .pr40{
      padding-right: 40px !important;
  }
  .pr50{
      padding-right: 50px !important;
  }
  /* padding right ends */
  
  /* padding bottom starts */
  .pb1em{
      padding-bottom: 1em !important;
  }
  .pb2em{
      padding-bottom: 2em !important;
  }
  .pb8{
      padding-bottom: 8px !important;
  }
  .pb16{
      padding-bottom: 16px !important;
  }
  .pb5{
      padding-bottom: 5px !important;
  }
  .pb10{
      padding-bottom: 10px !important;
  }
  .pb12{
      padding-bottom: 12px !important;
  }
  .pb15{
      padding-bottom: 15px !important;
  }
  .pb15{
      padding-bottom: 15px !important;
  }
  .pb20{
      padding-bottom: 20px !important;
  }
  .pb24{
      padding-bottom: 24px;
  }
  .pb25{
      padding-bottom: 25px;
  }
  .pb30{
      padding-bottom: 30px !important;
  }
  .pb40{
      padding-bottom: 40px;
  }
  .pb45{
      padding-bottom: 45px !important;
  }
  .pb50{
      padding-bottom: 50px;
  }
  .pb100{
      padding-bottom: 100px !important;
  }
  .pb300{
      padding-bottom: 300px !important;
  }
  /* padding bottom ends */
  
  /* padding left starts */
  .pl0{
      padding-left: 0px !important;
  }
  .pl3{
      padding-left: 3px !important;
  }
  .pl5{
      padding-left: 5px;
  }
  .pl5p{
      padding-left: 5%;
  }
  .pl2{
      padding-left: 2px;
  }
  .pl6{
    padding-left: 6px !important;
  }
  .pl10{
      padding-left: 10px !important;
  }
  .pl15{
      padding-left: 15px !important;
  }
  .pl16{
      padding-left: 16px !important;
  }
  .pl20{
      padding-left: 20px !important;
  }
  .pl25{
      padding-left: 25px !important;
  }
  .pl30{
      padding-left: 30px;
  }
  .pl32{
      padding-left: 32px !important;
  }
  .pl35{
      padding-left: 35px;
  }
  .pl-30px{
      padding-left: 30px;
  }
  .pl40{
      padding-left: 40px !important;
  }
  .pl50{
      padding-left: 50px !important;
  }
  .pl56{
      padding-left: 57px !important;
  }
  .pl60{
      padding-left: 60px !important;
  }
  .pl62{
      padding-left: 62px !important;
  }
  .pl65{
      padding-left: 65px !important;
  }
  .pl70{
      padding-left: 70px !important;
  }
  .pl-70px{
      padding-left: 70px;
  }
  .pl60
  {
      padding-left: 60px;
  }
  .pl100{
      padding-left: 100px;
  }

  .pl150{
    padding-left: 150px;
  }
  
  /* padding left ends */
  
  /* padding style ends */
  /* -----------------*/
  
  /* margin style starts */
  /* ---------------------*/
  .m0{
      margin: 0px;
  }
  
  .m0-auto{
      margin: 0px auto;
  }
  
  /* margin top starts */
  .mtinit
  {
      margin-top: inherit !important;
  }

  .mtn2{
      margin-top: -2px !important;
  }
  
  .mtn3{
      margin-top: -3px !important;
  }
  
  .mtn5{
      margin-top: -5px;
  }
  .mtn10{
      margin-top: -10px;
  }
  .mtn50{
      margin-top: -50px;
  }
  .t0 {
      top: 0px !important;
  }
  .mt0 {
      margin-top: 0px !important;
  }
  .mt3
  {
      margin-top: 3px;
  }
  .mt5{
      margin-top: 5px !important;
  }
  .mt6{
      margin-top: 6px !important;
  }
  .mt7
  {
      margin-top: 7px;
  }
  .mt8
  {
      margin-top: 8px;
  }
  .mt10 {
      margin-top: 10px !important;
  }
  .mt11 {
      margin-top: 11px !important;
  }
  .mt13
  {
      margin-top: 13px;
  }
  .mt24
  {
      margin-top: 24px;
  }
  .mt15{
      margin-top: 15px !important;
  }
  .mt19 {
      margin-top: 19px !important;
  }
  
  .mt16 {
      margin-top: 16px !important;
  }
  
  .mt20 {
      margin-top: 20px !important;
  }
  .mt22 {
      margin-top: 22px !important;
  }
  .mt25
  {
      margin-top: 25px !important;
  }
  .mt30 {
      margin-top: 30px !important;
  }
  .mt35 {
      margin-top: 35px !important;
  }
  .mt40{
      margin-top: 40px;
  }
  .mt47
  {
      margin-top: 47px;
  }
  .mt50{
      margin-top: 50px;
  }
  .mt55{
      margin-top: 55px !important;
  }
  .mt65
  {
      margin-top: 65px;
  }
  .mt70
  {
      margin-top: 70px;
  }
  .mt80
  {
      margin-top: 80px;
  }
  .mt100
  {
      margin-top: 100px;
  }
  .mtn15{
      margin-top: -15px;
  }
  .mt-n20px{
      margin-top: -20px;
  }
  .mtn170{
      margin-top: -170px;
  }
  .mtn230{
      margin-top: -230px;
  }
  .mtn250{
      margin-top: -250px;
  }
  .mtn340{
      margin-top: -340px;
  }
  .mtn390{
      margin-top: -390px;
  }
  /* margin top ends */
  
  /* margin right starts */
  .mrn20{
      margin-right: -20px;
  }
  .mr25{
      margin-right: 25px;
  }
  .mr0{
      margin-right: 0px !important;
  }
  .mr3pr{
      margin-right: 3%;
  }
  .mr2 {
      margin-right: 2px !important;
  }
  .mr5 {
      margin-right: 5px !important;
  }
  
  .mr8 {
      margin-right: 8px;
  }
  
  .mr10
  {
      margin-right: 10px !important;
  }
  .mr15
  {
      margin-right: 15px !important;
  }
  .mr16
  {
      margin-right: 16px !important;
  }
  .mr20{
      margin-right: 20px;
  }
  .mr30
  {
      margin-right: 30px !important;
  }
  .mr40
  {
      margin-right: 40px !important;
  }
  .mr35
  {
      margin-right: 35px !important;
  }
  .mr30pr{
      margin-right: 30%;
  }
  .mr150{
      margin-right: 150px;
  }
  /* margin right ends */
  
  /* margin bottom starts */
  .mb0{
    margin-bottom: 0px !important;
  }
  .mb55{
      margin-bottom: 55px;
  }
  .mb7{
      margin-bottom: 7px;
  }
  .mb70{
      margin-bottom: 70px;
  }
  .mb5{
      margin-bottom: 5px !important;
  }
  .mr7 {
      margin-right: 7px !important;
  }
  .mb8 {
      margin-bottom: 8px !important;
  }
  .mb10 
  {
      margin-bottom: 10px !important;
  }
  .mb10
  {
      margin-bottom: 10px;
  }
  .mb15{
      margin-bottom: 15px !important;
  }
  .mb16{
      margin-bottom: 16px;
  }
  .mb160{
      margin-bottom: 160px;
  }
  .mb17{
      margin-bottom: 17px;
  }
  .mb18{
      margin-bottom: 18px;
  }
  .mb20{
      margin-bottom: 20px !important;
  }
  .mb2
  {
    margin-bottom: 2px;
  }
  .mb25 
  {
      margin-bottom: 25px !important;
  }
  .mb28 
  {
      margin-bottom: 28px !important;
  }
  .mb30{
      margin-bottom: 30px !important;
  }
  .mb32{
      margin-bottom: 32px;
  }
  .mb150{
      margin-bottom: 150px !important;
  }
  
  .mbn5{
      margin-bottom: -5px;
  }
  .mbn10{
      margin-bottom: -10px;
  }
  .mbn30{
      margin-bottom: -30px;
  }
  /* margin bottom ends */
  
  /* margin left starts */
  .ml0{
      margin-left: 0px !important;
  }
  .mln4{
      margin-left: -4px !important;
  }
  .ml5
  {
      margin-left: 5px;
  }
  .ml7 {
      margin-left: 7px !important;
  }
  .ml8 {
      margin-left: 8px !important;
  }
  .ml10
  {
      margin-left: 10px;
  }
  .ml-10px{
      margin-left: 10px !important;
  }
  .ml15
  {
      margin-left: 15px !important;
  }
  .ml15p{
  
      margin-left: 15%;
  }
  .ml20
  {
      margin-left: 20px;
  }
  .ml20px
  {
      margin-left: 20px !important;
  }
  .ml30
  {
      margin-left: 30px;
  }
  .ml35
  {
      margin-left: 35zpx;
  }
  .ml40
  {
      margin-left: 40px;
  }
  .ml-20px{
      margin-left: 20px;
  }
  .ml25{
      margin-left: 25px !important;
  }
  .ml35{
      margin-left: 35px;
  }
  .ml45{
      margin-left: 45px;
  }
  .ml50
  {
      margin-left: 50px;
  }
  .ml60
  {
      margin-left: 60px;
  }
  .ml70
  {
      margin-left: 70px;
  }
  .ml-70px{
      margin-left: 70px;
  }
  
  .mln4
  {
      margin-left: -4px !important;
  }
  .mln10{
      margin-left: -10px !important;
  }
  
  .mln20{
      margin-left: -20px;
  }
  .mln33{
      margin-left: -33px;
  }
  .bt0{
      border-top: 0px !important;
  }
  /* margin left ends */

  .r40{
      right: 40px;
  }

  .r0{
      right: 0px;
  }

  .r30{
    right: 30px;
    }

  .l12{
      left: 12px !important;
  }
  
  /* margin style ends */
  /* ---------------------*/
  
  .word-wrap {
      overflow-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-all;
  }

  .white-space-nowrap{
      white-space: nowrap;
  }
  
  /* --------------------------------------*/
  /* COMMON SYTLES ENDS */
  /* -------------------------------------*/
  
  
  /* -----------------------------------------------------*/
  /* utility style starts */
  /* -----------------------------------------------------*/
  
  .cursor-pointer{
      cursor: pointer;
  }
  
  .cursor-default{
      cursor: default !important;
  }
  
  a:hover, a:focus{
      text-decoration: none !important;
      outline: 0;
  }
  .ng-hide.ng-hide-animate{
       display: none !important;
  }
  
  .border-right-light{
      border-right: 1px solid rgba(153, 153, 153, 0.2)
  }
  .border-botton-light{
      border-bottom: 1px solid rgba(153, 153, 153, 0.2)
  }
  
  .separation-line{
      height: 1px;
      background: rgba(153, 153, 153, 0.2);
      margin: 50px 0px 20px 0px;
  }
  .lh12{
      line-height: 12px !important;
  }
  .lh18{
      line-height: 18px !important;
  }
  .lh24{
      line-height: 24px !important;
  }
  .lh30{
      line-height: 30px !important;
  }
  .lh32{
      line-height: 32px !important;
  }
  .lh36{
      line-height: 36px !important;
  }
  .lh45{
      line-height: 45px !important;
  }
  
  .br2p{
      border-radius: 2px !important;
  }
  .br3p{
      border-radius: 3px !important;
  }
  
  td span.fa-circle-o,
  td span.fa-check{
      font-size: 18px;
  }
  .initial-position {
    position: inherit !important;
}
  .circle-point{
      content: "";
      position: relative;
      left: -10px;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
  }
  
  .align-center {
      text-align: center !important;
  }

  .left13{
      left: 13px !important;
  }
  
  .left35{
      left: 35px !important;
  }
  
  .ta-center{
      text-align: center;
  }
  
  .ta-left{
      text-align: left;
  }
  
  .blue_a_tag{
      text-decoration: none;
  }
  
  .not-active{
      cursor: default;
      pointer-events: none;
      text-decoration: none;
      opacity: 0.6;
  }
  
  .ajaxLoader
  {
      font-size: 17px;
      padding-left: 46%;
  }
  
  .ajaxLoader .loaderSpinner 
  {
      float: left;
      margin-top: 2px;
      margin-right: 10px;
      border-radius: 23px;
      border-width: 12px;
      width: 18px;
      height: 18px;
      z-index: 2000;
      border: solid 2px transparent;
      border-top-color: #5d8fc2;
      border-left-color: #5d8fc2;
      -moz-animation: spin 400ms linear infinite;
      animation: spin 400ms linear infinite;
  }
  
  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
  
  .capitalize
  {
      text-transform: capitalize !important;
  }
  
  .clear-left{
      clear: left;
  }
  
  .clear-both{
      clear: both;
  }
  
  .floatl
  {
      float: left;
  }
  .align-right 
  {
      text-align: right!important;
  }
  
  .align-left {
      text-align: left !important;
  }
  .overflow-auto
  {
      overflow: auto;
  }
  
  .display-inline
  {
      display: inline-block;
  }
  
  .floatr
  {
      float: right;
  }
  
  .breadcrumb{
      display: none;
  }
  
  .mb40
  {
      margin-bottom: 40px;
  }
  .mb4
  {
      margin-bottom: 4px;
  }
  .white
  {
      color: white;
  }
  .mtm53{
      margin-top: -53px;
  }
  .height15
  {
      height: 15px;
  }
  .width14
  {
      width: 14px !important;
  }
  .width15
  {
      width: 15px;
  }
  .recompute_parent_div{
      font-size: 25px;
      color: #fff;
      position: fixed;
      top: 15%;
      left: 40%;
  }
  .recompute_div{
      display: inline-block;
      margin-top: 250px !important;
  }
  
  .recompute_lock{
      z-index: 9;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      position: fixed;
      border-radius: 3px;
      background-color: #000;
      opacity: 0.6;
  }
  
  .pt6{
      padding-top: 6px;
  }
  .top2{
      top: 2px !important;
  }
  .top4{
      top: 4px !important;
  }
  .top14{
      top: 14px !important;
  }
  .top30{
      top: 30px !important;
  }
  .top40{
      top: 40px !important;
  }
  .top80{
      top: 80px !important;
  }

  .top46{
      top: 46px !important;
  }

  .top50{
      top: 50px !important;
  }

  .top96{
      top: 96px !important;
  }

  .top105{
      top: 105px !important;
  }

  .top154{
      top: 154px !important;
  }
  
  .simple_table tr, .simple_table tr:hover
  {
      background-color: white !important;
  }
  .simple_table td, .simple_table th
  {
      border-top: none !important;
      border-bottom: none !important;
  }
  .pt8
  {
      padding-top: 8px;
  }
  .mt120{
      margin-top: 120px;
  }
  .opacity_6{
      opacity: 0.6;
  }
  .billingInfoTable .help-block
  {
      color: #dd5826;
  }
  .agreement_button, team_agreement_button
  {
      border: none;
      background-color: rgb(11,143,224);
      font-weight: 600;
  }
  .dateRange {
      border-radius: 4px;
  }
  
  .dateRange {
      border-radius: 4px;
  }
  
  #manage-project-details tr, #manage-project-details tr:hover {
      background-color: white;
  }
  #manage-project-details td, #manage-project-details th{
      border-top: 1px solid white;
      border-bottom: 1px solid white;
  }
  #manage-project-details .form-control, #manage-project-details .help-block
  {
      width: 95%;
      float: right;
  }
  
  #manage-project-details .dataInputLoaderSpinner
  {
      float: left !important;
  }
  
  .message-banner, .message-banner-v4_1 {
      background: #242424;
      color: #fff;
      -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.2) inset;
      -moz-box-shadow: 0 1px 2px rgba(0,0,0,.2) inset;
      box-shadow: 0 -1px 10px rgba(0,0,0,.5) inset;
      border: none;
      text-align: center;
      font-size: 14px;
      padding: 10px 0px;
      z-index: 999;
      position: fixed;
      width: 100%;
      opacity: 0.85;
      display: none;
      top: 0px;
      padding-top: 15px;
  }
  
  .deactivateInput
  {
      border: none;
      background: none;
  }
  
  .deactivateSelect{
      cursor: default;
      pointer-events: none;
      text-decoration: none;
      opacity: 0.6 !important;
  }
  
  button{
      outline: 0 !important;
  }
  
  .uppercase {
      text-transform: uppercase;
  }
  
  .lowercase {
      text-transform: lowercase;
  }
  
  .showError
  {
      float: right;
      margin-top: 6px;
      color: #dd5826;
      cursor: pointer;
      margin-right: 10px;
  }
  .showErrorPortfolio
  {
      float: right;
      margin-top: 0px;
      color: #dd5826;
      cursor: pointer;
      margin-right: 10px;
      font-size: 11px;
  }
  .uploadLoaderSpinner
  {
      float: left;
      margin-top: 5px;
      margin-right: 5px;
      border-radius: 23px;
      border-width: 12px;
      width: 9px;
      height: 9px;
      z-index: 2000;
      border: solid 2px transparent;
      border-top-color: darkgray;
      border-left-color: darkgray;
      -moz-animation: spin 400ms linear infinite;
      animation: spin 400ms linear infinite;
  }
  .affirmationLoaderSpinner {
      float: left;
      margin-top: 4px;
      margin-left: -35px;
      margin-right: 5px;
      border-radius: 23px;
      border-width: 12px;
      width: 9px;
      height: 9px;
      /* z-index: 2000; */
      border: solid 2px transparent;
      border-top-color: darkgray;
      border-left-color: darkgray;
      -moz-animation: spin 400ms linear infinite;
      animation: spin 400ms linear infinite;
  }
  
  .error-border{
      border: 1px solid #dd5826 !important;
      border-color: #dd5826 !important;
  }
  
  .visibility_hidden{
      visibility: hidden;
  }
  
  .display_inline{
      display: inline;
  }
  
  .display_block{
      display: block;
  }
  
  .display_none{
      display: none;
  }
  
  .display_inline_tbl
  {
      display: inline-table;
  }
  
  .disable-click{
      pointer-events: none;
  }
  
  .bg_none{
      background: none !important;
  }
  
  .vertical_align_middle{
      vertical-align: middle !important;
  }
  
  .vertical-align-middle
  {
      vertical-align: middle !important;
  }
  
  .bold
  {
      font-weight: bold !important;
  }
  
  .verticle_middle{
      vertical-align: middle !important;
  }
  
  .subscript_heading {
      font-size: 14px;
      padding-left: 15px;
  }
  
  .overflow_y_initial{
      overflow-y: initial !important;
  }
  
  .disableDiv
  {
      pointer-events: none;
      cursor: not-allowed;
  }
  .cursor-not-allowed{
    cursor: not-allowed;
  }
  .border1{
      border: 1px solid;
  }
  
  .hr-divider{
      margin-top: 5px;
      margin-bottom: 5px;
      border-top: 1px solid #ddd;
      margin-left: 15px;
      margin-right: 35px;
  }
  
  .hr-score-divider{
      margin-top: 5px;
      margin-bottom: 5px;
      border-top: 1px solid #ddd;
      /*margin-left: 10%;
      margin-right: 25%;*/
      margin-left: 1%;
      margin-right: 5%;
  }
  
  .border-bottom-gray{
      border-bottom: 1px solid #ddd !important;
  }
  .border-bottom-gray-3px{
      border-bottom: 3px solid rgb(208,208,208);
  }

  .gray-border{
    border-color: #767676 !important;
  }

  .cursor-initial{
      cursor: inherit !important;
  }
  .cursor-init{
      cursor: initial !important;
  }
  .user_agreement_body{
      height: 500px;
  }
  
  .border-bottom-none{
      border-bottom: none;
  }
  
  .padding-modal-window{
      padding: 20px 50px 50px 50px;
  }
  
  .fa.fa-pencil, 
  .fa.fa-trash-o,
  .fa.fa-check,
  .fa.fa-times{
      cursor: pointer;
  }
  
  .desktop_flex {
      display: flex;
  }
  
  /* -----------------------------------------------------*/
  /* utility style ends */
  /* -----------------------------------------------------*/
  
  /* -----------------------------------------------------*/
  /* login/tables/myprojects/sidebar starts */
  /* -----------------------------------------------------*/
  main#content{
      overflow-y: hidden;
  }
  
  .home-wrapper {
      display: block;
      position: relative;
      min-height: 200px;
      width: 100%;
      padding: 50px;
      height: 55%;
      background: url('../images/LEED+image_full.png') no-repeat center;
      background-size: cover;
  }
  
  /* login css starts */
  .login_close_button, .login_close_button:hover {
      height: 40px;
      width: 40px;
      border-radius: 0px 6px 0px 0px;
      color: inherit;
  }
  
  .login_body {
      margin-top: 40px;
      padding-top: 30px;
      padding: 0px 40px;
      padding-bottom: 40px;
  }
  
  .login_field {
      font-weight: 400;
      font-size: 14px;
      -webkit-font-smoothing: antialiased;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      -webkit-transition: border 300ms linear;
      -moz-transition: border 300ms linear;
      -o-transition: border 300ms linear;
      transition: border 300ms linear;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      height: 40px;
      padding: 10px 16px;
      line-height: 1.3333333;
  }
  
  .login_submit{
      background-color: #555555;
      border: 1px solid #555555;
      color: #f5f5f5;
  }
  
  input.login_field:focus, button.login_field:focus{
      outline: none !important;
      border-color: #ccc !important;
      box-shadow: 0 0 10px #ccc !important;
  }
  /* login css ends */
  
  
  /* parsley starts */
  input.parsley-error {
      border-color: #dd5826;
      box-shadow: inset 0 1px 1px rgba(221,88,38,.075);
  }
  
  .parsley-errors-list li {
      list-style: none;
      color: #dd5826;
  }
  
  .parsley-errors-list {
      font-size: 12px;
      padding-left: 0;
      margin-bottom: 5px;
  }
  /* parsley ends */
  
  /* table css starts */
  .table.dataTable.no-footer{
      border-bottom: 0px !important;
  }
  
  .dataTables_wrapper .dataTables_info{
      font-size: 13px !important;
      color: #b6b6b6 !important;
  }
  
  .dataTables_length, 
  .dataTables_filter, 
  .dataTables_info,
  .dataTables_paginate.paging_simple_numbers{
      display: none !important;
  }
  
  .dataTables_empty{
      display: none;
  }
  
  table.paymentSidebarTable{
      border: 1px solid #ddd;
      border-collapse: separate;
      background: #FEFEFE;
      font-size: 12px;
      color: #666;
      margin-bottom: 1.4em;
      width: 95%;
      -moz-border-radius: 3px;
      border-radius: 3px;
      -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
      -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
      box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
  }
  
  table.paymentSidebarTable th, table.paymentSidebarTable td {
      padding: 14px 10px;
      border-bottom: 1px solid #ddd;
      border-top: 1px solid #fff;
      vertical-align: top;
  }
  
  table.paymentSidebarTable tr th{
      border-right: 1px solid #DDDDDD;
  }
  
  table.paymentSidebarTable th{
      width: 120px
  }
  /* table css ends */
  
  /* sidebar css starts */
  .sidebar-nav li a.collapsed .toggle {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
  }
  
  .sidebar-nav-title{
      margin: 40px 0 15px 11px;
  }
  
  .sidebar-nav li a .label{
      font-size: 10px;
      float: right;
      line-height: 14px;
      margin-top: 0px;
      margin-right: 15px;
      width: 22px;
  }
  
  #sidebar-thisproject{
      margin-right: 2px;
  }
  
  .sidebar-nav > li > ul:before{
      padding-top: 0px;
  }
  
  .sidebar-nav > li > ul:after{
      padding-bottom: 0px;
  }
  
  .sidebar-nav > li > a .icon {
      top: 8px;
  }
  
  #sidebar-selected-project, #sidebar-selected-portfolio{
      pointer-events: none;
      color: #F2BE35;
      background-color: #242424;
      line-height: initial !important;
      padding-top: 8px;
      padding-bottom: 5px;
      min-height: 45px;
      padding-right: 5px;
  }
  
  #sidebar-selected-project span.icon, #sidebar-selected-portfolio span.icon{
      border-radius: 50%; 
      background-color: #F2BE35;
  }
  
  #sidebar-selected-project i.fa-building, #sidebar-selected-portfolio  i.fa-building{
      color: #242424;
  }
  #sidebar-actions{
      height: initial !important;
  }
  
  #sidebar-manage{
      height: initial !important;
  }
  
  /* .active_class{
      color: #fff !important;
      font-weight: normal;
  }
  
  .active_class_aa #all_actions{
      color: #fff;
      font-weight: normal;
  }
  
  .active_class_aa #credits_actions{
      color: #fff;
      font-weight: normal;
  } */
  
  /* .active_class_di #data_input{
      color: #fff;
      font-weight: normal;
  }
  
  
  .active_class_di #credits_actions{
      color: #fff;
      font-weight: normal;
  }
  
  .active_class_di #all_actions{
      color: #fff;
      font-weight: normal;
  } */
  
  .hide_all_actions_style{
      font-weight: 100 !important;
      color: #aaa !important;
  }
  /* sidebar css starts */
  
  .submit-for-review-btn{
       background-color: transparent;
      -moz-osx-font-smoothing: auto;
      -webkit-font-smoothing: subpixel-antialiased;
      border: 1px solid #555555;
      color: #555555;
      font-size: 12px;
  }
  
  .submit-for-review-btn:hover{
      background-color: #555555;
      color: #f5f5f5;
  }
  
  .arc-button{
      padding-left: 20px;
      padding-right: 20px;
      text-transform: uppercase;
      border-radius: 0px;
  }
  
  .performance-report-padding{
      padding: 30px 30px;
  }

.font-family-America{
    font-family: 'America' !important;
  }
  
  .arc-label tspan {
    font-size: 14px;
    fill: #414041;
    font-family: 'America';
  }
  
  .arc-label.name tspan {
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'America';
  }
  
  .arc-label.disabled tspan {
    font-size: 14px;
    fill: #999999 !important;
    text-transform: uppercase;
  }
  
  .arc-label.factors tspan {
    font-size: 10px !important;
  }
  
  .arc-label.value tspan {
    font-size: 14px;
    fill: #414041;
    font-family: 'America';
  }
  
  .arc-label.average tspan {
    font-size: 12px;
    fill: #414041;
    font-family: 'America';
  }
  
  .arc-label.max tspan {
    font-size: 14px;
    fill: #000;
    font-family: 'America';
  }
  
  /*LEED starp starts*/
  
  .login-button{
      position: absolute;
      top: 10px;
      right: 10px;
      padding-left: 20px;
      padding-right: 20px;
      text-transform: uppercase;
      font-size: 14px;
      border-radius: 0px;
  }
  
  .leed-btnOutline, .leed-btnOutline:focus {
      background-color: transparent;
      -moz-osx-font-smoothing: auto;
      -webkit-font-smoothing: subpixel-antialiased;
      border: 2px solid #f5f5f5;
      color: #f5f5f5;
  }    
  
  .leed-btnOutline:hover {
      background-color: #f5f5f5;
      color: #181818;
  }
  
  #notification-bg-warning{
      background-color: #dd5826;
      color: white;
  }
  
  #select-year{
      width: 125px;
      padding-left: 15px;
  }
  
  .green-color{
      color: #95bf58
  }
  
  .green-color-energy{
      color: #d0dd3d;
  }
  
  .gray-color{
      color: #555555 !important;
  }
  
  .danger-color{
      color: #dd5826;
  }
  
  .orDivider 
  {
      text-align: center;
      color: darkgrey;
      margin-top: 15px;
      margin-bottom: 15px;
  }
  
  #pay-by-check{
      display: block; 
      border-bottom: 1px solid rgba(128, 128, 128, 0.25);
      padding-bottom: 10px;
  }
  
  .dropdown-menu .divider
  {
      margin: 4px 0;
  }
  
  .drpToText{
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      appearance: none !important;
      border: none !important;
      cursor: default;
      pointer-events: none;
      text-decoration: none;
      background: transparent;
  }
  .rotate-slow
  {
      animation: spin 1500ms linear infinite;
  }
  #iframe
  {
      width: 100%;
      border: none;
      height: 200px;
  }
  #paymetric_iframe{
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    height:0;
    width:0;
    border: none;
  }
  
  #manage-project-details tr td{
      vertical-align: middle !important;
  }
  
  #actions-form textarea{
      width: 98%;
      height: 100px;
      margin-left: 10px;
  }
  
  .green-color {
      color: #50AE54 !important;
  }
  
  .orange-color {
      color: #F5A623 !important;
  }
  
  .content_container
  {
      margin: 0 auto;
      width: 5%;
      /* margin-left: 0px; */
  }
  .carbon-color{
    color: rgb(151, 61, 44);
  }
  .pre-certification-icon{
      height: 20px;
      line-height: 20px;
  }
  
  .pre-certification_modal_body{
      padding-top: 15px;
      padding-bottom: 30px;
      padding-left: 30px;
      padding-right: 30px;
  }
  
  .pre-certification-line{
      border-bottom: 1px solid rgba(128, 128, 128, 0.16);
      padding-bottom: 3px;
      margin-bottom: 10px;
  }
  
  .middle_ware_loading
  {
    font-size: 25px;
    width: 100%;
    height: 100%;
    background-color: white;
    text-align: center;
  }
  
  .load_middle_ware_loading
  {
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #f1f1f1;
      font-size: 25px;
      padding-left: 45%;
      padding-top: 20px;
      z-index: 1;
  }
  
  .middle_ware_loading .uploadLoaderSpinner, .load_middle_ware_loading .uploadLoaderSpinner
  {
      margin-top: 10px;
      width: 15px;
      height: 15px;
  }
  .certification-cards .row{
      background: #f5f5f5;
      padding-top: 7px;
      padding-bottom: 16px;    
      margin-left: -10px;
      margin-right: -10px;
      margin-top: 10px;
      border-radius: 6px
  }
  
  .certification-cards .cert-title{
      margin-top: 7px;
      font-size: 16px;
  }
  
  .certification-cards .cert-points{
      font-size: 36px;
  }
  
  .certification-cards .cert-point-text{
      font-size: 16px;
  }
  
  .certification-cards .cert-date{
      margin-top: 15px;
  }
  
  .certification-cards .cert-status{
      margin-top: -18px;
      clear: left;
  }
  
  select::-ms-expand 
  {
      display: none;
  }
  
  .pattern-validation{
      margin-top: -10px;
      margin-bottom: -10px;
  }
  
  .btn-no-style{
      background: none;
      border: none;
  }
  
  .feedback_title
  {
      width: 100%;
  }
  
  #panel 
  {
      background-color: #f1f1f1;
      border: 1px solid #b3b3b3;
      left: 46%;
      margin-left: -205px;
      padding: 5px;
      position: absolute;
      top: 20px;
      z-index: 5;
      border-radius: 5px;
  }
  
  .city-data-field{
      -webkit-appearance: none;
      appearance: none;
      width: 70px;
      margin-top: -5px;
      font-weight: 600;
      font-size: 12px;
      border-radius: 4px;
      border: 1px solid rgba(128, 128, 128, 0.33);
      height: 30px
  }
  
  .searchDropdown, 
  .searchDropdownPortfolio {
    position: absolute;
    top: 40px;
    left: 0px;
    width: 100%;
    background-color: white;
    border-radius: 0px 0px 3px 3px;
    font-size: 14px;
    border-bottom: 1px solid lightgrey;
    padding: 10px 20px;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
  }
  .searchDropdownPort {
    position: absolute;
    top: 40px;
    left: 0px;
    width: 100%;
    background-color: white;
    border-radius: 0px 0px 3px 3px;
    font-size: 14px;
    border-bottom: 1px solid lightgrey;
    padding: 10px 0px;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
  }

  .searchDropdownPort .projectPort_row:hover
  {
    background-color: #eee;
  }
  .searchDropdownPort .projectPort_row
  {
    padding: 10px 15px;
  }

  .searchDropdown table th, .searchDropdown table td
  {
      padding-left: 0px;
      border-bottom: none !important;
      border-top: none !important;
      padding: 0px !important;
  }
  .searchDropdown table tbody td
  {
    padding: 8px 8px 8px 0px !important;
  }
  .searchDropdown table tr
  {
    background-color: white !important;
  }
  .searchDropdown table tr:hover
  {
    background-color: #F4F5F8 !important;
  }
  
  .searchDropdownPort, .searchDropdownPortfolio
  {
      z-index: 3;
      top: 33px !important;
  }
  
  .searchLabels {
    color: #28323B;
    font-weight: 500;
    font-family: AmericaMedium;
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .searchCategory
  {
      width: 100%;
      float: left;
      max-height: 300px;
      overflow: auto;
  }
  
  .lineSearch
  {
      float: left;
      width: 83%;
      margin-top: 11px;
      border-bottom: 1px solid lightgrey;
  }
  
  .searchName
  {
      float: left;
      width: 17%;
      color: darkgrey;
      margin-top: 2.5px;
      margin-bottom: 2.5px;
  }
  
  .searchResults
  {
      float: left;
      width: 100%;
      margin-top: 2.5px;
      margin-bottom: 2.5px;
      cursor: pointer;
      padding: 1px;
  }
  
  .searchResults:hover
  {
      background-color: #f1f1f1;
  /*
      padding: 5px;
      font-size: 15px;
  */
  }
  
  #unitTypeSelect sup{
      cursor: pointer;
  }
  
  #unitTypeSelect sup .popover{
      top: -50px;
      left: 15px;
      display: block;
      width: 250px;
      background: white;
      border: 1px solid rgba(128, 128, 128, 0.32);
  }
  
  .form-wizard ul.nav-justified{
      pointer-events: none;
  }
  .project_agreement_row{
      height: 90px;
      background-color: transparent !important;
  }
  
  .bt_ddd{
      border-top: 1px solid #ddd !important;
  }
  
  .LEEDOnline_tag, .LEEDOnline_tag:hover, LEEDOnline_tag:focus{
      color: #555555 !important;
  }
  
  .checkbox label:before {
      margin-top: 1px;
  }
  
  .certification_green{
      color: #609936 !important;
  }
  
  li.list_style_none{
      list-style: none;
  }
  
  .manage_team{
      width: 66.66%;
  }
  
  span.seach_suggestion{
      width: 33%;
      float: left;
  }
  
  .visible-xs.certification-cards .project_agreement_row
  {
      float: left;
      margin-top: 20px;
      height: initial !important;
  }
  
  .visible-xs.certification-cards .text_row
  {
      width: 60%;
  }
  
  .visible-xs.certification-cards .button_row
  {
      text-align: right;
  }
  
  .visible-xs .searchDropdown
  {
      top: 60px !important;
  }
  
  /* dcdemo-v2 changes */
  
  .myprojects_tbl .dataTables_paginate.paging_simple_numbers,
  .myprojects_tbl .dataTables_info{
      display: block !important;
      margin-top: 10px;
  }
  
  .myprojects_tbl .dataTables_length{
      display: block !important;
      margin-top: 18px;
      margin-left: 30px;
      font-size: 13px !important;
      color: #b6b6b6 !important;
  }
  
  .dataTables_paginate .paginate_button.current{
      color: white !important;
      border: none !important;
      background: #D6D6D6 !important;
  }
  
  .dataTables_paginate .paginate_button:hover{
      color: white !important;
      border: none !important;
      background: #D6D6D6 !important;
  }
  
  .credit_comment_box{
      margin-bottom: -7px;
  }
  
  .credit_comment_box .credit_comment .circle{
      background: #ddd; 
      width: 10px; 
      height: 10px;
  }
  
  .credit_comment_box .credit_verb{
      border-left: 3px solid #ddd; 
      margin-left: 3px; 
      min-height: 20px;
  }
  
  .credit_comment_box .author{
      margin-left: 12px;
  }
  
  .add_meter_backdrop
  {
      padding: 30px;
      background-color: rgba(238,238,238,1);
      border-radius: 2px;
  }
  
  .add_meter_backdrop input
  {
      background-color: white !important;
  }
  
  .add_meter_backdrop input:focus
  {
      border: 1px solid #333 !important;
  }
  
  .smallScorepuck
  {
      text-align: center;
      padding-top: 5px;
      height: 36px;
      width: 36px;
      float: left;
      border-radius: 20px;
      background-image: url(../images/leed-logo.png);
      background-repeat: no-repeat;
      background-color: rgb(137,138,137);
      background-size: 35px 33px;
      background-position: 1px 2px;
  }
  .smallScorepuckBlank
  {
      text-align: center;
      padding-top: 5px;
      height: 36px;
      width: 36px;
      float: left;
      border-radius: 20px;
  /*    background-image: url(../images/leed-logo.png);*/
      background-repeat: no-repeat;
      background-color: rgb(137,138,137);
      background-size: 35px 33px;
      background-position: 1px 2px;
  }
  .smallScorepuck span, .smallScorepuckBlank span
  {
      color: white;
      font-size: 18px;
      font-weight: 600;
      font-family: 'AmericaCompressed';
  }
  
  .noProjects,
  .noTeamMembers {
      font-size: 18px;
      text-align: center;
      margin-bottom: 25px;
      margin-top: 40px;
      color: grey;
  }
  
  .searchDropdownOrganization .projectPort_row:hover
  {
      background-color: #eee;
  }
  .searchDropdownOrganization .projectPort_row
  {
    padding: 10px 15px;
    cursor: pointer;
  }
  .searchDropdownOrganization_nav, .searchDropdownOrganization {
      position: absolute;
      top: 42px;
      border-bottom: 1px solid #eee;
      z-index: 3;
      width: 100%;
      background-color: white;
      border-bottom: 1px solid lightgrey;
      padding: 10px 0px;
      border-left: 1px solid lightgrey;
      border-right: 1px solid lightgrey;
      border-radius: 0px 0px 3px 3px;
      font-size: 14px;
  }
  
  /* -----------------------------------------------------*/
  /* login/tables/myprojects/sidebar style ends */
  /* -----------------------------------------------------*/
  
  /* -----------------------------------------------------*/
  /* racetrack/unpacking starts */
  /* -----------------------------------------------------*/
  
  /* grid view css starts */
  
  .grid_view_container .score_grid{
      /* width: 30%; */
      margin-right: 30px;
      margin-top: 30px;
      padding: 15px;
  }
  
  .grid_racetrack_container{
      width: 750px;
      height: 750px;
      position: relative;
      left: 50%;
      margin-left: -375px;
      cursor: pointer;
  }
  
  .grid_racetrack{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
  }
  
  .grid-total-name-puck {
      border-radius: 82px;
      background-color: #ddd;
      width: 164px;
      height: 164px;
  }
  
  .grid-total-name-puck > .text {
    height: 100%;
    width: 100%;
    text-align: center;
    font-family: 'America';
    font-size: 90px;
    padding-top: 14%;
    color: white;
  }
  
  .grid-score-puck {
      background-image: url("../images/score-puck.svg");
      width: 170px;
      height: 170px;
      background-size: 100% 100%;
      margin: auto;
  }
  
  .score_image_container{
      text-align: center;
  }
  
  #racetrack_container, #racetrack_container_arc_score{
      width: 750px;
      height: 750px;
      position: relative;
      left: 50%;
      margin-left: -375px;
      cursor: pointer;
  }
  /* grid view css ends */
  
  #racetrack_overview,
  #racetrack_overview_arc_score,
  #racetrack_energy,
  #racetrack_water,
  #racetrack_waste,
  #racetrack_transportation,
  #racetrack_humanexperience,
  #racetrack_base{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
  }
  
  #racetrack_overview > svg,
  #racetrack_overview_arc_score > svg,
  #racetrack_base > svg,
  #racetrack_energy > svg,
  #racetrack_water > svg,
  #racetrack_waste > svg,
  #racetrack_transportation > svg,
  #racetrack_humanexperience > svg
  {
      cursor: pointer;
  }
  
  .score_puck_container{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;    
  }
  
  #score_puck_container_total, #score_puck_container_total_arc_score{
      padding-top: 38.65%;
  }
  
  #score_puck_container_energy,
  #score_puck_container_water,
  #score_puck_container_waste,
  #score_puck_container_transportation,
  #score_puck_container_humanexperience{
      padding-top: 39%;
  }
  
  #score_puck_container_base{
      padding-top: 28%;
  }
  
  #score-puck, #score-puck_arc_score {
      background-image: url("../images/score-puck.svg");
      width: 170px;
      height: 170px;
      background-size: 100% 100%;
      margin: auto;
  }
  
  #name-puck-energy,
  #name-puck-water,
  #name-puck-waste,
  #name-puck-transportation,
  #name-puck-humanexperience,
  #name-puck-base{
      margin: auto;
  }
  
  .total-name-puck,
  .name-puck {
      border-radius: 82px;
      background-color: #ddd;
      width: 164px;
      height: 164px;
  }
  
  .base-name-puck {
      border-radius: 160px;
      background-color: #ddd;
      position: relative;
      width: 330px;
      height: 330px;
  }
  
  .base-name-puck .text{
      text-align: center;
      font-family: 'America';
      text-transform: uppercase;
      font-size: 14px;
      padding-top: 48%;
  }
  
  .total-name-puck > .text {
    height: 100%;
    width: 100%;
    text-align: center;
    font-family: 'AmericaCompressed';
    font-size: 90px;
    padding-top: 14%;
    color: white;
  }
  
  .name-puck > .text{
    text-align: center;
    font-family: 'America';
    text-transform: uppercase;
    font-size: 14px;
    padding-top: 45%;
  }
  
  .racetrack_grid{
      transform: scale(.5);
      height: 400px;
      margin-left: -41%;
      margin-top: -30%;
      margin-bottom: 30%;
  }
  
  #track_container{
      padding-top: 8%;
      background: #f1f1f1;
      width: 750px;
      float: left;
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding-left: 70px;
  }
  
  .name-puck.water > .text {
    color: #55caf5;
  }
  
  .name-puck.water > .text:after {
    content: "Current Water";
  }
  
  .name-puck.waste > .text {
    color: #84ccaf;
  }
  
  .name-puck.waste > .text:after {
    content: "Current Waste";
  }
  
  .name-puck.energy > .text {
    color: #d0dd3d;
  }
  
  .name-puck.energy > .text:after {
    content: "Current Energy";
  }
  
  .name-puck.transport > .text {
    color: #a39f92;
    font-size: 14px;
    padding-top: 40%;
  }
  
  .name-puck.transport > .text:after {
    content: "Current Transportation";
  }
  
  .name-puck.human > .text {
    color: #f2ac41;
    padding-top: 40%;
  }
  
  .name-puck.human > .text:after {
    content: "Current Human Experience";
  }
  
  .energy-bg{
    background-color: #D0DD3D;
  }
  
  .scope1_raw_ghg-bg{
  
      background-color: rgb(191, 191, 191);
  }
  .scope2_raw_ghg-bg{
  
      background-color: rgb(217, 217, 217);
  }
  .total_raw_transit-bg{
  
      background-color: #A39F92;
  }
  
  .water-bg{
    background-color: #55CAF5;
  }
  
  .waste-bg{
    background-color: #84CCAF;
  }
  
  .transportation-bg, .transport-bg{
    background-color: #A39F92;
  }
  
  .human-bg, .human_experience-bg{
    background-color: #F2AC41;
  }
  
  .base-bg{
    background-color: #f25b63;
  }
  
  .energy-color{
    color: #D0DD3D;
  }
  
  .water-color{
    color: #55CAF5;
  }
  
  .waste-color{
    color: #84CCAF;
  }
  
  .transportation-color{
    color: #A39F92;
  }
  
  .human-color{
    color: #F2AC41;
  }
  
  .add-preferences{
      float: right;
      font-size: 25px;
      width: 45px;
      height: 35px;
      color: #64bd63;
      background: #eee;
      border-radius: 5px;
      border: 1px solid #ddd;
      text-align: center;
      padding-top: 3px;
      cursor: pointer;
  }
  
  .preference_added{
      background: #f0b518;
  }
  
  .category-score .init-score{
      color: #666;
      font-size: 1.25em;
      font-weight: 600;
      padding-right: 0px;
      margin-left: 10px; 
      padding-top: 10px;
  }
  
  .category-score .total-score{
      color: #666;
      font-weight: 200;
      /* font-size: .75em; */
      /* padding-left: 0px; */
      padding-top: 43px;
  }
  
  .unpacking_score .total-score{
      color: #666;
      font-weight: 200;
      font-size: .75em;
      padding-left: 0px;
      padding-top: 28px;
  }
  
  .category-score .progress{
      background-color: rgba(221, 221, 221, 0.6);
      border-radius: 0px;
      height: 35px; 
      width: 100%;
      margin-left: 10px;
  }
  
  .category-score-goal .progress{
      
      height: 55px; 
      
  }
  
  .category-score .progress img{
      position: absolute;
      left: 20px;
  }
  
  .unpacking_score .category-score .progress img{
      left: 32px !important;
  }
  
  .category-score .progress img.energy{      
      margin-top: 7px;
      width: 13px;
  }
  
  .category-score .progress img.water{
      margin-top: 7px;
      width: 12px;
  }
  
  .category-score .progress img.waste{
      margin-top: 10px;
      width: 17px;
  }
  
  .category-score .progress img.transport{
      margin-top: 10px;
      width: 19px;
  }
  
  .category-score .progress img.human{
      margin-top: 8px;
      width: 9px;
  }
  
  .category-score .progress .category-label{
      position: absolute;
      left: 37px;
      margin-top: 8px;
      font-size: 13px;
      color: #414041;
      font-family: 'America';
      white-space: nowrap;
  }
  
  .unpacking_score .category-score .progress .category-label{
      left: 55px !important;
  }
  
  .category-score .progress .category-label-small{
      position: absolute;
      left: 20px;
      margin-top: 7px;
      font-size: 10px;
      color: #414041;
      font-family: 'America';
      width: 50%;
  }
  
  .category-score .progress .category-label-1{
      position: absolute;
      /* margin-top: 8px; */
      font-size: 13px;
      color: #414041;
      font-family: 'America';
      white-space: nowrap;
      right: 0px;
      top: 34px;
  }
  .category-score .progress .category-label-2{
      position: absolute;
      /* margin-top: 8px; */
      font-size: 13px;
      color: #414041;
      font-family: 'America';
      white-space: nowrap;
      right: -9px;
      top: 54px;
  }
  
  .human_sub_categories .category-score .progress .category-label{ 
      left: 35px; 
  }
  
  .category-score .category-label-total,
  .category-score .category-label-base{
      margin-left: -25px;
  }
  
  .category-score .category-label-total{
      color: #fff !important;
  }
  
  .category-score .progress-bar{
      border-radius: 0px;
      text-align: right; 
      padding-right: 10px;     
      padding-top: 8px;
      color: #666;
      font-size: 1em;
      font-weight: 600;
  }
  
  /** new progress bar **/
  
  .progress-label-energy,
  .progress-label-carbon,
  .progress-label-water,
  .progress-label-waste,
  .progress-label-transport,
  .progress-label-human_experience,
  .progress-label-co2,
  .progress-label-occupant_satisfaction,
  .progress-label-voc,
  .progress-label-base,
  .progress-label-generated_waste,
  .progress-label-satisfaction_score,
  .progress-label-source_energy,
  .progress-label-carbon_energy,
  .progress-label-aqi,
  .progress-label-ridership
  {
      float: left;
      /* margin-left: 50%; */
      color: #414041;
      font-weight: normal; 
      font-size: 14px; 
      margin-top: 7px;
      width: 16%;
      /*text-shadow: 1px 1px 0 #fff;*/
  }
  
  .progress-label-energy-small,
  .progress-label-carbon-small,
  .progress-label-water-small,
  .progress-label-waste-small,
  .progress-label-transport-small,
  .progress-label-human_experience-small,
  .progress-label-co2-small,
  .progress-label-occupant_satisfaction-small,
  .progress-label-voc-small,
  .progress-label-base-small,
  .progress-label-generated_waste-small,
  .progress-label-satisfaction_score-small
  {
      float: left;
      /* margin-left: 50%; */
      color: #414041;
      font-weight: normal; 
      font-size: 10px; 
      margin-top: 7px;
      width: 16%;
      /*text-shadow: 1px 1px 0 #fff;*/
  }
  
  .progress-label-carbon-1,
  .progress-label-energy-1,
  .progress-label-water-1,
  .progress-label-generated_waste-1,
  .progress-label-transit-1,
  .progress-label-satisfaction_score-1
  {
      float: left;
      position: absolute;
      /* margin-left: 50%; */
      color: #414041;
      font-weight: normal; 
      font-size: 14px; 
      margin-top: 30px;
      /*text-shadow: 1px 1px 0 #fff;*/
  }
  
  
  .progress-label-total
  {
      float: left;
      color: #fff;
      font-weight: normal; 
      font-size: 14px; 
      margin-top: 7px;
  }
  
  .category-score .progressbar{
      border-radius: 0px;
      border: none;
      color: #666;
  }
  
  .progress .ui-widget-content{
      background: none;
      height: 100%;
  }
  
  .progress .ui-progressbar-value{
      border: none;
      border-radius: 0px;
      margin: 0px !important;
  }
  
  #progress-bar-total .ui-progressbar-value {
     background: #7f7f7f;
  }
  
  #progress-bar-energy .ui-progressbar-value, #progress-bar-carbon_energy .ui-progressbar-value, #progress-bar-source_energy .ui-progressbar-value {
     background: #D0DD3D;
  }
  
  #progress-bar-water .ui-progressbar-value {
     background: #55CAF5;
  }
  
  #progress-bar-waste .ui-progressbar-value, #progress-bar-generated_waste .ui-progressbar-value {
     background: #84CCAF;
  }
  
  #progress-bar-transport .ui-progressbar-value {
     background: #A39F92;
  }
  
  #progress-bar-base .ui-progressbar-value {
     background: #f25b63;
  }
  
  #progress-bar-carbon .ui-progressbar-value {
     background: #333;
  }
  
  #progress-bar-human_experience .ui-progressbar-value,
  #progress-bar-occupant_satisfaction .ui-progressbar-value,
  #progress-bar-satisfaction_score .ui-progressbar-value,
  #progress-bar-co2 .ui-progressbar-value,
  #progress-bar-voc .ui-progressbar-value,
  #progress-bar-aqi .ui-progressbar-value,
  #progress-bar-ridership .ui-progressbar-value
   {
     background: #F2AC41;
  }
  
  .category-score .progress-bar-human_experience,
  .category-score .progress-bar-co2,
  .category-score .progress-bar-occupant_satisfaction,
  .category-score .progress-bar-satisfaction_score,
  .category-score .progress-bar-ridership,
  .category-score .progress-bar-aqi,
  .category-score .progress-bar-voc{
      background: #F2AC41;
  }
  
  .annual-carbon-consm-label{
      font-size: 1.15em;
      color: #414041;
      font-family: 'America';
      white-space: nowrap;
      font-weight: 600;
  }
  
  .annual-carbon-consm-value{
      font-size: 18px;
      color: #414041;
      font-weight: 400;
  }
  
  /** new progress bar **/
  
  
  .bar-column-left {
      padding-right: 0px;
  }
  
  .bar-column-right {
      padding-left: 0px;
  }
  
  .bar-column-inside-right {
      padding-left: 0px;
      padding-right: 0px
  }
  
  .score-zero {
      display: none;
  }
  
  .progress-label-source_energy, .progress-label-carbon_energy
  {
      height: 0px;
  }
  
  .category-score .progress-bar-total{
      background: #7f7f7f;
  }
  
  .category-score .progress-bar-carbon{
      background: #333;
  }
  
  .category-score .progress-bar-energy, .category-score .progress-bar-carbon_energy, .category-score .progress-bar-source_energy{
      background: #D0DD3D;
  }
  
  .category-score .progress-bar-water{
      background: #55CAF5;
  }
  
  .category-score .progress-bar-waste{
      background: #84CCAF;
  }
  
  .category-score .progress-bar-transport{
      background: #A39F92;
  }
  
  .category-score .progress-bar-human{
      background: #F2AC41;
  }
  
  .category-score .progress-bar-base{
      background: #f25b63;
  }
  
  .unpacking_score_info{
      margin-top: 8%; 
      padding: 0px 1% 0px 15%;
  }
  
  .unpacking_score_info .local-avg-info img,
  .unpacking_score_info .global-avg-info img{
      width: 20px;
  }
  
  .unpacking_score_info .local-avg-info span,
  .unpacking_score_info .global-avg-info span{
      color: #414041;
      font-family: 'America';
      font-size: 12px;
  }
  
  .unpacking_score {
    padding: 0px 5% 0px 5%;
  }   
  
  .energy_unpacking_score,
  .base_unpacking_score,
  .water_unpacking_score,
  .waste_unpacking_score,
  .humanexperience_unpacking_score,
  .transportation_unpacking_score{
      margin-top: 8%;
  }
  
  .progress-local-avg,
  .progress-global-avg{
      position: absolute;
      left: 0;
      margin-top: -51px;
      display: none;
  }
  
  .local-avg-img,
  .global-avg-img{
      position: absolute;
      top: -48px;
      left: -15px;
      /* width: 40px; */
      width: 35px;
  }
  
  .dual-average .global-avg-img,
  .dual-average .local-avg-img{
      width: 50px;
  }
  
  .progress-local-avg .local-avg,
  .progress-global-avg .global-avg{
      font-family: 'America';
      color: #414041;
      top: -28px;
      position: absolute;
      z-index: 100;
      font-size: 10px;
      left: -3px;
  }
  
  /* Category score on my projects page starts */
  
  .smallScorepuck {
      text-align: center;
      padding-top: 5px;
      height: 36px;
      width: 36px;
      float: left;
      border-radius: 20px;
      background-repeat: no-repeat;
      background-color: darkgrey;
      background-size: 35px 33px;
      background-position: 1px 2px;
  }
  
  .smallScorepuck span {
      color: white;
      font-size: 18px;
      font-weight: 600;
  }
  .projectCert {
      font-weight: 600;
      padding-top: 8px;
      float: left;
      margin-left: 10px;
  }
  
  .human-category-title sup .tooltip{
      width: 200px !important;
  }
  
  .admin-analysis-tooltip .tooltip{
      width: 300px !important;
  }
  /* Category score on my projects page starts ends */
  
  
  /* -----------------------------------------------------*/
  /* racetrack/unpacking ends */
  /* -----------------------------------------------------*/
  
  /* -----------------------------------------------------*/
  /* analysis starts */
  /* -----------------------------------------------------*/
  
  .performance-report-chart{
      height: 234px;
      width: 80%;
      margin: 0px auto;
      float: left;
  }
  
  .analysis_color_scale {
      height: 100%;
      width: 65%;
      float: left;
      /* color: #fff; */
      font-size: 25px;
      text-align: center;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
  }
  
  .reduce_emission_by_header {
      font-size: 12px;
      float: left;
      /* margin-left: 25px; */
      margin-top: 20px;
  }
  
  .analysis_slider_container {
      width: 90%;
      margin: 0px auto;
      height: 14px;
      position: relative;
  }
  
  #analysis_slider_red_emission {
      -webkit-appearance: none !important;
      appearance: none !important;
      -moz-appearance: none !important;
      -ms-user-select: none !important;
      user-select: none !important;
      margin-top: 40px;
      height: 6px;
      margin-top: 100px;
      border-radius: 12px !important;
  }
  
  #analysis_slider_red_emission .ui-slider-handle {
      -webkit-appearance: none !important;
      appearance: none !important;
      -moz-appearance: none !important;
      -ms-user-select: none !important;
      user-select: none !important;
      background: url(../images/cloud-slider-white.png) no-repeat;
      margin-top: -53px;
      width: 60px;
      margin-left: -31px;
      border: none;
      outline: 0;
      height: 72px;
      cursor: pointer;
      font-size: 13px;
      font-weight: bold;
      padding-top: 15px;
      padding-left: 12px;
  }
  
  .analysis_color_scale_triangle_water {
      position: relative;
      width: 0px;
      float: left;
      height: 88px;
      background: #55CAF5;
  }
  
  .analysis_color_scale_triangle_water:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 64px solid transparent;
      border-bottom: 64px solid transparent;
      border-left: 25px solid #55CAF5;
  }
  
  .analysis_new_score_cloud, .analysis_new_score_normal {
      width: 30%;
      float: right;
      height: 100%;
  }
  
  .reduce_by {
      width: 130px;
      margin: 0px auto;
      text-align: center;
      margin-top: 10px;
      font-weight: 600;
  }
  
  .analysis_header_energy_score {
      width: 100%;
      margin: 0px auto;
      text-align: center;
  }
  
  .analysis_dynamic_section_value {
      width: 14px;
      margin-right: 66px;
  }
  
  #analysis_energy_score_dynamic {
      margin-top: -35px;
  }
  
  .analysis_points_increased_by {
      text-align: center;
      font-size: 11px;
      color: #B3B3B3;
  }
  
  #analysis_if_reduce, #analysis_if_increase, #increase_carbon_score, #increase_source_score, #increase_energy_score {
      height: 130px;
      margin-top: 30px;
      border: 1px solid #E2DFDF;
      background-color: #fff;
      border-radius: 5px;
      font-size: 12px;
      margin-right: 20px;
      margin-left: 20px;
  }
  
  #analysis_slider_inc_score, #increase_carbon_score_handle, #increase_source_score_handle, #increase_energy_score_handle {
      -webkit-appearance: none !important;
      appearance: none !important;
      -moz-appearance: none !important;
      -ms-user-select: none !important;
      user-select: none !important;
      margin-top: 40px;
      height: 4px;
      margin-top: 90px;
      /* background: #848d1c; */
      border-radius: 12px !important;
  }
  
  #analysis_slider_inc_score .ui-slider-handle, #increase_carbon_score_handle .ui-slider-handle, #increase_source_score_handle .ui-slider-handle, #increase_energy_score_handle .ui-slider-handle{
      -webkit-appearance: none !important;
      appearance: none !important;
      -moz-appearance: none !important;
      -ms-user-select: none !important;
      user-select: none !important;
      background: url(../images/rectangular-slider.png) no-repeat;
      margin-top: -51px;
      width: 65px;
      margin-left: -36px;
      border: none;
      outline: 0;
      height: 69px;
      cursor: pointer;
      font-size: 15px;
      font-weight: bold;
      padding-top: 10px;
      text-align: center;
  }
  
  .analysis_color_scale_triangle_energy {
      position: relative;
      width: 0px;
      float: left;
      height: 88px;
      background: #D0DD3D;
  }
  
  .analysis_color_scale_triangle_energy:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 64px solid transparent;
      border-bottom: 64px solid transparent;
      border-left: 25px solid #D0DD3D;
  }
  
  .analysis_dynamic_section_value_down {
      margin-right: 90px;
  }
  
  #analysis_reduce_emission_by {
      margin-top: -41px;
  }
  
  #dismiss_overlapDates
  {
      width: 100px;
  }
  
  #last_12_month_data {
      margin: 20px;
      height: 136px;
      padding-top: 17px;
      width: 100%;
      margin-left: 0px;
  }
  
  .amcharts-chart-div a {
      display: none !important;
  }
  
  .waterAnalysisImage
  {
      background: url(../images/icons/water.svg) no-repeat;
      background-size: 9.5%;
  }
  
  .humanAnalysisImage
  {
      background: url(../images/icons/human.svg) no-repeat;
      background-size: 9.5%;
  }
  
  .energyAnalysisImage
  {
      background: url(../images/icons/energy.svg) no-repeat;
      background-size: 9.5%;
  }
  
  .wasteAnalysisImage
  {
      background: url(../images/icons/waste.svg) no-repeat;
      background-size: 9.5%;
  }
  
  #performance_report .separation-line
  {
      float: left;
      width: 100%;
  }
  
  .category_heading
  {
      font-size: 40px;
      margin-bottom: 30px;
  }
  
  .general_info_score
  {
      float: left;
      width: 15%;
      text-align: center
  }
  
  
  .analysis_table
  {
      float: right;
      width: 100%;
      font-size: 12px;
  }
  
  .analysis_table th, .analysis_table td
  {
      border-top: none !important;
  }
  
  .performance_section th, .performance_section td
  {
      border-bottom: 1px solid #ddd !important;
      border-right: 1px solid #ddd !important;
      border-left: 1px solid #ddd !important;
  }
  
  .performance_section_no_border th, .performance_section_no_border td
  {
      border-top: none !important;
  }
  
  .analysis_image
  {
      width: 70%;
  }
  
  .overall_info {
      overflow: hidden;
      border-top: 1px solid;
      border-bottom: 1px solid;
      padding-top: 20px;
      padding-bottom: 20px;
  }
  
  .total_score {
      font-size: 120px;
      font-weight: 500;
      width: 20%;
  }
  
  .overall_table
  {
      float: left;
      width: 33%;
      margin-top: 30px;
  }
  
  #overall_chart
  {
      height: 180px;
      width: 45%;
      float: left;
  }
  
  .analysis_chart
  {
      margin: 20px;
      height: 160px;
      width: 100%;
      margin-left: 0px;
  }
  
  #performance_report table {
      text-align: right;
  }
  
  #performance_report th {
      text-align: center;
  }
  
  #performance_report
  {
      color: black !important;
  }
  /* -----------------------------------------------------*/
  /* analysis ends */
  /* -----------------------------------------------------*/
  
  /* -----------------------------------------------------*/
  /* Data Input: Add data starts; */
  /* -----------------------------------------------------*/
  
  #addDataModal .nav-tabs
  {
      background-color: white;
  /*    border-bottom: 1px solid lightgrey;*/
  }
  
  #addDataModal a
  {
      box-shadow: none;
  }
  
  #addDataModal li.active
  {
      color: black;
  }
  
  #addDataModal li.active a
  {
      border-bottom: 3px solid black;
      float: left;
  }
  
  #addDataModal li 
  {
      cursor: pointer;
      width: 20%;
  }
  
  .addData 
  {
      padding: 15px;
      text-align: center;
  }
  
  .addDataDesc
  {    
      font-size: 14px;
      text-align: left;
      margin-bottom: 15px;
  /*    margin-top: 15px;*/
  }
  
  .addDataSource
  {
      color: darkgrey;
      cursor: pointer;
      border-radius: 5px;
      border: 1px solid #E2DFDF;
      height: 140px;
      width: 140px;
      display: inline-block;
      margin-right: 15px;
      text-align: center;
  }
  
  .three_way
  {
      width: 30% !important;
  }
  
  .two_way
  {
      width: 47% !important;
  }
  
  .iconAddSource 
  {
      float: left;
      width: 100%;
      margin-top: 26px;
      font-size: 50px;
  }
  
  .titleAddSource
  {
      float: left;
      width: 100%;
      margin-top: 15px;
      font-size: 10px;
  }
  
  .currentMetersList
  {
      font-size: 14px;
      color: #4a4a4a;
      float: left;
      padding-bottom: 15px;
      width: 100%;
  }
  
  .currentHeaderMeter
  {
      width: 100%;
      color: darkgrey;
      padding-left: 25px;
      margin-bottom: 10px;
  }
  
  .headerItem 
  {
      width: 33%;
      display: inline-block;
  }
  
  .meter_item
  {
      background-repeat: no-repeat;
      padding-left: 25px;
      margin-bottom: 10px;
      background-position: 1px 0px;
      float: left;
      width: 100%;
      padding: 5px 0px 15px 25px;
      border-bottom: 1px solid lightgray;
  }
  
  .meter_detail_addData
  {
      float: left;
      width: 33%;
  }
  
  #meter_name_manually
  {
      height: 45px !important;
      background-color: #f1f1f1 !important;
      border: 1px solid #E2DFDF !important;
      border-radius: 3px;
      box-shadow: none !important;
      font-size: 14px !important;
      padding-left: 12px !important;
      width: 100%;
  }
  
  #manual_meter_setup .modal-content 
  {
      padding: 0px 50px 30px 50px !important;
  }
  
  .meterType[type=energy]:hover, .meterType[type=energy] a:hover
  {
      color: white;
      background: rgb(208, 221, 61) !important;
      background-size: 10px !important;
      background-position: 10% 50% !important;
  }
  
  .meterType[type=water]:hover, .meterType[type=water] a:hover
  {
      color: white;
      background: rgb(85, 202, 245) !important;
      background-size: 10px !important;
      background-position: 10% 50% !important;
  }
  
  .meterType[type=other_fuel]:hover, .meterType[type=other_fuel] a:hover
  {
      color: white;
      background: rgb(208, 221, 61) !important;
      background-size: 10px !important;
      background-position: 10% 50% !important;
  }
  
  .meterTypeDropdown .water_Selected {
      color: white;
      background: rgb(85, 202, 245) !important;
      border: none;
  }
  
  .energy_unit_Selected
  {
      background: white;
      color: rgb(208, 221, 61) !important;
      border: 1px solid rgb(208, 221, 61);
  }
  
  .water_unit_Selected
  {
      background: white;
      color: rgb(85, 202, 245) !important;
      border: 1px solid rgb(85, 202, 245);
  }
  
  .other_fuel_unit_Selected
  {
      background: white;
      color: rgb(208, 221, 61) !important;
      border: 1px solid rgb(208, 221, 61);
  }
  
  .meterTypeDropdown button
  {
      height: 45px !important;
      border-radius: 4px 0px 0px 4px;
  }
  
  .meterUnitDropdown button {
      height: 45px !important;
      border-radius: 0px 4px 4px 0px;
  }
  
  .fuelTypeDropdown button
  {
      height: 45px !important;
      background: #f1f1f1;
      text-align: left !important;
      border-radius: 4px;
      color: #999;
      width: 100%;
      border: 1px solid #E2DFDF !important;
  }
  
  .fuelCaret
  {
      float: right;
      margin-top: 8px;
  }
  
  .addedMeters
  {
      padding: 15px;
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 200px;
      overflow-y: scroll;
      margin-bottom: 15px;
  }
  
  .meterNewItemWrapper
  {
      margin-top: 25px;
  }
  
  .meterNewItem
  {
      float: left;
      width: 25%;
      margin-bottom: 25px;
  }
  
  .meterTypeDropdown .energy_Selected {
      color: white;
      background: rgb(208, 221, 61) !important;
      border: none;
  }
  
  .continue_manual_meter_div
  {
      display: inline-block;
      width: 100%;
      margin-top: 10px;
  }
  
  .customFileUpload {
      position: relative;
      overflow: hidden;
      margin: 10px;
  }
  .customFileUpload input.upload {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      font-size: 20px;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
  }
  .meter_input_field
  {
      width: 50%;
      display: inline-block;
      font-weight: 600;
      padding-left: initial;
      background-color: white !important;
  }
  
  .meter-source{
      float: right;
      width: 150px;
      height: 30px;
      margin-top: -5px;
      margin-left: 5px;
  }
  
  
  table input[readonly]
  {
      background-color: transparent !important;
  }
  
  table input
  {
      background-color: white !important;
  }
  
  .dataInputLoaderSpinner 
  {
      float: right;
      margin-top: 11px;
      /* margin-right: 10px; */
      border-radius: 23px;
      border-width: 12px;
      width: 9px;
      height: 9px;
      z-index: 2000;
      border: solid 2px transparent;
      border-top-color: darkgray;
      border-left-color: darkgray;
      -moz-animation: spin 400ms linear infinite;
      animation: spin 400ms linear infinite;
  }
  
  .data_input_perf{
      width: 80px;
      border-radius: 4px;
      border: 1px solid rgba(128, 128, 128, 0.33);
      height: 30px;
      padding: 1px 10px;
  }
  
  .noMeters
  {
      font-size: 21px;
      text-align: center;
      margin-bottom: 25px;
      color: grey;
  }
  
  /* -----------------------------------------------------*/
  /* Data Input: Add data ends; */
  /* -----------------------------------------------------*/
  
  /* -----------------------------------------------------*/
  /* Survey style starts; */
  /* -----------------------------------------------------*/
  
  .addDataSource_survey
  {
      color: darkgrey;
      cursor: pointer;
      border-radius: 5px;
      border: 1px solid #E2DFDF;
  /*    height: 160px;*/
      width: 140px;
      display: inline-block;
      margin-right: 15px;
      text-align: center;   
  }
  
  #survey_link_email_1, #survey_link_copy_1, #survey_link_email_2, #survey_link_copy_2
  {
      margin-right: 16px;
      border: 1px solid rgb(186, 193,202);
      background-color: white;
      border-radius: 5px;
      font-size: 15px;
      font-weight: 100;
      width: 12%;
      height: 45px;
      text-align: center;
      margin: 0px;
      outline: none;
  }
  
  .V3_button
  {
      color: grey !important;
      background-color: white;
  }
  
  .V3_button:hover
  {
      background-color: #428bca !important;
      border: 1px solid #428bca !important;
      color: white !important;
  }
  
  .surveyLinkAddData
  {
      float: left;
      margin-top: 0px !important;
      width: 100% !important;
  }
  
  .linkCopied_2, .linkCopied_1
  {
      color: #95BF58;
      position: relative;
      bottom: 32px;
      left: 221px;
      font-size: 12px;
  }
  
  .linkCopied
  {
      color: #95BF58;
      position: relative;
      top: 15px;
      left: -10px;
      font-size: 12px;
  }
  
  .copySurveyInput
  {
      width: 100%;
      margin-top: 30px;
  }
  .surveyInputCopy
  {
      width: 85%;
      float: left;
      height: 33px;
      border-radius: 4px 0px 0px 4px;
  }
  
  .content_container
  {
      margin: 0 auto;
      width: 75%;
      /* margin-left: 0px; */
  }
  
  .choices
  {
      float: left;
      width: 100%;
      padding-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
  }
  
  .feedback_option
  {
      height: 55px;
      width: 55px;
      float: left;
      background-size: 50px 50px !important;
      margin-right: 10px;
      border-radius: 3px;
      background-position: 2.5px 2.5px !important;
      cursor: pointer;
  }
  
  .happy
  {
      background: url(../images/happy.png) no-repeat;   
  }
  
  .okay
  {
      background: url(../images/okay.png) no-repeat;   
  }
  
  .unhappy
  {
      background: url(../images/unhappy.png) no-repeat;   
  }
  
  .happy.selected_choice
  {
      background-color: rgb(136, 193, 88); 
  }
  
  .okay.selected_choice
  {
      background-color: rgb(255, 221, 108); 
  }
  
  .unhappy.selected_choice
  {
      background-color: rgb(231, 112, 107);
  }
  
  .surveyWrapper
  {
      margin-left: initial !important;
  }
  
  .surveyContent
  {
          padding: 40px 40px 40px 40px !important; 
  }
    
  
  .routeTypes
  {
      float: right;
      width: 80%;
  }
  
  .surveyHeading
  {
      width: 100%;
  }
  
  .survey_fontsize 
  {
      float: left;
      width: 12.5%;
      text-align: center;
  }
  
  .unit_dropdown
  {
      float: left;
      width: 20%;
      padding-left: 25px;
  }
  
  .survey_input
  {
      width: 50% !important;
      margin: 0 auto;
  }
  
  /*.addRoute
  {
      float: left;
      width: 100%;
      padding-left: 44%;
  }*/
  
  /*.addRouteLabel
  {
      background: url(../images/add-route.png) no-repeat;
      width: 100%;
      padding-left: 40px;
      padding-top: 4px;
      margin-left: 45%;
      margin-top: 5%;
      padding-bottom: 10px;    
      cursor: pointer;
      float: left;
  }*/
  
  .addRouteLabel_german
      {
          background: url(../images/add-route.png) no-repeat;
          width: 100%;
          padding-left: 40px;
          padding-top: 4px;
          margin-left: 36%;
          margin-top: 5%;
          padding-bottom: 10px;    
          cursor: pointer;
          float: left;
      }
  
  .surveySeparator
  {
      border-top: 1px solid #dddddd !important;
      float: left;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 40px;
  }
  
  .smiley
  {
      background: url(../images/smilies.png) no-repeat center;
      height: 85px;
      width: 85px;
      margin-left: auto;
      margin-right: auto;
  }
  
  .satis1
  {
      background-position: -00px 0px;
  }
  
  .satis2
  {
      background-position: -100px 0px;
  }
  
  .satis3
  {
      background-position: -200px 0px;
  }
  
  .satis4
  {
      background-position: -300px 0px;
  }
  
  .satis5
  {
      background-position: -400px 0px;
  }
  
  .satis6
  {
      background-position: -500px 0px;
  }
  
  .satis7
  {
      background-position: -600px 0px;
  }
  
  .satis8
  {
      background-position: -700px 0px;
  }
  
  .satis9
  {
      background-position: -800px 0px;
  }
  
  .satis10
  {
      background-position: -900px 0px;
  }
  
  .satis11
  {
      background-position: -1000px 0px;
  }
  /*
  #surveySlider
  {
      display: inline-block;     
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      -ms-user-select: none !important;
      width: 100%;
      background: url(../img/slider-track.png) no-repeat;
      height: 8px;
      margin-top: 20px;
      margin: auto;
  }*/
  
  .sorry
  {
      width: 100%;
      float: left;
      background-size: 100% 100%;
  }
  
  .sorry_container
  {
      width: 80%;
      margin: 0 auto;
      margin-top: 70px;
      padding: 15px;
      border: 1px solid #dddddd;
      border-radius: 4px;
      overflow: auto;
  }
  
  /*.surveyOptionRow
  {
      width: 100%;
      float: left;
      font-size: 14px;
      font-family: 'Open Sans';
      font-weight: 400;
  }
  
  .surveyOptionRow .checkbox
  {
      float: left;
      width: 20%;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 10px;
  }*/
  
  .survey_other
  {
      margin: 0 auto;
      width: 50%;
  }
  
  .survey_location
  {
      margin: 0 auto;
      width: 50%;
  }
  .survey_tenant_name
  {
      margin: 0 auto;
      width: 40%;
  }
  /*#surveySlider .ui-slider-handle
  {
      background: url(../images/handle.png) no-repeat;
      width: 35px;
      height: 35px;
      border: none;
      margin-top: -7px;
      background-size: 35px;
      cursor: pointer;
  }*/
  .feedbackStatus
  {
      font-size: 10px;
      margin-top: 9px;
      float: left;
  }
  .card_img
  {
      width: 30px;
      margin-right: 10px;
      /*opacity: 0;*/
      position: absolute;
      top: 15px;
      right: -10px;
  }

  .card_img_paynow
  {
      width: 30px;
      margin-right: 10px;
      opacity: 0;
      position: absolute;
  }
  
  .removeOpacity
  {
      opacity: 1 !important;
  }
  
  .manage_unit_selected
  {
      font-size: 11px;
      color: grey;
  }
  
  .survey_label
  {
      background: url(../images/survey.png) no-repeat;
  }
  
  .survey_label
  {
      display: inline-block;
      width: 12%;
      height: 105px;
      padding: 0;
      margin: 2px;
      vertical-align: middle;
      text-align: center;
      cursor: default;
      text-shadow: 0px 1px #ffffff;
      font-weight: normal !important;
  }
  
  .survey_label.walk, .survey_label.walk_bike_telecommute
  {
      background-position: 49% -75px;
  }
  
  .survey_label.bus
  {
      background-position: 64% -1852px;
  }
  
  .survey_label.light_rail
  {
      background-position: 70% -1451px;
  }
  
  .survey_label.heavy_rail
  {
      background-position: 67% -651px;
  }
  .survey_label.motorcycle
  {
      background-position: 70% -1713px;
  }
  .survey_label.car
  {
      background-position: 75% -1980px;
  }
  .survey_label.car23
  {
      background-position: 75% -1580px;
  }
  .survey_label.cars4
  {
      background-position: 70% -1182px;
  }
  
  .routeTypes_mobile
  {
      
      width: 100%;
  }
  .surveyHeading_mobile
  {
      width: 70%;      
  }
  .survey_fontsize_mobile 
  {
      
      width: 20%;
      height: 80px;
      text-align: top; 
        
      font-size: 14px;
  
  }
  .survey_fontsize_mobiles
  {
      
      text-align: center;
  }
  .unit_dropdown_mobile
  {
      float: right;
      width: 20%;
      padding-left: 25px;
  }
  .addRoute_mobile
  {
      
      float: left;
      width: 100%;
      padding-left: 25%;
      
  }
  .addRouteLabel_mobile
  {
      background: url(../images/add-route.png) no-repeat;
      padding-left: 40px;
      padding-top: 4px;
      padding-bottom: 10px;
      cursor: pointer;
      float: left;
  }
  
  .sliderLabelFirst {
      font-weight: 600;
      width: 5%;
      float: left;
      text-align: left;
  }
  
  .sliderLabelFirst_mobile {
      font-weight: 600;
      font-size: 10px;
      width: 5%;
      float: left;
      text-align: left;
  }
  
  .sliderLabelSecond {
      font-weight: 600;
      width: 8%;
      float: right;
      margin: 2px;
      margin-right: 0px;
      text-align: right;
  }
  
  .sliderLabelSecond_mobile
  {
      position: relative;
      margin-right: 20px;
      font-weight: 600;
      font-size: 10px;
      width:5%;
      float:right;
      text-align: right;
  }
  .surveyOptionRow_mobile
  {
      width: 100%;
      float: left;
      
  }
  .surveyOptionRow_mobile .checkbox
  {
      float: left;
      width: 50%;
      margin-top: 20px;
      
  }
  .survey_other_mobile
  {
      margin: 0 auto;
      width: 70%;
      font-size: 12px;
  }
  .survey_location_mobile
  {
      margin: 0 auto;
      width: 70%;
      font-size: 12px;
  }
  .survey_label_mobile
  {    
      height: 50px;    
      width: 80px;      
      vertical-align: bottom;     
  }
  .survey_label_mobile.walk, .survey_label_mobile.walk_bike_telecommute
  {
      background: url(../images/walk.png) no-repeat;
      
  }
  .survey_label_mobile.bus
  {
      background-position: 55% -1852px;
      background: url(../images/bus.png) no-repeat;
  }
  .survey_label_mobile.light_rail
  {
      background-position: 57% -1451px;
      background: url(../images/light_rail.png) no-repeat;
  }
  
  .survey_label_mobile.heavy_rail
  {
      background-position: 58% -651px;
      background: url(../images/heavy_rail.png) no-repeat;
  }
  .survey_label_mobile.motorcycle
  {
      background-position: 57% -1713px;
      background: url(../images/motorcycle.png) no-repeat;
  }
  .survey_label_mobile.car
  {
      background-position: 56% -1980px;
      background: url(../images/car.png) no-repeat;
  }
  .survey_label_mobile.car23
  {
      background-position: 56% -1580px;
      background: url(../images/car23.png) no-repeat;
  }
  .survey_label_mobile.cars4
  {
      background-position: 57% -1182px;
      background: url(../images/cars4.png) no-repeat;
  }
  .routeLabel_mobile
  {  
      
      display: inline;
      float: left;
      width: 55%;
      background: url(../images/route-label.png) no-repeat;
      height: 40px;
      color: white;
      padding: 8px 34px;
      font-weight: 100;
      position:absolute;    
      background-position: 22px 0px;
      margin-top: -3px;
  }
  
  .mood {
    text-align: center;
    height: 170px;
    width: 100%;
      float: left;
  }
  /* line 1341, ../scss/main.scss */
  .mood .labels {
    position: relative;
    font-size: 12px;
    top: 30px;
    left: 50%;
    text-align: center;
  }
  /* line 1349, ../scss/main.scss */
  .mood .low {
    position: absolute;
    margin-left: -389px;
  }
  /* line 1354, ../scss/main.scss */
  .mood .high {
    position: absolute;
    margin-left: 333px;
  }
  /* line 1359, ../scss/main.scss */
  .mood .smiley {
    position: relative;
    left: 50%;
    color: #F0AB40;
    margin-left: -31px;
    margin-top: 70px;
    height: 85px;
    width: 85px;
    background: url("../images/smilies.png") -500px 0px no-repeat;
  }
  
  .animationButton {
      width: 15%;
      border-radius: 0px 4px 4px 0px;
  }
  
  #project-info {
      font-family: 'America';
  }
  /* -----------------------------------------------------*/
  /* Survey style ends; */
  /* -----------------------------------------------------*/
  
  /* -----------------------------------------------------*/
  /* Manage app and arc/leed logo starts; */
  /* -----------------------------------------------------*/
  
  .printbtn {
      background-color: #4CC713;
      color: white;
  }
  .leedImage
  {
      background: url(../images/arc-colored.png) no-repeat;
      background-size: 104%;
      float: right;
      width: 160px;
      height: 96px;
      margin-top: 20px;
  }
  
  .arc_logo_colored {
      position: relative;
      margin-right: -30px;
      float: right;
      width: 200px;
      height: 100px;
      background: url(../images/arc-logo-full-color.png) no-repeat center;
      background-size: 150px;
  }
  #select_title .arc_logo_white {
    height: 78px;
    background: url(../images/arc-logo.png) no-repeat center;
    background-size: 57px;
    background-color: rgb(19, 66, 114);
  }
  #select_title .active .arc_logo_white, #select_title .title_images:hover .arc_logo_white
  {
    height: 73px;
  }
  .arc_logo_white {
      height: 78px;
      background: url(../images/arc-logo.png) no-repeat center;
      background-size: 78px;
  }
  
  .arc_logo_black {
      position: relative;    
      float: right;
  }
  
  .nav-static .appLogo
  {
      background-size: 25%;
  }
  
  /*.leed_logo_cert
  {
      background: url(../images/leed_logo_small.png) no-repeat;
  }*/
  
  .cert_logo
  {
      width: 100px;
      height: 50px;
      background-size: 45%;
  }
  
  .arc_logo {
      float: right;
      font-size: 50px;
      font-weight: 500;
  }
  
  img.analytics_icon {
      height: 37px;
      float: left;
      margin-top: 12px;
      margin-right: 10px;
  }
  
  .energyStarImage
  {
      width: 150px;
      margin-left: 10px;
      margin-bottom: 5px;
  }
  
  .googleDriveDrp
  {
      color: lightgrey;
      background: url(../images/logo/google_drive.svg) 2px 1px no-repeat;
      background-size: 13px 16px;
      /* float: left; */
      height: 28px;
      width: 28px;
      /* border-radius: 5px; */
      cursor: pointer;
      padding-right: 15px;
      margin-right: 2px;
  }
  .dropBoxDrp
  {
      color: #0061FF;
      background: url(../images/logo/dropbox.svg) -1px 2px no-repeat;
      background-size: 72px 15px;
      /* float: left; */
      height: 28px;
      width: 30px;
      /* border-radius: 5px; */
      cursor: pointer;
      padding-right: 15px;
      margin-right: 2px;
  }
  .oneDriveDrp
  {
      color: #094AB2;
      background: url(../images/logo/onedrive.svg) 1px 1px no-repeat;
      background-size: 13px 16px;
      /* float: left; */
      height: 28px;
      width: 28px;
      /* border-radius: 5px; */
      cursor: pointer;
      padding-right: 15px;
      margin-right: 2px;
  }
  
  .dropBox, .oneDrive, .laptop {
      background-color: white;
      border-radius: 5px;
      font-size: 17px;
      padding: 0px 4px 0px 4px;
      cursor: pointer;
      float: left;
      display: inline;
      margin-right: 10px;
  }
  .dropBox {
      color: #428bca;
      border: 2px solid #428bca;
  }
  
  .dropBox:hover {
      background-color: #428bca;
      color: white;
  }
  
  .oneDrive {
      color: rgb(9, 74, 178);
      border: 2px solid rgb(9, 74, 178);
  }
  
  .oneDrive:hover {
      background-color: rgb(9, 74, 178);
      color: white;
  }
  
  .laptop {
      color: lightgrey;
      border: 2px solid rgb(148,158,171);
  }
  
  .laptop:hover {
      background-color: lightgrey;
      color: white;
  }
  
  .googleDrive {
      color: lightgrey;
      border: 2px solid rgb(148,158,171);
      background: url(../images/logo/google_drive.svg) 2px 1px no-repeat;
      background-size: 22px 22px;
      float: left;
      height: 29px;
      width: 29px;
      border-radius: 5px;
      cursor: pointer;
      margin-right: 10px;
  }
  .googleDrive:hover {
      background-color: lightgrey;
      color: white;
  }
  
  #logo_well{
      background-image: url('../images/logo/well.png');
      background-repeat: no-repeat;
      background-size: 60px;
      height: 60px;
      width: 60px;
      float: left;
      margin-left: 20px;
  }
  
  #logo_peer{
      background-image: url('../images/logo/peer.png');
      background-repeat: no-repeat;
      background-size: 60px;
      height: 60px;
      width: 60px;
      float: left;
  }
  
  #logo_sites,
  #logo_gresb,
  #logo_parksmart,
  #logo_edge,
  #logo_cradle{
      float: left;
      margin-left: 16px;
      border: 1px solid rgba(128, 128, 128, 0.41);
      border-radius: 30px;
      height: 60px;
      width: 60px;
      padding-left: 6px;
      padding-top: 10px;
  }
  
  #logo_sites img,
  #logo_gresb img,
  #logo_parksmart img,
  #logo_edge img,
  #logo_cradle img{
      height: 35px;
      width: 45px;
  }
  
  #logo_edge{
      margin-left: 0px;
      clear: left;
      margin-top: 15px;
  }
  
  #logo_cradle{
      margin-top: 15px;
  }
  
  .apps-page-padding{
      padding: 30px 30px;
  }
  
  .project-apps{
      border: 1px solid rgba(128, 128, 128, 0.21);
      /* border-bottom: 0px; */
      height: 320px;
  }

  .billing-apps{
      border: 1px solid rgba(128, 128, 128, 0.21);
      /* border-bottom: 0px; */
      height: 310px;
  }
  
  .project-apps .fs-mini{
      margin-top: 10px;
      padding-left: 15px;
  }
  
  .project-apps .app_title{
    font-weight: 500;
    font-family: AmericaMedium;
      margin-top: 15px;
      padding-left: 15px;
      height: 30px;
      line-height: 23px;
  }
  
  #energy_star_title,
  #dropbox_title,
  #onedrive_title,
  #google_title{
      margin-bottom: 30px;
  }
  
  .app_desc{
      height: 80px;
      padding-left: 15px;
      padding-right: 15px;
  }
  
  .app_status{
      text-align: center;
      height: 45px;
      line-height: 45px;
      cursor: pointer;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      margin-bottom: 0px;
  }
  
  #energy_star_img{
      width: 170px;
  }
  
  .energyStarPrompt 
  {
      background: url('../images/energystar.png') no-repeat;
      background-size: 85%;
      background-position: 50% 50%;
  }
  
  #dropbox_img{
      width: 135px;
  }
  
  #onedrive_img{
      width: 150px;
  }
  
  #google_drive_img{
      width: 135px;
  }
  
  .app_installed{
      background: #64bd63;
      color: white;
      font-weight: 600;
  }
  
  .app_not_installed{
      background: white;
      color: #2164B2;
      font-weight: 600;
      border: 1px solid rgba(128, 128, 128, 0.21);
      border-top: 0px;
  }
  
  .app_not_installed :hover{
      background-color: #fafafa;
  }
  /* -----------------------------------------------------*/
  /* Manage app and arc logo ends; */
  /* -----------------------------------------------------*/
  
  
  
  /* -----------------------------------------------------*/
  /* Portfolio modals starts */
  /* -----------------------------------------------------*/
  
  .border-right{
      border-right: 1px solid #ddd;
  }
  
  .portfolio-modal .modal-content{
      border-radius: 0px 0px 8px 8px;
  }
  
  .portfolio-modal .modal-header{ 
      background: #333;
  }
  
  .portfolio-modal-wbg .modal-header {
      background: none;
  }
  
  .portfolio-modal .modal-footer{ 
      border-top: none;
  }
  
  .portfolio-modal .close-portfolio-modal{
      font-size: 2em;
      font-weight: 600;
      color: #ffffff;
      margin-top: -5px;
      cursor: pointer;
  }
  
  .portfolio-modal-wbg .close-portfolio-modal{
      font-size: 2em;
      font-weight: 600;
      color: dimgray;
      margin-top: -5px;
      cursor: pointer;
  }
  
  .portfolio-modal .upload-file-portfolio{
      border-radius: 2px;
      background-color: #949eab;
      border-color: #949eab;
  }
  
  .portfolio-modal .cancel-btn
  {
      background: none;
      border: 1px solid #aaa;
      color: #aaa;
      border-radius: 2px;
  }
  
  .portfolio-modal .btn-primary{    
      background-color: #4a90e2;
  }
  
  .portfolio-overview-cert{
      width: 16px;
      height: 16px;
      float: left;
      margin-top: 2px;
      margin-right: 10px;
      margin-left: 10px;
  }
  
  .border-portfolio-overview{
      border-top: 1px solid lightgrey;
      margin-top: 5px;
      padding-top: 5px;
  }
  
  .bg-platinum{
      background-color: #818183;
  }
  
  .bg-gold{
      background-color: #d5ae00;
  }
  
  .bg-silver{
      background-color: #83bbc9;
  }
  
  .bg-certified{
      background-color: #a5ba48;
  }
  
  .bg-non-certified{
      background-color: #4a4a4a;
  }
  
  .bld-icon-portfolio-overview{
      color: #7e8083;    
  }
  
  .portfolio-certified-leed-logo{
      float: left;
      width: 50px;
      height: 50px;
      background: url(../images/leed-logo.png) no-repeat;
      background-size: 40px 40px;
  }
  
  .portfolio-gresb-icon{
      float: left;
      width: 50px;
      height: 50px;
      background: url(../images/gresb-icon-only.svg) no-repeat;
      margin-top: 8px;
  }
  
  .portfolio-certified-total-prj{
      padding-left: 5px;
      font-size: 40px;
  }
  
  #portfolio-chartdiv {
      height: 130px;
      font-size: 11px;
      float: left;
      width: 120%;
  }
  
  /* Manage portfolio */
  .portfolio_separator {
      margin-top: 40px;
      color: darkgrey;
      padding-bottom: 10px;
      border-bottom: 1px solid lightgrey;
      font-size: 1.25em;
      font-weight: 400;
  }
  .input-port
  {
      width: 60%;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: none;
      border-image: initial;
      margin-top: 0px;
      padding: 6px 0px 6px 0px;
      font-size: 16px !important;
  }
  
  .modalTeamRow {
      padding-top: 15px;
      padding-bottom: 15px;
      border-top: 2px solid #f1f1f1;
      border-bottom: 2px solid #f1f1f1;
  }
  
  .collabDrop {
      width: 125px;
      color: black;
      background-color: white;
      border: 1px solid lightgrey;
      text-align: left;
  }
  
  .collabDrop .caret {
      float: right;
      margin-top: 6px;
  }
  
  /* Portfolio Ends */
  
  /* Manage portfolio */
  .color_dark_gray{
      color: darkgrey;
  }
  
  .bg-white{
      background: #fff !important;
  }
  
  .checkbox-info .import-portfolio-chk:checked + label::after {
      padding-top: 2px;
  }
  
  .checkbox-info .import-portfolio-chk:checked + label::before {
      background-color: #fff !important;
      border: 2px solid #64bd63 !important;
  }
  
  .checkbox-info .import-portfolio-chk:checked + label::after {
      color: #64bd63 !important;
  }
  
  .portfolio_input_nav_active, .portfolio_input_nav:hover {
      color: black;
      border-bottom: 3px solid black;
      height: 40px;
  }
  
  .portfolio_input_nav {
      float: left;
      margin-right: 35px;
      cursor: pointer;
      font-weight: 600;
      height: 40px;
  }
  
  .credit_comment_box_portfolio{
      margin-bottom: -7px;
  }
  
  .credit_comment_box_portfolio .credit_comment_portfolio .circle{
      width: 10px; 
      height: 10px;
      border: 2px solid #999999;
      border-radius: 10px 10px;
  }
  
  .credit_comment_box_portfolio .credit_verb{
      border-left: 2px solid #999999; 
      margin-left: 3px; 
      min-height: 20px;
      border-left-style: dashed;
  }
  
  .credit_comment_box_portfolio .author{
      margin-left: 12px;
  }
  
  .portfolio_timeline_border{
      border-left: 2px solid #999999;
      margin-left: 3px !important;
      min-height: 20px;
      border-left-style: dashed;
      margin-bottom: 0px;
      margin-top: 10px;
  }
  
  .select2-container.form-control {
      background: transparent;
      box-shadow: none;
      border: none;
      display: block;
      margin: 0;
      padding: 0;
  }
  
  .port_table textarea {
      background-color: white !important;
      resize: none;
      overflow: hidden;
      font-size: 16px;
      padding: 6px 0px 6px 0px;
  }
  
  .port_table select {
      background-color: white !important;
      resize: none;
      overflow: hidden;
      -webkit-appearance: none;
      appearance: none;
      -moz-appearance: none;
      font-size: 12px;
      line-height: 1.5;
  }
  /* -----------------------------------------------------*/
  /* Portfolio modals ends */
  /* -----------------------------------------------------*/
  
  /** Portfolio pages: project, score and carbon page css starts **/
  /* ------------------------------------------------------------- */
  
  .arc-tbl .arc-tbl-header{
      color: #28323B;
      font-size: 12px;	
      font-weight: 600;
  }
  
  .arc-tbl .arc-tbl-header span{
      padding-left: 8px;
      pointer-events: none;
  }
  
  .arc-tbl tr.arc-tbl-row td{
      vertical-align: middle !important;
      cursor: pointer;
  }
  
  .arc-tbl span.project-id{
      color: #28323B;		
      font-size: 14px;	
      font-weight: 600;
  }
  
  .arc-tbl span.project-name,
  .arc-tbl span.project-geo{
      text-transform: capitalize;
      color: #28323B;
      font-size: 14px;
      line-height: 21px;
      font-weight: normal;
  }
  
  .arc-tbl span.project-score{
      font-family: America;
      color: white;
      font-size: 18px;
  }
  
  .arc-tbl .project-cert{
      padding: 2px 10px;
      color: white;
      border-radius: 3px;
      font-size: 12px;
      font-family: America;
  }
  .arc-tbl .platinum{
      background-color: #818183;    
  }
  
  .arc-tbl .certified{
      background-color: #a5ba48;
  }
  
  .arc-tbl .gold{
      background-color: #d5ae00;
  }
  
  .arc-tbl .silver{
      background-color: #83bbc9;
  }
  
  .project-score-puck{
      text-align: center;
      padding-top: 5px;
      height: 36px;
      width: 36px;
      float: left;
      border-radius: 20px;
      background-repeat: no-repeat;
      background-color: rgb(137,138,137);
      background-size: 35px 33px;
      background-position: 1px 2px;
  }
  
  .portfolio-projects-pagination{
      padding-right: 15px;
      float: right;
  }
  
  .portfolio-projects-pagination .pagination-loader{
      float: left;
      margin-top: 11px;
      margin-right: 10px;
      border-radius: 23px;
      border-width: 12px;
      width: 9px;
      height: 9px;
      z-index: 2000;
      border: solid 2px transparent;
      border-top-color: darkgray;
      border-left-color: darkgray;
      -moz-animation: spin 400ms linear infinite;
      animation: spin 400ms linear infinite;
      margin-left: 10px;
  }
  
  .pagination-rows-container{
      float: left;
  }
  
  .pagination-rows-container .rows-per-page-title{
      font-family: America;
  }
  
  .pagination-rows-container .page-size-drp{
      display: inline;
      margin-left: 5px;
  }
  
  .page-size-drp .btn-page-size-drp{
      border: none;
      background: white;    
  }
  
  .page-size-drp .pagination-page-size{
      font-weight: 600;
      margin-right: 5px;
  }
  
  .page-size-drp span.fa-caret-down{
      font-weight: 600;
  }
  
  .pagination-rows-navigator{
      float: right;
  }
  
  .pagination-rows-navigator .rows-per-page-title{
      font-family: America;
  }
  
  .pagination-rows-navigator .prev-btn{
      background: #fff;
      margin-left: 30px;
      margin-right: 30px;
  }
  
  .pagination-rows-navigator .prev-btn i.fa-angle-left,
  .pagination-rows-navigator .prev-btn i.fa-angle-right{
      font-size: 16px;
      color: darkgrey;
      font-weight: 600;
  }
  
  .pagination-rows-navigator .next-btn{
      background: #fff;
      margin-left: 30px;
  }
  
  .portfolio-widget-label{
  
      font-family: America;
      font-weight: 300 !important;
      color: #000000b5;
  }
  
  .portfolio-widget-value{
  
      font-weight:600 !important;
      font-size: 20px;
  }
  
  .portfolio-widget-units{
  
      font-size: 10px;
  }
  
  .portfolio-filters-wrapper{
      margin-bottom: 20px;
  }
  
  #scale-icon{
      background-image:  url(../images/ruler-grey.svg);
      text-align: center;
      font-size: 12px;
      background-repeat: no-repeat;
      background-size: 35px 33px;
  }
  
  .portfolio-widget{
      /*padding: 7px 5px;*/
      cursor: pointer;
      -moz-box-shadow: 0px 0px 5px 5px #8888881c;
      -webkit-box-shadow: 0px 0px 5px 5px #8888881c;
      box-shadow: 0px 0px 5px 5px #8888881c;
      height: 110px;
      margin-right: -15px;
  }
  #occupants-widget div,
  #emissions-widget div,
  #project-widget div,
  #certified-project-widget div,
  #total-certified-widget div,
  #total-floor-widget div,
  #emissions-widget div
   {
      padding-bottom: 2px;
  }
  
  /* Porttolio project side bar modal window starts */
  .modal.portfolio-projects-sidebar .modal-dialog {
      position: fixed;
      margin: auto;
      width: 35%;
      height: 100%;
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
  }
  
  .modal.portfolio-projects-sidebar .modal-dialog .modal-body{
      padding: 20px; 
  }
  
  .modal.portfolio-projects-sidebar .modal-dialog .modal-header{
      border-bottom: 0;
  }
  
  .modal.portfolio-projects-sidebar .modal-header .modal-title{
      font-weight: normal;
      color: #252C32;
      font-size: 20px;
  }
  
  .modal.portfolio-projects-sidebar .modal-dialog .modal-header .modal-title{
      float: left;
  }
  
  .modal.portfolio-projects-sidebar .portfolio-widget-data .portfolio-widget-data-num{
      font-weight: normal;
      padding-left: 5px;
      color: #252C32;
      font-family: "Open Sans";
      font-size: 24px;
  }
  
  .modal.portfolio-projects-sidebar .portfolio-widget-data{
      margin-top: 5px;
      margin-bottom: 10px;
  }
  
  .modal.portfolio-projects-sidebar .portfolio-widget-data .portfolio-widget-unit{
      font-size: 11px;
      padding-left: 5px;
  }
  
  /** for all modal across arc, use this close button class*/
  .modal .modal-close-btn{
      float: right;
      font-size: 21px;
      font-weight: 500;
      line-height: 1;
      color: #00000045;
      padding: 0;
      cursor: pointer;
      background: transparent;
      border: 0;
      -webkit-appearance: none;
      appearance: none;
  }
  
  .modal.portfolio-projects-sidebar.fade .modal-dialog {
      right: -320px;
      -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
      -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
      -o-transition: opacity 0.3s linear, right 0.3s ease-out;
      transition: opacity 0.3s linear, right 0.3s ease-out;
  }
  
  .modal.portfolio-projects-sidebar.fade.in .modal-dialog {
      right: 0;
  }
  
  .modal.portfolio-projects-sidebar .modal-content {
      height: 100%;
      overflow-y: auto;
      border-radius: 0px;
  }
  .modal.portfolio-projects-sidebar .arc-tbl th.gross-sq-ft-lbl{
      padding-right: 0px;
  }
  
  .arc-tbl .tbl-loading-msg{
      text-align: center;
      padding: 20px;
  }
  
  .arc-tbl .tbl-heading-row .tbl-heading-left{
      text-align: left;
      padding-left: 0px;
  }
  
  .arc-tbl .tbl-heading-row .tbl-heading-left span,
  .arc-tbl .tbl-heading-row .tbl-heading-right span{
      color: #28323B;	
      font-family: "Open Sans";
      font-weight: 600;	
  }
  
  .arc-tbl .tbl-heading-row .tbl-heading-right{
      text-align: right;
  }
  
  .arc-tbl .tbl-heading-row .tbl-heading-right i.fa{
      padding-left: 5px;
  }
  /* Porttolio project side bar modal window ends */
  
  .scope1-color{
  
      color: rgb(191, 191, 191);
  }
  .scope2-color{
  
      color: rgb(217, 217, 217);
  }
  #carbon-labels{
  
      padding: 15px 15px;
  }
  .energy-total-score{
  
      font-weight: 600 !important;
      font-size: 16px;
  }
  .project-carbon-score{
  
      float: left;
      width: 10%;
      font-weight: 600 !important;
  }
  /* ------------------------------------------------------------*/
  /** Portfolio pages: project, score and carbon page css ends **/
  
  
  /* -----------------------------------------------------*/
  /* Submit for review screens starts starts */
  /* -----------------------------------------------------*/
  
  .black_color_28323a{
     color: #28323a; 
  }
  
  .gray_color_4A4A4A{
      color: #4A4A4A;
  }
  .gray_color_68737D{
      color: #68737D;
  }
  
  .gray_color_707479{
      color: #707479;
  }
  
  .gray_color_b8c3ca{
      color: #b8c3ca;
  }
  
  .status_complete{
      color: #50AE54;
  }
  
  .status_incomplete{
      color: #D0011B;
  }
  
  .gray_border{
      border: 1px solid #ddd;
  }
  
  .light_gray_border{
      border-bottom: 1px solid #ddd;
  }
  
  .gray_bg{
      background: #f7f7f7;
  }
  
  .white_bg{
      background: #fff !important;
  }
  
  .lightblue_bg{
      background: #9bc8d3;
  }
  
  #submit_for_review_nav_container div div.active{
      padding-bottom: 10px;
      border-bottom: 8px solid #28323a;
  }
  
  #submit_for_review_nav_container div div.inactive{
      padding-bottom: 10px;
      border-bottom: 8px solid #eeeeee;
      color: rgba(117, 120, 125, 0.97);
  }
  
  #submit_for_review .cert_goal img{
      height: 60px;
  }
  
  #submit_for_review .edit_cert_level .btn{
      width: 35%;
      height: 30px;
      vertical-align: middle;
      color: #0000FF;
      font-size: 12px;
      background: #ffffff;
      margin-bottom: 20px;
      margin-top: 10px;
  }
  
  #submit_for_review .current_score .progress-bar{
      border-radius: 0px;
      background: #565656;
  }
  
  #submit_for_review .current_score .progress{
      border-radius: 0px;
      height: 30px;
  }
  
  #submit_for_review .current_score div.circle{
      border-radius: 50%;
      background: #565656;
      color: white;
      height: 40px;
      width: 40px;
      font-weight: 600;
      font-size: 18px;
      padding-top: 7px;
  }
  
  .hr-summary-divider{
      margin-top: 10px;
      margin-bottom: 10px;
      border-top: 1px solid #ddd;
      margin-left: 10px;
      margin-right: 100px;
  }
  
  .border-radius-4{
      border-radius: 4px;
  }
  
  #submit_for_review .select_cert .select2-container a{
      border: 1px solid #999999;
  }
  
  .row.border-divider{
      border-top: 1px solid #ddd;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 20px;
  }
  
  .light_blue_btn{
      background: #4a90e2;
  }
  
  .edit-goal-modal .modal-content{
      border-radius: 0px 0px 8px 8px;
  }
  
  .edit-goal-modal .modal-header{ 
      background: #333;
  }
  
  .edit-goal-modal-wbg .modal-header {
      background: none;
  }
  
  .edit-goal-modal .modal-footer{ 
      border-top: none;
  }
  
  .edit-goal-modal .close-edit-goal-modal{
      font-size: 2em;
      font-weight: 600;
      color: #ffffff;
      margin-top: -5px;
      cursor: pointer;
  }
  
  .edit-goal-modal .upload-file-portfolio{
      border-radius: 2px;
      background-color: #949eab;
      border-color: #949eab;
  }
  
  .edit-goal-modal .cancel-btn
  {
      background: none;
      border: 1px solid #aaa;
      color: #aaa;
      border-radius: 2px;
  }
  
  .edit-goal-modal .btn-primary{    
      background-color: #4a90e2;
  }
  
  .edit-goal-modal .current_score .progress-bar{
      border-radius: 0px;
      background: #565656;
  }
  
  .edit-goal-modal .current_score .progress{
      border-radius: 0px;
      height: 30px;
  }
  
  .edit-goal-modal .current_score div.circle{
      border-radius: 50%;
      background: #565656;
      color: white;
      height: 40px;
      width: 40px;
      font-weight: 600;
      font-size: 18px;
      padding-top: 7px;
  }
  
  .edit-goal-modal .cert_goal img{
      height: 60px;
  }
  
  .edit-goal-modal .canel-modal{
      float: right;
      color: #aaa;
      font-size: 20px;
  }
  
  .edit-goal-modal .select2-container a{
      border: 1px solid #999999;
  }
  
  .basepoints_filter .select2-container a{
      border: none;
      box-shadow: none;
  }
  
  #submit_for_review #reviewPaymentForm input,
  #submit_for_review #reviewPaymentForm select{
      border: 1px solid #999999;
  }
  
  #submit_for_review #reviewPaymentForm .fa-lock{
      margin-top: -22px;
      margin-right: 10px;
      color: #b8c3ca;
  }
  
  #submit-payment .current_score .progress-bar{
      border-radius: 0px;
      background: #9bc8d3;
  }
  
  #submit-payment .current_score div.circle{
      background: #9bc8d3;
  }
  
  #submit-payment .edit_cert_level .btn{
      background: #9bc8d3;
      border-color: #fff;
      color: #fff;
  }
  .h190px{
      height: 190px;
  }
  .deleteHide 
  {
      z-index: 9;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: white;
      position: absolute;
      border-radius: 3px;
      background-color: rgba(255,255,255,0.8);
  }
  .floatNone
  {
      float: none !important;
  }
  .height15
  {
      height: 15px;
  }
  .width15
  {
      width: 15px;
  }
  
  #submit_for_review #reviewPaymentForm .fa-lock, #pay_by_card_billing .fa-lock, #pay_now_by_card .fa-lock{
      margin-top: -22px;
      margin-right: 10px;
      color: #b8c3ca;
  }
  /* -----------------------------------------------------*/
  /* Submit for review screens starts starts */
  /* -----------------------------------------------------*/
  
  
  /*******************************
  * MODAL AS LEFT/RIGHT SIDEBAR Starts
  * Add "left" or "right" in modal parent div, after class="modal".
  *******************************/
  
      .modal.left .modal-content,
      .modal.right .modal-content {
          height: 100%;
          overflow-y: auto;
      }
      
      .modal.left .modal-body,
      .modal.right .modal-body {
          padding: 15px 15px 80px;
      }
  
      /*Left*/
      .modal.left.fade .modal-dialog{
          left: -320px;
          -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
             -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
               -o-transition: opacity 0.3s linear, left 0.3s ease-out;
                  transition: opacity 0.3s linear, left 0.3s ease-out;
      }
      
      .modal.left.fade.in .modal-dialog{
          left: 0;
      }
          
      /*Right*/
      .modal.right.fade .modal-dialog {
          right: -320px;
          -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
             -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
               -o-transition: opacity 0.3s linear, right 0.3s ease-out;
                  transition: opacity 0.3s linear, right 0.3s ease-out;
      }
      
      .modal.right.fade.in .modal-dialog {
          right: 0;
      }
  
      /* ----- MODAL STYLE ----- */
      .modal.right,
      .modal.left{
          border-radius: 0;
      }
  
  /*******************************
  * MODAL AS LEFT/RIGHT SIDEBAR Ends
  * Add "left" or "right" in modal parent div, after class="modal".
  *******************************/
  
  .arc_banner_button{
      color: #fff !important;
      margin-right: 35px !important;
      margin-top: -20px !important;
      font-size: 28px !important;
      font-weight: normal !important;
      text-shadow: 0 0 0 !important;
  }
  .resize_none{
      resize: none;
  }
  .additional_upload{
      margin-left: 10px;
      float: left;
      white-space: nowrap;
      padding: 5px 10px;
      font-size: 14px;
      background-color: #f8f8f8;
      border: 1px solid #ccc;
      line-height: 1.42857;
      border-radius: 4px;
      font-weight: 400;
      text-align: center;
  }
  .spinner_review{
      float: right !important;
      margin-left: 10px !important;
      margin-top: 3px !important;
  }
  
  /************************
  *Portfolio Ananlytics*
  ************************/
  
  .project-score-change {
      /* width: 25%; */
      /* height: 20%; */
      padding: 20px 20px 20px 20px;
      border: 1px solid black;
      margin-bottom: 20px;
      float: right;
      text-align: center;
      background-color: lightgrey;
  }
  .portfolio-project-score-change{
      padding-left: 10px;
      padding-bottom: 42px;
      font-size: 54px;
  }
  .manage_goals{
  
      padding-left: 60px;
      width: 100%;
  }
  .portfolio_goals {
  
      text-align: center;
  }
  .textarea_portfolio {
  
      resize: vertical;
      height: 120px;
      width: 95%;
      padding: 10px;
      color: black;
  }
  
  .portfolio-overflow-auto
  {
      overflow: auto;
      border-bottom: 1px solid grey;
  }
  .highestScoreCircle {
  
      border-radius: 50%;
      width: 90px;
      font-size: 50px;
      border: 2px solid #666;    
      
  }
  .highestScoreCircle span {
      text-align: center;
      line-height: 90px;
      display: block;   
  }
  
  .bc_yl{
  
      background: yellow;
  }
  .bc_lg{
  
      background: lightgrey;
  }
  .bc_g {
  
      background: #71da71;
  }
  .bc_o {
  
      background: #ffad33;
  }
  .lowestScoreCircle {
  
      border-radius: 50%;
      width: 90px;
      font-size: 50px;
      border: 2px solid #666;      
  }
  .lowestScoreCircle span {
      text-align: center;
      line-height: 90px;
      display: block;   
  }
  .avgScoreCircle {
  
      border-radius: 50%;
      width: 90px;
      font-size: 50px;
      border: 2px solid #666;    
      
  }
  .avgScoreCircle span {
      text-align: center;
      line-height: 90px;
      display: block;   
  }
  .map {
      
      height: 100px;
  }
  .score_high {
  
      /*margin-top: 40px;*/
      float: left;
      
  }
  /*******************************
  * MODAL AS LEFT/RIGHT SIDEBAR Starts
  * Add "left" or "right" in modal parent div, after class="modal".
  *******************************/
  .modal.left .modal-dialog {
      position: fixed;
      top: 15%;
      left: 40%;
  }
  .modal.right .modal-dialog
  {
      position: fixed;
  }
  .recompute_div{
      display: inline-block;
      margin-top: 250px !important;
      margin: auto;
      /* width: 320px; */
      /* height: 100%; */
      -webkit-transform: translate3d(0%, 0, 0);
          -ms-transform: translate3d(0%, 0, 0);
           -o-transform: translate3d(0%, 0, 0);
              transform: translate3d(0%, 0, 0);
  }
  .avg_analytics_table{
   
      border: 1px solid lightgrey;
  }
  .analytics_table {
  
  
      float:right; 
      border: 1px solid lightgrey;    
  }
  .bc_lgrey {
  
      background-color: lightgrey;
  }
  .bc_lgr{
  
      background-color: #F0F0F0;
  }
  .b_lgrey {
      /* color: black; */
      border: 1px solid lightgrey;
  }
  .fontSize35
  {
      font-size: 35px !important;
  }
  .progress-bar-carbon{
      background: #333;
   }
  
   .portfolio-score .progress-bar{
      border-radius: 0px;
      text-align: right; 
      padding-right: 10px;     
      padding-top: 8px;
      color: #666;
      font-size: 1em;
      font-weight: 600;
  }
  
  .portfolio-score .progress .category-label{
      position: absolute;
      left: 30px;
      margin-top: 10px;
      font-size: 1.15em;
      color: white;
      font-family: 'America';
      /* white-space: nowrap; */
  }
  
  .pt45
  {
      padding-top: 45px;
  }
  
  .fontSize25
  {
      font-size: 25px;
  }
  
  .center_heading
  {
      display: table;
      margin: 0 auto;
      padding-left: 80px;
      padding-right: 80px;
  }
  .fontSize10
  {
      font-size: 10px;
  }
  
  .custom_graph div {
      position:relative;
      margin:80px;
      width:220px; height:220px;
  }
  .custom_graph canvas {
      display: block;
      position:absolute;
      top:0;
      left:0;
  }
  .custom_graph span {
      color:#555;
      display:block;
      line-height:220px;
      text-align:center;
      width:220px;
      font-family:sans-serif;
      font-size:40px;
      font-weight:100;
      margin-left:5px;
  }
  
  .custom_graph input {
      width: 200px;
  }
  
  .custom_graph span
  {
      color: #555;
      display: block;
      line-height: 205px;
      text-align: center;
      width: 220px;
      font-family: sans-serif;
      font-size: 30px;
      font-weight: 100;
      margin-left: -10px;
  }
  .displayTable
  {
      display: table;
  }
  .displayVertical
  {
      display: table-cell;
      vertical-align: middle;
  }
  .recompute_tax_lock{
      z-index: 9;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      position: fixed;
      border-radius: 3px;
      background-color: #000;
      opacity: 0.4;
  }
  .h100{
      height: 100px;
  }
  .h100p{
      height: 100%;
  }
  .fontSize50
  {
      font-size: 50px !important;
  }
  .fontSize20
  {
      font-size: 20px;
  }
  .heading_grey_color
  {
      color: #7e8083;
  }
  .progress_bar_red
  {
      float: right;
      font-size: 13px;
      font-family: 'America';
      margin-top: 19px;
  }
  .mb3{
      margin-bottom: 3px;
  }
  
  .analysisincrease_by_img
  {
      width: 13px;
      height: 20px;
      padding-left: 3px !important;
  }
  
  /***********************
      Portfolio end
  ************************/
  
  /*Gresb chnages start*/
  
  .gresb_login_iframe{
      width: 100%;
      height: 100%;
  }
  
  .gresb_parent_div{
      font-size: 25px;
      color: #fff;
      position: relative;
      top: -40px;
  }
  
  .gresb_loading_lock{
      z-index: 9;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      position: fixed;
      border-radius: 3px;
      background-color: #000;
      opacity: 0.6;
  }
  
  input#assets_fetch::-webkit-outer-spin-button,
  input#assets_fetch::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
  }
  
  /*Gresb chnages end*/
  
  .ccc_border {
      border: 1px #ccc solid;
  }
  
  .measureBullet {
      font-size: 7px;
      float: left;
      margin-top: 6px;
      margin-right: 5px;
  }
  
  .measureTextarea
  {
      width: 90%;
      height: 100px;
      margin: 10px 0px;
      border: 1px solid #CCC;
      border-radius: 3px;
      padding: 5px;
      resize: none !important;
  }
  
  .measureParea
  {
      display: block;
      width: 850px;
      height: 100px;
      margin: 10px 0px;
      border: 1px solid #CCC;
      border-radius: 3px;
      padding: 5px;
      word-wrap: break-word;
      resize: none !important;
  }
  
  #base_score_data_tree
  {
      z-index: -1;
      position: absolute !important;
  }
  
  #base_score_data_tree label::before 
  {
      display: none;
  }
  
  #base_score_data_tree .span_content
  {
      margin-left: 20px;
  }
  
  .p2px
  {
      padding: 2px !important;
  }
  
  .project_row .progress
  {
      margin-bottom: 0px;
      height: 35px;
      background: none;
      border-radius: 0px;
  }
  .project_row .progress-bar
  {
      border-radius: 0px;
  }
  .score_label_progress
  {
      padding-right: 7px;
      text-align: right;
      padding-top: 7px;
      font-size: 18px;
      color: #4a4a4a;
      font-weight: 600;
  }
  #dataTable_portfolio tr
  {
      background-color: white !important;
  }
  
  table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
      background-color: white !important;
  }
  table.dataTable.order-column tbody tr > .sorting_1, table.dataTable.order-column tbody tr > .sorting_2, table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1, table.dataTable.display tbody tr > .sorting_2, table.dataTable.display tbody tr > .sorting_3 {
      background-color: white !important;
  }
  .filter_arrow
  {
      color: darkgray;
      font-size: 16px;
  }
  .relative-position
  {
      position: relative !important;
  }
  .custom-dropdown
  {
      position: absolute !important;
      width: 350px !important;
      margin-bottom: 0px !important;
      z-index: 1 !important;
      right: 0px;
      box-shadow: 0px 2px 8px rgba(0,0,0,0.4);
      border-radius: 1px;
      top: 30px;
  }
  
  .borderless-button
  {
      border: none;
      background: white;
  }
  
  .fontSize16
  {
      font-size: 16px !important;
  }
  
  .material_loader {
      -webkit-animation: spin 1s linear infinite;
      animation: spin 0.5s linear infinite;
      border: 1px solid white;
      border-right: 2px solid darkgrey;
      border-top: 2px solid darkgrey;
      border-radius: 50%;
      height: 10px;
      width: 10px;
    }
    
    @-webkit-keyframes spin {
      to {
        border-top-color: darkgrey;
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
      }
    }
    
    @keyframes spin {
      to {
        border-top-color: darkgrey;
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
      }
    }
  
  .width80p
  {
      width: 80%;
  }
  .mt6
  {
      margin-top: 6px;
  }

  .position-fixed{
      position: fixed;
  }
  .position-absolute{
      position: absolute;
  }
  
  .additional_data_drpdwn
  {
      width: 500px;
      max-height: 250px;
      overflow-x: hidden;
      overflow-y: scroll;
  }
  
  .additional_data_drpdwn_option 
  {
      padding: 5px 0px 5px 10px;
      float: left;
      width: 100%;
      font-size: 14px;
      cursor: pointer;
  }
  
  .additional_data_drpdwn_option:hover
  {
      background-color: #f1f1f1;
      color: black;
  }
  
  .action_description
  {
      cursor: pointer;
  }
  
  .raised
  {
      border-radius: 5px;
      padding: 1px 12px;
      box-shadow: 1px 3px 20px rgba(0,0,0,0.4);
  }
  
  .fontSize18
  {
      font-size: 18px;
  }
  
  .portfolio_navbar .nav .open > a, .portfolio_navbar .nav .open > a:hover, .portfolio_navbar .nav .open > a:focus {
      background-color: white !important;
      border-color: white !important;
      color: #4a4a4a !important;
  }
  .portfolio_navbar .nav > li > a:hover, .portfolio_navbar .nav > li > a:focus 
  {
      background-color: white !important;
      color: #4a4a4a !important;
  }
  .padding30p15p
  {
      padding: 30px 15px;
  }
  .width400
  {
      width: 400px;
  }
  .simple_table label::before, .simple_table label::before, .simple_table label::after, .simple_table label::after
  {
      margin-left: -20px !important;
  }
  .filter_circle {
      color: rgb(33,100,178);
      border: 1px solid rgb(33,100,178);
      border-radius: 12px;
      padding: 0px 5px;
      font-size: 13px;
  }
  .blue-color
  {
      color: rgb(33,100,178) !important;
  }
  
  .measure_drpdwn
  {
      height: 200px;
      overflow: auto;
  }
  .white_space_normal
  {
      white-space: normal;
  }
  .span_vertical_center
  {
      position: absolute;
      top: 48%;
      right: 2%;
  }
  .word_wrap
  {
      word-wrap: break-word;
  }
  .force-active
  {
      pointer-events:  initial !important;
      text-decoration:  initial !important;
      opacity: initial !important;
  }
  .align_file_name
  {
      position: relative;
      bottom: 1.5px;
  }
  .admin_rs_dropdown{
      height: 300px;
      overflow: auto;
  }
  .backdrop {
      width: 100%;
      height: 100%;
      z-index: 1000000;
      /* position: fixed; */
      background-color: white;
      text-decoration: none;
  }
  
  .loader_parent {
      /* width: 100%; */
      height: 30%;
      /* padding-top: 5%; */
      margin-left: 45%;
      /* float: left; */
      padding: 10% 0%;
    }
    
    .loader-lg {
      width: 10%;
      height: 50%;
      margin-right: 10px;
    }
  
    .loader {
      position: relative;
      /* margin: 0px auto; */
      /* width: 25px; */
      /* height: 25px; */
      margin-right: 10px;
    }
    
    .loader:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
    
    .circular-loader {
      -webkit-animation: rotate 2s linear infinite;
      animation: rotate 2s linear infinite;
      height: 100%;
      -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: auto;
    }
    
    .loader-path {
      stroke-dasharray: 150, 200;
      stroke-dashoffset: -10;
      -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      stroke-linecap: round;
    }
    
    .loader-white {
      -webkit-animation: dash 1.5s ease-in-out infinite, white 6s ease-in-out infinite !important;
      animation: dash 1.5s ease-in-out infinite, white 6s ease-in-out infinite !important;
    }
    
    .loader-black {
      -webkit-animation: dash 1.5s ease-in-out infinite, black 6s ease-in-out infinite !important;
      animation: dash 1.5s ease-in-out infinite, black 6s ease-in-out infinite !important;
    }
    
    @-webkit-keyframes rotate {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    
    @keyframes rotate {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    
    @-webkit-keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }
    
    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }
    
    @-webkit-keyframes color {
      0% {
        stroke: #949EAB;
      }
      40% {
        stroke: #949EAB;
      }
      66% {
        stroke: #949EAB;
      }
      80%,
      90% {
        stroke: #949EAB;
      }
    }
    
    @keyframes color {
      0% {
        stroke: #949EAB;
      }
      40% {
        stroke: #949EAB;
      }
      66% {
        stroke: #949EAB;
      }
      80%,
      90% {
        stroke: #949EAB;
      }
    }
  /*Score code version*/
  .score_version_modal_header, .score_version_modal_body, .project_payment_modal_header, .project_payment_modal_body{
      padding: 15px 30px;
      color: #4a4a4a;
  }
  
  .score_version_modal_footer, .project_payment_modal_footer{
      padding: 0px 0px 0px 30px;
      color: #4a4a4a;
  }
  
  .score_version_modal_body li{
      color: #949eab;
  }
  
  .score_version_modal_body li span{
      color: #4a4a4a;
  }
  
  .score_version_modal .close, .project_payment_modal .close {
    position: absolute;
    right: 20px;
    top: 31px;
    width: 32px;
    height: 25px;
    padding-bottom: 26px;
    margin-top: -12px;
    opacity: 1;
  }
  .score_version_modal .close:hover, .project_payment_modal .close:hover {
    opacity: 0.7;
  }
  .score_version_modal .close:before, .close:after, .project_payment_modal .close:before {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 18px;
    width: 1px;
    background-color: #7f8893;
  }
  .score_version_modal .close:before, .project_payment_modal .close:before {
    transform: rotate(45deg);
  }
  .score_version_modal .close:after, .project_payment_modal .close:after {
    transform: rotate(-45deg);
  }
  
  .score_version_modal .btn-primary, .arc-primary {
      color: #fff;
      background-color: #1E88E5;
      border-color: #1E88E5;
  }
  
  .arc-default {
      color: #707479;
  }
  
  .score_version_modal .btn-white {
      color: #4a4a4a;
      background-color: #fff;
      border-color: #c9ccd0;
  }
  
  .score_version_modal .sub-heading {
      padding-top: 5px;
      padding-bottom: 10px;
  }
  
  .full-page-modal .modal-dialog, .advanced_scoring_widget_modal .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .full-page-modal .modal-content, .advanced_scoring_widget_modal .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    border: 0px;
  }
  
  .full-page-modal .modal-header{
      box-shadow: 2px 0 4px 0 rgba(0,0,0,0.26);
  }
  
  .full-page-modal h3 {
      color: #28323B;
      font-weight: 300;
      line-height: 30px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 25px;
  }
  
  .split_screen_vertically .modal-header {
      background-size: cover;
      background-color: #fff;
  }
  
  .split_screen_vertically .split_background {
      background-image: linear-gradient(to bottom, #F8F8F8, #F8F8F8 60%, #fff 40%, #fff);
      background-size: cover;
      background-repeat: no-repeat;
  }
  
  .split_screen_vertically .card {
      border: 1px solid #E1E1E1;
      border-radius: 2px;
      background-color: #FFFFFF;
      box-shadow: 0 1px 1px 0 rgba(0,0,0,0.12);
      display: inline-block;
      float: none;
      padding: 15px;
      width: 500px;
      /* overflow: -webkit-paged-y; */
      /*height: 600px;*/
  }
  #score_code_version_notification .checkbox
  {
      padding: 20px 50px;
  }
  #score_code_other_upgrade_options textarea
  {
      width: 100%;
      height: 150px;
      padding: 15px;
      border-radius: 3px;
      resize: none;
  }
  .score_version_modal ul {
      border: none;
      border-radius: 0px;
  }
  .score_version_modal li {
      padding: 5px;
  }
  .unselectable
  {
      -webkit-user-select: none;    
      -moz-user-select: none; 
      -ms-user-select: none;
      user-select: none; 
  }
  #project_payment_new
  {
      padding-left: 0px !important;
      padding-right: 0px !important;
      z-index: 9999999;
      height: 100%;
      top: 0px;
      position: absolute;
      width: 100%;
      display: none;
  }
  #score_code_version_comparison
  {
      padding-left: 0px !important;
      padding-right: 0px !important;
      /* z-index: 9999999; */
      height: 100%;
  }
  .card_divider
  {
      border-top: 0.1em solid lightgrey;
      margin-top: 15px;
      margin-bottom: 15px;
  }
  .card_info
  {
      text-align: left;
      padding-left: 20px;
  }
  .split_screen_vertically .card_score
  {
      text-align: left;
      font-size: 11px;
      padding-left: 20px;
      /* height: 16px; */
      overflow: auto;
  }
  .smallScorepuckBlank p
  {
      color: white;
      font-weight: 600;
      font-size: 16px;
      padding-top: 3px;
  }
  .wrap_width
  {
      width: 146%;
  }
  .score_box
  {
      margin-right: 15px;
      padding-top: 5px;
      border-radius: 3px;
      padding-bottom: 5px;
      text-align: center;
  }
  .white-bg
  {
      background-color: white !important;
  }
  .score_version_modal .checkbox label:before {
      border: 2px solid #76787d;
  }
  .score_version_uptodate_icon {
      color: #50AE54;
      width: 35px;
      padding-right: 5px;
  }
  .score_version_notuptodate_icon {
      color: #dd5826;
      vertical-align: middle;
      font-size: 30px;
  }
  .score_version_notuptodate_icon_yellow {
      color: #f2be35;
      vertical-align: middle;
      font-size: 30px;
  }
  
  .compare_score_version_spinner {
    margin: 50px auto;
    width: 50px;
    height: 60px;
    text-align: center;
    font-size: 0px;
  }
  
  .compare_score_version_spinner > div {
    background-color: #cdcdcd;
    height: 100%;
    width: 7px;
    margin: 0 3px 0 0;
    display: inline-block;
    
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }
  
  .compare_score_version_spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  
  .compare_score_version_spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  .compare_score_version_spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  
  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
    20% { -webkit-transform: scaleY(1.0) }
  }
  
  @keyframes sk-stretchdelay {
    0%, 40%, 100% { 
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }  20% { 
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }
  
  .no_bullet_li {
      color: transparent !important;
  }
  .remindMeSpinner {
      float: right;
      margin-top: 8px;
      /* margin-right: 10px; */
      border-radius: 23px;
      border-width: 12px;
      width: 9px;
      height: 9px;
      z-index: 2000;
      border: solid 2px transparent;
      border-top-color: darkgray;
      border-left-color: darkgray;
      -moz-animation: spin 400ms linear infinite;
      animation: spin 400ms linear infinite;
  }
  .ui-datepicker-trigger{
      border:none;
      background:none;
  }
  .score_version_extension_gray_border{
      border: 1px solid rgb(148, 158, 171);
  }
  .inline-flex{
      display: inline-flex;
  }
  .date_input_height{
      height: 25px;
  }
  .score_version_form_readonly{
      background: transparent !important;
  }
  .override_row_margin{
      margin-left: 0px;
      margin-right: 0px;
  }
  #score_notification_reminder{
      width: 140px;
  }
  .score_version_remind_me {
      margin-bottom: 12px;
  }
  .score_version_remind_button{
      width: 200px;
  }
  .score_version_remind_button_multiple_option{
      width: 230px;
  }
  .opacity_0{
      opacity: 0;
  }
  .no_border_card{
      border: 0px solid #fff !important;
      box-shadow: 0 0px 0px 0 #fff !important;
      padding-top: 0px !important;
      background-color: transparent !important;
  }
  .hidden_card{
      padding: 0px !important;
      border: 0px !important;
  }
  /*Score code version Ends*/
  .overflow-hidden
  {
      overflow: hidden !important;
  }
  .datepicker
  {
      z-index:100000009 !important;
  }
  .loader_parent {
      /* width: 100%; */
      height: 30%;
      /* padding-top: 5%; */
      margin-left: 45%;
      /* float: left; */
      padding: 10% 0%;
    }
    
    .loader-lg {
      width: 10%;
      height: 50%;
      margin-right: 10px;
    }
  
    .loader {
      position: relative;
      /* margin: 0px auto; */
      /* width: 25px; */
      /* height: 25px; */
      margin-right: 10px;
    }
    
    .loader:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
    
    .circular-loader {
      -webkit-animation: rotate 2s linear infinite;
      animation: rotate 2s linear infinite;
      height: 100%;
      -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: auto;
    }
    
    .loader-path {
      stroke-dasharray: 150, 200;
      stroke-dashoffset: -10;
      -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      stroke-linecap: round;
    }
    
    .loader-white {
      -webkit-animation: dash 1.5s ease-in-out infinite, white 6s ease-in-out infinite !important;
      animation: dash 1.5s ease-in-out infinite, white 6s ease-in-out infinite !important;
    }
    
    .loader-black {
      -webkit-animation: dash 1.5s ease-in-out infinite, black 6s ease-in-out infinite !important;
      animation: dash 1.5s ease-in-out infinite, black 6s ease-in-out infinite !important;
    }
    
    @-webkit-keyframes rotate {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    
    @keyframes rotate {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    
    @-webkit-keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }
    
    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }
    
    @-webkit-keyframes color {
      0% {
        stroke: #949EAB;
      }
      40% {
        stroke: #949EAB;
      }
      66% {
        stroke: #949EAB;
      }
      80%,
      90% {
        stroke: #949EAB;
      }
    }
    
    @keyframes color {
      0% {
        stroke: #949EAB;
      }
      40% {
        stroke: #949EAB;
      }
      66% {
        stroke: #949EAB;
      }
      80%,
      90% {
        stroke: #949EAB;
      }
    }
  .text-align-justify{
      text-align: justify;
  }
  /* End User Agreement Starts*/
  #cookies_confirmation {
      visibility: hidden;
      width: 100%;
      background-color: #f2f2f2;
      border-radius: 2px;
      padding: 15px 50px;
      position: fixed;
      z-index: 9999999;
      bottom: 0px;
      font-size: 14px;
      border-top: #d8d8d8 solid 1px;
      font-weight: 500;
      min-height: 130px;
      padding-top: 30px;
  }

  #cookies_confirmation a{
    color: #1775D2;
  }
  
  #cookies_confirmation.show {
      visibility: visible;
      -webkit-animation: cookiesConfirmationFadein 0.5s;
      animation: cookiesConfirmationFadein 0.5s;
  }
  
  #cookies_confirmation.hide {
      visibility: hidden;
      -webkit-animation: cookiesConfirmationFadeout 0.5s;
      animation: cookiesConfirmationFadeout 0.5s;
  }
  
  @-webkit-keyframes cookiesConfirmationFadein {
      from {bottom: 0px; opacity: 0;} 
      to {bottom: 0px; opacity: 1;}
  }
  
  @-moz-keyframes cookiesConfirmationFadein {
      from {bottom: 0px; opacity: 0;} 
      to {bottom: 0px; opacity: 1;}
  }
  
  @-o-keyframes cookiesConfirmationFadein {
      from {bottom: 0px; opacity: 0;} 
      to {bottom: 0px; opacity: 1;}
  }
  
  @keyframes cookiesConfirmationFadein {
      from {bottom: 0px; opacity: 0;}
      to {bottom: 0px; opacity: 1;}
  }
  
  @-webkit-keyframes cookiesConfirmationFadeout {
      from {bottom: 0px; opacity: 1;} 
      to {bottom: 0px; opacity: 0;}
  }
  
  @-moz-keyframes cookiesConfirmationFadeout {
      from {bottom: 0px; opacity: 1;} 
      to {bottom: 0px; opacity: 0;}
  }
  
  @-o-keyframes cookiesConfirmationFadeout {
      from {bottom: 0px; opacity: 1;} 
      to {bottom: 0px; opacity: 0;}
  }
  
  @keyframes cookiesConfirmationFadeout {
      from {bottom: 0px; opacity: 1;}
      to {bottom: 0px; opacity: 0;}
  }
  
  .accept_cookie_btn {
      line-height: 65px;
  }
  
  a.text-underline, a.text-underline:hover, a.text-underline:focus {
      text-decoration: underline !important;
      color: #dd5826 !important;
  }
  .accept_and_close_btn, .accept_and_close_btn:hover, .accept_and_close_btn:focus {
      background-color: #1775D2;
      border-color: #1775D2;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
  }
  /* End User Agreement Ends*/
  
  .center-div
  {
      display: flex;
      justify-content: center;
  }
  .between-div
  {
      display: flex;
      justify-content: space-between;
  }
  .survey-header
  {
      background-image: url(../images/Survey_Banner@2x.png);
      background-size: 100%;
      height: 90px;
      background-repeat: no-repeat;
  }
  .survey-logo
  {
      margin: 0 auto;
      background-image: url(../images/arc-logo-white.png);
      background-size: 100%;
      width: 80px;
      height: 100%;
      background-position-y: 50%;
      background-repeat: no-repeat;
  }
  .borderBottom
  {
      border-bottom: 1px solid #DDDDDD;
  }
  .survey-card
  {
      border-bottom: 1px solid #DDDDDD;
      padding: 20px 40px;
      /* overflow: auto; */
      background: #fff;
      float: left;
      width: 100%;
  }
  .survey-heading
  {
      font-size: 16px;
      color: #28323B;
      font-weight: 400;
  }
  .survey-card .btn-survey {
      display: flex;
      justify-content: space-between;
      border-radius: 2px !important;
      height: 36px;
      border: 1px solid #BAC1CA;
      background-color: white;
  }
  .survey-card .fa-chevron-down, .survey-unit .fa-chevron-down {
      color: #BAC1CA;
      margin-left: 5px;
      margin-top: 4px;
  }
  .route-icon
  {
      height: 30px;
      width: 30px;
      margin-right: 21px;
  }
  .bus
  {
      background-image: url(../images/bus.svg);
  }
  .car
  {
      background-image: url(../images/car_icon.svg);
  }
  .car23
  {
      background-image: url(../images/carpool_icon.svg);
  }
  .cars4
  {
      background-image: url(../images/cars4.svg);
  }
  .heavy_rail
  {
      background-image: url(../images/heavy_rail.svg);
  }
  .light_rail
  {
      background-image: url(../images/light_rail.png);
      background-size: 30px;
      background-repeat: no-repeat;
  }
  .motorcycle
  {
      background-image: url(../images/motorcycle.svg);
  }
  .walk, .walk_bike_telecommute
  {
      background-image: url(../images/walk_icon.svg);
  }
  .bike
  {
      background-image: url(../images/bike_icon.svg);
  }
  .telecommute
  {
      background-image: url(../images/telecomute_icon.svg);
  }
  .route-label
  {
      display: flex;
  }
  .mode-list
  {
      width: 100%;
      margin-top: 10px;
      border-radius: 2px;
      padding: 0px 30px;
      /* border: 1px solid #DDDDDD; */
  }
  .mode-option-border
  {
      border-bottom: 1px solid #DDDDDD;
  }
  .mode-option 
  {
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      justify-content: space-between;
      color: #4A4A4A;
  }
  .route-value
  {
      font-weight: 600;
      /* white-space: nowrap; */
      text-align: right;
      overflow: hidden;
      width: 100px;
      text-overflow: ellipsis;
  }
  .route_list input
  {
      border: 1px solid #ccc;
      /* width: 15%; */
  }
  .route_list .modal-header
  {
      padding: 10px 35px;
  }
  .route_list .modal-footer
  {
      padding: 10px 35px 25px 35px;
  }
  .route_list .btn-success
  {
      min-width: 90px;
      height: 40px;
      margin-right: 20px;
      background-color: #50AE54;
  }
  .survey-card .btn-primary:active, .btn-survey.btn-primary:active
  {
      box-shadow: none;
  }
  .survey-card .btn-primary, .btn-survey.btn-primary
  {
      min-width: 90px;
      height: 40px;
      background-color: #4A90E2;
      color: #FFFFFF;
      font-family: AmericaMedium;
      font-weight: 500;
      border: none;
      border-radius: 2px;
  }
  .route_list .modal-body
  {
      padding: 5px 35px;
  }
  .route_list .modal-header, .route_list .modal-body, .route_list .modal-footer 
  {
      border-bottom: none;
      border-top: none;
  }
  .grey-text
  {
      color: #949EAB;
  }
  .new-route
  {
      color: #707479;
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
  }
  .route_list .modal-close
  {
      font-size: 28px;
      color: #949EAB;
  }
  
  .blank .rangeslider__handle
  {
	background-color: #1A76D1;
  }
  .blank .rangeslider--horizontal
  {
	background-color: #E4E7EA !important;
  }
  .selected .rangeslider__handle
  {
	background-color: #1A76D1;
  }
  .selected .rangeslider__fill
  {
	background-color: #1A76D1;
  }
  
  .slider-text
  {
      margin-top: 30px;
      margin-bottom: 35px;
      text-align: center;
  }
  .survey-card .checkbox input[type="checkbox"]:checked + label::after, .checkbox input[type="radio"]:checked + label::after
  {
      background-color: #3864b9;
      top: 2px;
      border-radius: 2px;
      background-color: #3864b9;
      color: white;
  }
  
  .survey-card .input-md
  {
      border-radius: 2px;
      border: 1px solid #CDCDCD;
      height: 33px;
      padding: 5px 10px;
  }
  .sliderError
  {
      background-color: #FFEBEE;
      padding: 15px;
      overflow: auto;
  }
  .survey-card .error 
  {
      color: #D84315;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 0px;
  }
  .survey-options label
  {
      display: flex;
  }
  .survey-summary td
  {
      padding: 5px 0px;
  }
  .list-item
  {
      border-radius: 4px;
      background-color: #50AE54;
      height: 8px;
      width: 8px;
      margin-top: 6px;
      margin-right: 5px;
  }
  .pb75{
      padding-bottom: 75px !important;
  }
  .survey-widget
  {
      box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
  }
  .survey-footer
  {
      color: #949EAB;
      font-size: 12px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
  }
  .reload-survey
  {
      height: 40px;
      border-radius: 3px;
      background-color: #50AE54;
  }
  
  .route_list .dropdown-menu.left li {
    padding: 3px 0px;
  }
  
  .route_list .dropdown-menu.left {
    right: -1px;
    left: auto;
    top: -3px;
    border: none;
    border-radius: 0px;
    box-shadow: 1px 2px 16px 0px rgba(0, 0, 0, 0.23);
  }
  
  .route_list .dropdown-menu {
    left: initial;
    top: -3px;
    border: none;
    border-radius: 0px;
    padding: 15px 20px;
    box-shadow: 1px 2px 16px 0px rgba(0, 0, 0, 0.23);
  }
  
  .route_list .dropdown-lg {
    width: 100%;
    padding: 7px 15px;
    font-size: 14px;
    font-weight: normal;
    color: #28323B;
    text-align: left;
  }
  
  .route_list .dropdown-md {
    width: 40%;
    padding: 7px 15px;
    font-size: 14px;
    font-weight: normal;
    color: #28323B;
    text-align: left;
  }
  .btn-survey-unit:focus, .btn-survey-unit.focus, .btn-survey-unit:active:focus, .btn-survey-unit:active.focus, .btn-survey-unit.active:focus, .btn-survey-unit.active.focus
  {
      border: none;
      box-shadow: none;
  }
  .btn-survey-unit
  {
      background: none;
      padding-right: 0px;
  }
  .survey-card select
  {
      background-color: white;
      border: 1px solid #CDCDCD;
      border-radius: 2px;
      padding: 6px 12px;
      font-size: 14px;
      -webkit-appearance: none;
      appearance: none;
  }
  #survey_language
  {
      width: 120px;
  }
  .route-value.error
  {
      border: 2px solid #D84315 !important;
      float: right;
  }
  .route-value-error a
  {
      text-decoration: underline;
  }
  .route-value-error
  {
      color: #D84315;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0px;
      padding-top: 38px;
      /* padding-right: 2px; */
  }
  .error_symbol-sm {
      background-size: 25px !important;
      height: 23px !important;
      width: 23px !important;
      background-position-x: -2px !important;
      background-position-y: -3px !important;
  }
  .error_symbol {
      background: url(../images/alert_circle_icon.svg) no-repeat;
      background-size: 28px;
      height: 28px;
      width: 28px;
      float: left;
  }
  .survey-satisfaction-summary
  {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
  }
  textarea[name=organization], textarea[name=project_address] {
    resize: none;
    background-color: #fff !important
  }
  
  .deactivateInput_manage
  {
      border: none;
      background: none;
      padding-left: 0px;
  }
  
  .gresb_score{
      width: 30px;
      display: inline-block;
  }
  
  .r15-version{
     right: 15px !important;
  }
  
  .popover{
      width: 600px !important
  }
  .disable-bind .handle
  {
      color: inherit;
      cursor: default;
  }
  .error_symbol-sm, .success_symbol-sm, .success_symbol_grey-sm
  {
      background-size: 23px !important;
      height: 23px !important;
      width: 23px !important;
  }
  .success_symbol_grey:hover
  {
      text-decoration: underline;
  }
  .success_symbol_grey
  {
    background: url(../images/updated_open_icon_grey.svg) no-repeat;
    background-size: 28px;
    height: 28px;
    width: 102px;
    padding-left: 28px;
    cursor: pointer;
    /* position: relative; */
    /* right: 73px; */
    font-size: 12px;
    padding-top: 5px;
    color: #68737D;
  }
  .success_symbol
  {
      background: url(../images/updated_open_icon.svg) no-repeat;
      background-size: 28px;
      height: 28px;
      width: 28px;
  }
  .error_symbol
  {
      background: url(../images/alert_circle_icon.svg) no-repeat;
      background-size: 28px;
      height: 28px;
      width: 28px;
  }
  .arc-btn-sm
  {
      padding: 5px 12px;
      font-size: 13px !important;;
  }
  .arc-btn
  {
      background-color: white;
      font-size: 14px;
      color: #306EB7;
      font-weight: 600;
      border-color: lightgrey;
      border-radius: 2px !important;
  }
  .flex-center-align
  {
      display: flex;
      justify-content: center;
  }
  .left-right-align
  {
      display: flex;
      justify-content: space-between;
  }
  .arc-header-5
  {
      font-size: 16px;
      font-weight: 600;
  }
  .arc-modal-header
  {
      background-color: #F8F8F8;
      border-bottom: 1px solid #e5e5e5 !important;
  }
  .gresb-project-info
  {
      border: 1px solid #e5e5e5 !important;
      padding: 20px;
      margin-top: 15px;
  }
  .arc-btn-primary
  {
      background-color: #4A90E2;
      color: white;
      border-color: #4A90E2;
  }
  .search-dropdown
  {
      position: relative;
      display: inline-block;
      width: 100%;
  }
  .search-dropdown::before
  {
      position: absolute;
      content: " ▼";
      color: #4a4a4a;
      top: 11px;
      right: 7px;
      font-size: 9px;
      height: 11px;
      width: 7px;
  }
  .search-list 
  {
      height: 200px;
      overflow-y: scroll;
      position: absolute;
      background-color: white;
      border-left: 1px solid lightgrey;
      font-size: 13px;
      border-right: 1px solid lightgrey;
      border-bottom: 1px solid lightgrey;
      width: 100%;
      font-weight: 400;
      color: black;
      border-radius: 0px 0px 2px 2px;
  }
  .search-list-item:hover
  {
      background-color: #eee;
  }
  .search-list-item 
  {
      padding: 10px;
      cursor: pointer;
      margin: 0px;
  }
  .border-top-grey
  {
      border-top: 1px solid #e5e5e5 !important;
  }
  .error-color
  {
      color: #EF4C00;
  }
  .mr4
  {
      margin-right: 4px !important;
  }
  .mt4
  {
      margin-top: 4px !important;
  }
  .gresb_div
  {
      border-left: 1px solid lightgrey;
      padding-left: 30px;
  }
  .gresb_modal .modal-footer
  {
      border: none;
  }
  .gresb_modal .modal-body
  {
      padding: 15px 30px 20px 30px;
  }
  .gresb_modal .appLogo
  {
      height: 60px;
      margin: 0px;
      background-position: 0%;
      margin-top: -15px;
  }
  .gresb_modal .logo
  {
      width: 35%;
      margin: 0px;
      height: 45px;
  }
  .gresb_modal .modal-header
  {
      background-color: #242424;
  }
  .dataInputLoaderSpinner-white
  {
      border-top-color: white;
      border-left-color: white;
  }
  .dataInputLoaderSpinner-sm
  {
      margin-top: 6px;
      width: 10px;
      height: 10px;
      margin-bottom: 0px;
      position: relative;
      left: -10px;
      float: left;
  }
  .dataInputLoaderSpinner-md
  {
        float: none;
        width: 14px;
        height: 14px;
        margin-top: 6px;
  }
  .dataInputLoaderSpinner-lg
  {
      float: none;
      margin-top: 10px;
      width: 30px;
      height: 30px;
      margin-bottom: 10px;
  }
  .color_dark_gray.fa-trash:hover
  {
      color: black;
  }
  .width60p
  {
      width: 60%;
  }
  .arc-input-error input
  {
      border: 2px solid #D84315 !important;
  }
  .reading_error_label 
  {
      margin-bottom: 5px;
      margin-top: 0px;
      font-size: 12px;
      color: #D84315;
      font-weight: 400;
      float: left;
  }
  .border_bottom_0{
      border-bottom: 0px !important;
  }
  .gresb_modal .btn-default, .estimate_modal .btn-default
  {
      background-color: white;
  }
  .gresb_modal .btn-md, .estimate_modal .btn-md
  {
      font-weight: 500;
      font-family: AmericaMedium;
      padding: 5px 25px;
      border-radius: 3px;
      font-size: 14px;
      line-height: 24px;
  }
  .ml50p
  {
      margin-left: 50%;
  }
  .gresb_upload
  {
      background-image: url(../images/add_spreadsheet_icon.svg);
      background-repeat: no-repeat;
      background-position: 9px 8px;
      padding: 7px 25px 7px 35px !important;
      background-size: 21px;
  }
  .ellipsis_80
  {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 80%;
  }
  .ellipsis_100
  {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      float: left;
      width: 100%;
  }
  .project_type_wrapper .bootstrap-select .dropdown-toggle .filter-option-inner-inner
  {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    width: 93%;
  }
  .ellipsis
  {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      float: left;
      width: 160px;
  }
  .arc-breadcrumbs
  {
      display: flex;
      /* justify-content: center; */
  }
  .arc-breadcrumbs div 
  {
      padding: 4px;
      width: 25%;
      margin: 0px 1px;
      background-color: #E0E2E9;
  }
  .arc-breadcrumbs div:nth-child(1)
  {
      border-radius: 6px 0px 0px 6px;
  } 
  .arc-breadcrumbs div:nth-child(3)
  {
      border-radius: 0px 6px 6px 0px;
  } 
  .arc-breadcrumbs.red div:nth-child(1)
  {
      background: #EF4C00;
  }
  .arc-breadcrumbs.orange div:nth-child(1), .arc-breadcrumbs.orange div:nth-child(2)
  {
      background: #F5A623; /* orange now equals yellow ¯\_(ツ)_/¯ */
  }
  .arc-breadcrumbs.green div:nth-child(1), .arc-breadcrumbs.green div:nth-child(2), .arc-breadcrumbs.green div:nth-child(3)
  {
      background: #50AE54;
  }
  .p2535
  {
      padding: 25px 35px;
  }
  .p1632
  {
	padding: 16px 32px 30px 32px;
	border-radius: 5px;
  }
  .estimate_score_card .arc-breadcrumbs
  {
      justify-content: left !important;
  }
  .estimate_score_card
  {
      display: flex;
      width: 50%;
      padding: 20px;
      border: 1px solid #E0E2E9;
  }
  .estimate_score_card .item:nth-child(2)
  {
      width: 50%;
      padding-left: 20px;
  }
  .estimate_score_card .item:nth-child(1)
  {
      width: 50%;
      border-right: 1px solid #E0E2E9;
  }
  .estimate_modal p, .estimate_modal ul
  {
      font-size: 16px;
  }
  .estimate_score_card p
  {
      font-size: 13px;
  }
  .estimate_list li
  {
      margin-bottom: 10px
  }
  .error_symbol-xs
  {
    width: 19px !important;
    background-size: 19px !important;
    height: 19px !important;
    background: url(https://static-app.arconline.io/data-input-widget-assets-nonprd/svg/alert_circle_icon.svg) no-repeat;
    background-position: 0px 3px;
    outline: none;
  }
  #end_review_date{
      background-color: transparent;
  }
  
  /* New user billing UI starts */
  
  /* basic stylings ------------------------------------------ */
  
  .bill-container 		{ 
    font-family:"Open Sans", sans-serif;
      margin-left: 100px;
      margin-top: -30px;
      font-size:16px;
      box-sizing: border-box;
      
  }
  
  /* form starting stylings ------------------------------- */
  .form_group 			  { 
    position:relative; 
    margin-bottom:45px; 
  }
  .bill-input 				{
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
    font-size:16px;
    padding:10px 10px 10px 5px;
    display:block;
    width:400px;
    border:none;
    border-bottom:1px solid #757575;
  }
  .bill-input:focus 		{ outline:none; }
  
  /* LABEL ======================================= */
  .bill-label 				 {
    color:#999; 
    font-size:16px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:5px;
    top:10px;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  
  /* active state */
  .bill-input:focus ~ .bill-label, .bill-input:valid ~ .bill-label 		{
    top:-20px;
    font-size:14px;
    color:#5264AE;
  }
  
  /* BOTTOM BARS ================================= */
  .bill-bar 	{ position:relative; display:block; width:400px; }
  .bill-bar:before, .bill-bar:after 	{
    content:'';
    height:2px; 
    width:0;
    bottom:1px; 
    position:absolute;
    background:#5264AE; 
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  .bill-bar:before {
    left:50%;
  }
  .bill-bar:after {
    right:50%; 
  }
  
  /* active state */
  .bill-input:focus ~ .bill-bar:before, .bill-input:focus ~ .bill-bar:after {
    width:50%;
  }
  
  /* HIGHLIGHTER ================================== */
  .bill-highlight {
    position:absolute;
    height:60%; 
    width:100px; 
    top:25%; 
    left:0;
    pointer-events:none;
    opacity:0.5;
  }
  
  /* active state */
  .bill-input:focus ~ .bill-highlight {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
  }
  
  /* ANIMATIONS ================ */
  @-webkit-keyframes inputHighlighter {
      from { background:#5264AE; }
    to 	{ width:0; background:transparent; }
  }
  @-moz-keyframes inputHighlighter {
      from { background:#5264AE; }
    to 	{ width:0; background:transparent; }
  }
  @keyframes inputHighlighter {
      from { background:#5264AE; }
    to 	{ width:0; background:transparent; }
  }
  
  .bill-button {
    position: relative;
    background: currentColor;
    border: 1px solid currentColor;
    font-size: 16px;
    font-weight: 500;
    color: #4f93ce;
    margin-right: 30px;
    margin-top: 0px;
    padding: 0.75rem 2rem;
    cursor: pointer;
    -webkit-transition: background-color 0.28s ease, color 0.28s ease, box-shadow 0.28s ease;
    transition: background-color 0.28s ease, color 0.28s ease, box-shadow 0.28s ease;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .bill-button span {
    color: #fff;
    position: relative;
    z-index: 1;
  }
  .bill-button::before {
    content: '';
    position: absolute;
    background: #071017;
    border: 50vh solid #1d4567;
    width: 30vh;
    height: 30vh;
    border-radius: 50%;
    display: block;
    top: 50%;
    left: 50%;
    z-index: 0;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
  }
  .bill-button:hover {
    color: #337ab7;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  }
  .anchor-color {
    color: #337ab7;
  }
  .bill-button:active::before, .bill-button:focus::before {
    -webkit-transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
    transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
    transition: transform 1.12s ease, opacity 0.28s ease 0.364s;
    transition: transform 1.12s ease, opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
  .bill-button:focus {
    outline: none;
  }
  
  .bill-cancel-button {
    position: relative;
    background: currentColor;
    border: 1px solid currentColor;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    padding: 0.75rem 2rem;
    cursor: pointer;
    -webkit-transition: background-color 0.28s ease, color 0.28s ease, box-shadow 0.28s ease;
    transition: background-color 0.28s ease, color 0.28s ease, box-shadow 0.28s ease;
    overflow: hidden;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .bill-cancel-button span {
    color: #000000;
    position: relative;
    z-index: 1;
  }
  .bill-cancel-button::before {
    content: '';
    position: absolute;
    background: #071017;
    border: 50vh solid #1d4567;
    width: 30vh;
    height: 30vh;
    border-radius: 50%;
    display: block;
    top: 50%;
    left: 50%;
    z-index: 0;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
  }
  .bill-cancel-button:hover {
    color: #ffffff;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  }
  .bill-cancel-button:active::before, .bill-cancel-button:focus::before {
    -webkit-transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
    transition: opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
    transition: transform 1.12s ease, opacity 0.28s ease 0.364s;
    transition: transform 1.12s ease, opacity 0.28s ease 0.364s, -webkit-transform 1.12s ease;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
  .bill-cancel-button:focus {
    outline: none;
  }
  
  .twoline {
  list-style-type: none;
  padding:0;
  margin:0;
  
  }
  .twoline > li {
  display: inline-block;
  width: 200px;
  }
  .shortinput 				{
    font-size:16px;
    font-family: "Open Sans", sans-serif;
  font-weight: 500;
    padding:10px 10px 1.7px 5px;
    display:block;
    width:190px;
    border:none;
    margin-left: 10px;
    border-bottom:1px solid #757575;
  }
  
  .shortinput:focus ~ .bill-highlight {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
  }
  .shortinput:focus ~ .bill-bar:before, .shortinput:focus ~ .bill-bar:after {
    width:50%;
    margin-left: 10px;
  }
  .shortinput:focus ~ .bill-label, .shortinput:valid ~ .bill-label 		{
    top:-20px;
    font-size:14px;
    color:#5264AE;
    margin-left: 10px;
  }
  .shortinput:focus 		{ outline:none; }
  .shortbill-bar 	{ position:relative; display:block; width:180px; margin-left: 10px; }
  
  .bill-select {
    width: 400px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
  font-weight: 500;
    height: 2.6rem;
    padding-top: 0px;
    background: none;
    border: none;
    line-height: 1.6;
    box-shadow: none;
    color: #333;
    border-bottom:1px solid #757575;
  }
  .bill-select:focus ~ .bill-label, .bill-select:valid ~ .bill-label        {
    top:-30px;
    font-size:14px;
    color:#5264AE;
  }
  .bill-select:focus{
       outline: none;
  }
  .bill-select:focus ~ .bill-bar:before {
      width: 100%;
    left: 0;
    bottom: 10;
    }
  
  .bill-shortselect {
    width: 190px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
  font-weight: 500;
    height: 2.6rem;
    padding-top: 0px;
    background: none;
    border: none;
    line-height: 1.6;
    box-shadow: none;
    color: #333;
    border-bottom:1px solid #757575;
  }
  .bill-shortselect:focus ~ .bill-label, .bill-shortselect:valid ~ .bill-label        {
    top:-30px;
    font-size:14px;
    color:#5264AE;
  }
  .bill-shortselect:focus{
       outline: none;
  }
  .bill-shortselect:focus ~ .bill-bar:before {
      width: 190px;
    left: 0;
    margin-left: -10px;
    bottom: 10;
    }
  /* New user billing UI Ends */
  
  /* Payment Method UI Starts*/
  
  
  
  .card-label                  {
      font-weight:600;
    position:absolute;
    pointer-events:none;
    left:5px;
    top:-20px;
    font-size:14px;
    color:#383838;
  }
  
  .card-input                 {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
    font-size:16px;
    padding:10px 10px 10px 5px;
    display:block;
    width:260px;
    border:none;
    border-bottom:2.49px solid #d3d3d3;
  
  }
  
  .promo{
  
      width:230px !important;
      margin-left: 30px;
  }
  
  .credit{
      background: url(../images/credit-card.png) no-repeat scroll 1px 1px;
      padding-left:30px;
  }
  
  .calendar{
      background: url(../images/calendar-short.png) no-repeat scroll 1px 1px;
  }
  
  .lock{
      background: url(../images/lock.png) no-repeat scroll 1px 1px;
  }
  
  .card-input:focus       { outline:none; }
  
  
  /* active state */
  .card-input:focus ~ .bill-label, .card-input:valid ~ .bill-label        {
    top:-20px;
    font-size:14px;
    color:#5264AE;
  }
  
  /* BOTTOM BARS ================================= */
  .card-bar   { position:relative; display:block; width:260px; }
  
  .card-bar:before, .card-bar:after   {
    content:'';
    height:2px; 
    width:0;
    bottom:1px; 
    position:absolute;
    background:#5264AE; 
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  .card-bar:before {
    left:50%;
  }
  .card-bar:after {
    right:50%; 
  }
  
  /* active state */
  .card-input:focus ~ .card-bar:before, .card-input:focus ~ .card-bar:after {
    width:50%;
  }
  
  /* active state */
  .card-input:focus ~ .bill-highlight {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
  }
  
  /*checkbox*/
  /* Create a custom checkbox */
  /* Customize the label (the container) */
  .check-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size:16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
     border-radius: 10%;
  }
  
  /* On mouse-over, add a grey background color */
  .check-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .check-container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .check-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .check-container .checkmark:after {
    left: 7px;
    top: 1px;
    width: 5px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .cardshort-label
  {
      font-weight:600;
      position:absolute;
      pointer-events:none;
      left:5px;
      top:-20px;
      font-size:14px;
      color:#383838;
  }
  
  .cardshort-input                 {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
    font-size:16px;
    padding:10px 10px 10px 5px;
    padding-left: 30px;
    display:block;
    width:120px;
    border:none;
    border-bottom:2.49px solid #d3d3d3;
  }
  .cardshort-input:focus       { outline:none; }
  
  
  /* active state */
  .cardshort-input:focus ~ .bill-label, .cardshort-input:valid ~ .bill-label        {
    top:-20px;
    font-size:14px;
    color:#5264AE;
  }
  /* BOTTOM BARS ================================= */
  .cardshort-bar   { position:relative; display:block; width:120px; }
  .cardshort-bar:before, .cardshort-bar:after   {
    content:'';
    height:2px; 
    width:0;
    bottom:1px; 
    position:absolute;
    background:#5264AE; 
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  .cardshort-bar:before {
    left:50%;
  }
  .cardshort-bar:after {
    right:50%; 
  }
  
  /* active state */
  .cardshort-input:focus ~ .cardshort-bar:before, .cardshort-input:focus ~ .cardshort-bar:after {
    width:50%;
  }
  
  /* active state */
  .cardshort-input:focus ~ .bill-highlight {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
  }
  .cardcvv-label
  {
      font-weight:600;
      position:absolute;
      pointer-events:none;
      left:18px;
      top:-20px;
      font-size:14px;
      color:#383838;
  }
  .twolineshort {
  list-style-type: none;
  padding:0;
  margin:0;
  
  }
  .twolineshort > li {
  display: inline-block;
  width: 125px;
  }
  
  .twolinecredit {
  list-style-type: none;
  padding:0;
  margin:0;
  
  }
  .twolinecredit > li {
  display: inline-block;
  width: 220px;
  }
  .promo-header
  {
      font-size: 16px;
      color: grey;
  }
  .totaltext{
      font-size:16px;
    font-weight:normal;
     margin-left: 187px;
  }
  .single-article .article-container-inner {
      background: #f0eded;
      border: 1px solid #c9c7c7;
      padding: 20px;
      margin-bottom: 20px;
  }
  .single-article hr {
      margin: 30px -20px 20px;
      border: 0;
      border-top: 1px solid #c9c7c7;
  }
  .promostyle{
     vertical-align: top;
     padding-top: 22px;
  }
  
  .cvvicon{
      color: #4f93ce;
      font-size: 17px;
  }
  
  /* Payment Method UI Ends*/
  
  .grid {
      margin:10px;padding:0px;
      width:15%;
      
      
      -moz-border-radius-bottomleft:0px;
      -webkit-border-bottom-left-radius:0px;
      border-bottom-left-radius:0px;
      
      -moz-border-radius-bottomright:0px;
      -webkit-border-bottom-right-radius:0px;
      border-bottom-right-radius:0px;
      
      -moz-border-radius-topright:0px;
      -webkit-border-top-right-radius:0px;
      border-top-right-radius:0px;
      
      -moz-border-radius-topleft:0px;
      -webkit-border-top-left-radius:0px;
      border-top-left-radius:0px;
  }.grid table{
      border-collapse: collapse;
          border-spacing: 0;
      width:100%;
      height:100%;
      margin:0px;padding:0px;
  }.grid tr:last-child td:last-child {
      -moz-border-radius-bottomright:0px;
      -webkit-border-bottom-right-radius:0px;
      border-bottom-right-radius:0px;
  }
  .grid table tr:first-child td:first-child {
      -moz-border-radius-topleft:0px;
      -webkit-border-top-left-radius:0px;
      border-top-left-radius:0px;
  }
  .grid table tr:first-child td:last-child {
      -moz-border-radius-topright:0px;
      -webkit-border-top-right-radius:0px;
      border-top-right-radius:0px;
  }.grid tr:last-child td:first-child{
      -moz-border-radius-bottomleft:0px;
      -webkit-border-bottom-left-radius:0px;
      border-bottom-left-radius:0px;
  }.grid tr:hover td{
      background-color:#ffffff;
          
  
  }
  .grid td{
      vertical-align:middle;
      
      background-color:#ffffff;
  
      border:1px solid #000000;
      border-width:0px 1px 1px 0px;
      text-align:left;
      padding:7px;
      font-size:14px;
      font-family:Trebuchet MS;
      font-weight:normal;
      color:#000000;
  }.grid tr:last-child td{
      border-width:0px 1px 0px 0px;
  }.grid tr td:last-child{
      border-width:0px 0px 1px 0px;
  }.grid tr:last-child td:last-child{
      border-width:0px 0px 0px 0px;
  }
  .grid tr:first-child td{
          background:-o-linear-gradient(bottom, #337ab7 5%, #337ab7 100%);    background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #337ab7), color-stop(1, #337ab7) );
      background:-moz-linear-gradient( center top, #337ab7 5%, #337ab7 100% );
      filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#337ab7", endColorstr="#337ab7");  background: -o-linear-gradient(top,#337ab7,337ab7);
  
      background-color:#337ab7;
      border:0px solid #000000;
      text-align:center;
      border-width:0px 0px 1px 1px;
      font-size:16px;
      font-family:Trebuchet MS;
      font-weight:bold;
      color:#ffffff;
  }
  .grid tr:first-child:hover td{
      background:-o-linear-gradient(bottom, #337ab7 5%, #337ab7 100%);    background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #337ab7), color-stop(1, #337ab7) );
      background:-moz-linear-gradient( center top, #337ab7 5%, #337ab7 100% );
/*      filter:prosoft.gradient(startColorstr="#337ab7", endColorstr="#337ab7");  background: -o-linear-gradient(top,#337ab7,337ab7rogid:DXImageTransform.Mic);*/
  
      background-color:#337ab7;
  }
  .grid tr:first-child td:first-child{
      border-width:0px 0px 1px 0px;
  }
  .grid tr:first-child td:last-child{
      border-width:0px 0px 1px 1px;
  }
  
  .grid tr td[ng-click] {
      cursor: pointer;
  }
  
  .trial-banner {
      background: #FFF8EB;
      color: black;
      border: none;
      text-align: center;
      font-size: 15px;
      padding: 10px 0px;
      z-index: 999;
      width: 100%;
      top: 0px;
      font-weight: 400;
      display: none;
  }
  
  .trial-expired
  {
      display: flex;
      font-weight: 400;
      font-size: 13px;
  }
  .trial-expired .error
  {
      color: #EF4C00;
  }
  .trial-expired p
  {
      margin-bottom: 0px;
  }
  .arc-btn-xs
  {
      padding: 4px 8px;
      font-size: 12px !important;
  }
  
  .introjs-tooltip
  {
      padding: 30px 23px;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,0.23);
      border-radius: 2px;
      max-width: 560px !important ;
      width: 560px;
      top: 0px !important ;
  }
  .introjs-overlay
  {
      background: #4A4A4A;
  }
  .introjs-tooltiptext span
  {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
  }
  .introjs-tooltiptext p
  {
      line-height: 22px;
      margin-top: 10px;
  }
  
  .introjs-arrow.right-bottom 
  {
      top: 10px;
      bottom: auto;
  }
  .introjs-button
  {
      color: #FFFFFF;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      text-align: center;
      background-image: none;
      background-color: #50AE54;
      border: none;
      border-radius: 3px;
      text-shadow: none;
      height: 20px;
      width: 63px;
  }
  .w90p
  {
      width: 90% !important;
  }
  .w80px
  {
      width: 80px;
  }
  .arc_banner_button:after {
      background-color: transparent !important;
  }
  .survey-options {
      text-align: left;
  }
  .custom-chevron 
  {
      position: absolute;
      top: 6px;
      right: 10px;
      color: #CACFD5;
      font-size: inherit;
  }
  .only_border {
      border: 1px solid #d1d1d1 !important;
  }
  .warning_min_score {
      position: absolute;
      margin-left: 10px;
      margin-top: 3px;
  }
  .score_version_help
  {
      overflow: hidden;
      height: 110px;
      margin-bottom: 10px;
  }
  .line_height_18 {
      line-height: 18px !important;
  }
  .line_height_20 {
      line-height: 20px !important;
  }
  .line_height_22 {
      line-height: 22px !important;
  }
  .line_height_19 {
      line-height: 19px !important;
  }
  .line_height_24 {
      line-height: 24px !important;
  }
  .line_height_26 {
      line-height: 26px !important;
  }
  .line_height_32 {
      line-height: 32px !important;
  }
  .line_height_35 {
      line-height: 35px !important;
  }
  .line_height_39 {
      line-height: 39px !important;
  }
  .line_height_40 {
      line-height: 40px !important;
  }
  .agreement_tag:hover
  {
      color: #165c82;
  }
  .agreement_tag.reg
  {
    position: relative;
    top: -24px;
    left: 23px;
  }
  .agreement_tag
  {
      font-weight: 600;
      color: #1465C0;
      cursor: pointer;
  }
  .review_return:hover
  {
      color: black;
      border: 1px solid black;
  }
  .review_return
  {
      top: -5px;
      position: relative;
      left: 10px;
      font-size: 16px;
      color: darkgrey;
      border-radius: 14px;
      padding: 3px 7px;
      border: 1px solid darkgray;
  }
  .relative-position
  {
      position: relative;
  }
  #occupancy_trend_chart
  {
      height: 500px !important;
  }
  /* Measurabl Changes */
  .arc-table button
  {
    color: #2164B2;
    background-color: white;
    border: 1px solid #DDDDDD;
    font-size: 15px;
    font-weight: 500;
    font-family: AmericaMedium;
  }
  .arc-table td
  {
      border-top: none !important;
      border-bottom: 1px solid #E9EBED;
      /* padding: 15px 10px !important; */
      vertical-align: middle !important;
      padding-left: 0px !important;
  }
  .arc-table tbody tr
  {
      font-weight: 400;
  }
  .arc-table tr
  {
      background-color: white !important;
  }
  .arc-table
  {
      color: #28323B;
      font-size: 15px;
  }
  .arc-table th
  {
      border-bottom-width: 1px !important;
      border-bottom: 1px solid #D8DCDE !important;
      padding-left: 0px !important;
  }
  .meterNav {
      width: 100%;
      float: left;
      /* margin-top: 15px; */
      margin-bottom: 15px;
      padding: 0;
      /* padding-left: 20px; */
      list-style: none;
      border-bottom: 1px solid #DDDDDD;
    }
  .meterNav .meterNav--item 
  {
      float: left;
      cursor: pointer;
      margin-right: 30px;
  }
  
  .meterNav .meterNav--item span 
  {
      display: block;
      padding: 7px 0px;
      padding-bottom: 19px;
      color: #68737D;
      font-size: 16px;
      line-height: 22px;
  }
  .meterNav .meterNav--item span.meterNav--item__active,.meterNav .meterNav--item span:hover
  {
      border-bottom: 2px solid #4A4A4A;
      margin-bottom: -1.5px;
      color: var(--title-label);
      font-weight: 600;
  }
  .vertical_align_top{
      vertical-align: top !important;
  }
  #add_integrations:hover
  {
      color: #333;
      background-color: #dfdfdf;
      border-color: #adadad;
  }
  #add_integrations
  {
      color: #2164B2;
      background-color: white;
      font-weight: 600;
      margin-top: 10px;
      border: 1px solid #DDDDDD;
  }
  .slider_info
  {
      font-size: 12px;
      font-weight: 600;
      /* color: #4a4a4a; */
      float: left;
      padding: 20px 25px;
      height: 100%;
  }
  #increase_carbon_score .analysis_slider_container, #increase_source_score .analysis_slider_container
  {
      width: 70%;
      float: right;
      margin-right: 20px;
  }
  #increase_energy_score .analysis_slider_container
  {
      width: 75%;
      margin-right: 20px;
  }
  #increase_carbon_score_by, #increase_source_score_by
  {
      margin-top: -41px;
      margin-left: 20px;
  }
  #increase_energy_score_by
  {
      margin-top: -15px;
  }
  #increase_carbon_score_handle, #increase_source_score_handle
  {
      margin-top: 55px;
  }
  .slider_x_range_0
  {
      position: absolute;
      left: -1px;
      font-size: 14px;
      bottom: 0px;
      top: 15px;
  }
  .slider_x_range_n
  {
      position: absolute;
      right: 0px;
      font-size: 14px;
      bottom: 0px;
      top: 15px;
  }

/* ARC V2 */

.sidebar.white
{
    background-color: white;
}
.sidebar.grey
{
    background-color: #F4F5F8;
}
.sidebar
{
    top: 114px;
    width: 17%;
    height: 100%;
}
.navbar_info
{
    margin-left: 0px;
    left: 0px;
    position: fixed;
    height: 64px;
    top: 56px;
    padding-left: 24px;
    padding-right: 24px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.26);
    z-index: 2;
}
.navbar_info h3
{
    font-weight: 500;
    color: #28323B;
    line-height: 40px;
    margin-top: 12px;
    font-family: 'AmericaMedium';
}
.navbar
{   
    background-color: #28323B;
    height: 56px;
    margin-left: 0px;
    left: 0px;
    position: fixed;
    z-index: 3;
}
#content
{
    background-color: white;
    padding: 0px 0px 0px 0px;
    position: relative;
    top: 120px;
}
.nav_options div.active, .nav_options div:hover
{
    color: #FFFFFF;
    cursor: pointer;
    -webkit-transition: color 0.5s;
    transition: color 0.5s;
}
.nav_options div
{
    margin-right: 40px;
}
.tranparent
{
    opacity: 0 !important;
}
.nav_options
{
    color: #BAC1CA;
    font-size: 16px;
    font-weight: 500;
    padding-top: 15px;
    display: flex;
    margin-left: 24px;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    font-family: 'AmericaMedium';
}
.appLogo{
    background: url(../images/arc-logo-white.png) no-repeat;
    background-size: contain;
    width: 50.79px;
    height: 32px;
    margin: 12px 0px 12px 24px;
}
.appLogoBlack{
    background: url(../images/arc-logo-black.png) no-repeat;
    background-size: contain;
    width: 50.79px;
    height: 32px;
    position: relative;
    top: -6px;
}
.user.icon{
    background: url(../images/v2/user.svg) no-repeat;
    background-size: contain;
    width: 22px;
    height: 22px;
    margin-left: 32px;
}
.done_green_icon{
    background: url(../images/done_green_icon.svg) no-repeat;
    background-size: contain;
    width: 32px;
    height: 32px;
}
.not_done_icon{
    background: url(../images/notdone_x_icon.svg) no-repeat;
    background-size: contain;
    width: 32px;
    height: 32px;
}
.user.icon:hover
{
    background: url(../images/v2/user_white.svg) no-repeat;
}
.align_lr
{
    display: flex;
    justify-content: space-between;
}
.flex
{
    display: flex;
}
button.solid
{
    background-color: #1775D2;
    color: white;
}
button.outline:hover
{
    background-color: #1775D2;
    color: white;
    -webkit-transition: background-color 0.5s;
    transition: background-color 0.5s;
}
button.outline
{
    border: 1px solid #1775D2;
    background-color: white;
    color: #1775D2;
}
.navbar_info button
{
    outline: none;
    height: 32px;
    font-size: 15px;
    padding: 0px 22px 0px 42px;
    border-radius: 3px;
    font-weight: 600;
    position: relative;
}
.navbar-default
{
    right: 0px;
}
.mt15
{
    margin-top: 15px;
}
.content-wrap
{
    margin-left: 0px;
    left: 17%;
    width: 83%;
    z-index: inherit !important;
    overflow: auto;
}
.app_footer
{
    color: #949eab;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
    padding-bottom: 15px;
}
.sidebar.grey .project_list_options .option:hover, .sidebar.grey .project_list_options .option.active
{
    color: #4a4a4a;
    background-color: #E5E6EA;
    font-weight: 500;
    font-family: 'AmericaMedium';
}
.sidebar.white .project_list_options .option:hover, .sidebar.white .project_list_options .option.active
{
    color: #344049;
    background-color: #F4F5F8;
    font-weight: 500;
    font-family: 'AmericaMedium';
}
.project_list_options .option
{
    padding: 8px 15px 8px 25px;
    cursor: pointer;
    color: #6B7882;
    font-size: 15px;
    list-style-type: none;
}
.project_list_options ul.option
{
    padding: 3px 15px 3px 25px !important;
    margin-bottom: 0px;
}
.project_list_options
{
    padding-top: 24px;
    font-size: 15px !important;
    line-height: 24px;
    overflow-y: auto;
    height: calc(100vh - 150px);
    overflow-x: hidden;
    padding-bottom: 24px;
}
.divider
{
    border-top: 1px solid #E9EBED;
}
.option.icon
{
    background-size: contain;
    width: 18px;
    height: 18px;
}
.project_list_options .option:hover svg, .project_list_options .option.active svg
{
    fill: #344049;
}
.option svg
{
    fill: #68737D;
    width: 18px;
    position: relative;
    height: 18px;
    top: 4px;
}
.svg_hover
{
    cursor: pointer;
}
.svg_hover:hover
{
    fill: grey;
}
.user_options
{
    padding-top: 15px;
    margin-right: 24px;
}
.user_options svg:hover, .user_options svg.active
{
    fill: #FFFFFF;
}
.user_options svg
{
    fill: #BAC1CA;
    margin-left: 32px;
    cursor: pointer;
}

.project_label
{
    display: inline-block;
}
.project_label.gold, .project_label.parksmart-gold
{
    background-color: #D5AE00;
}
.project_label.platinum, .project_label.parksmart-platinum
{
    background-color: #818183;
}
.project_label.silver, .project_label.parksmart-silver
{
    background-color: #83BBC9;
}
.project_label.certified, .project_label.parksmart-pioneer, .project_label.parksmart-bronze
{
    background-color: #A5BA47;
}
.project_label.gold, .project_label.platinum, .project_label.silver, .project_label.certified, .project_label.parksmart-pioneer, .project_label.parksmart-bronze, .project_label.parksmart-silver, .project_label.parksmart-platinum, .project_label.parksmart-gold
{
    border-radius: 3px;
    color: white;
    font-size: 12px;
    font-weight: 600;
    padding: 5px 10px;
    text-transform: capitalize;
}
.dropdown-clean, .dropdown-clean:hover
{
    border: none;
    background-color: white;
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    padding: 0px;
    padding-bottom: 3px;
    font-family: 'AmericaMedium';
}
.align_r
{
    display: flex;
    justify-content: flex-end;
}
.list_controls .fa:hover
{
    color: black;
}
.list_controls .fa
{
    color: #68737D;
    cursor: pointer;
}
.project_list_status
{
    margin-top: 17px;
    font-size: 15px;
    margin-bottom: 15px;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
}

body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

body::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

body::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.15);
}

body::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 135, 139, 0.8);
}
.ellipsis_v2
{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 151px;
}
.navbad_project_reg
{
    bottom: 0px;
    padding: 13px 16px;
    top: initial;
}
.pace {
    display: none;
}
.homeOverview
{
    background-color: #F4F5F8 !important;
}
.homeOverview_parent 
{
    left: 0px !important;
    width: 100%;
}
body
{
    background-color: #F4F5F8;
}
.home_tab
{
    padding: 0px 32px 0px 32px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.26);
    background-color: white;
    display: flex;
    height: 64px;
    margin: 24px 15px 16px 15px;
}
.tab_item
{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-right: 40px;
    color: #68737D;
    height: 64px;
    padding-top: 20px;
    cursor: pointer;
    font-family: 'AmericaMedium';
}
.tab_item.active, .tab_item:hover
{
    color: #28323B;
    border-bottom: 2px solid #28323B;
}
.widget_container iframe, .widget_container > iframe
{
    border: none;
    margin-bottom: 15px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.26);
    background-color: white;
    border-radius: 2px;
}
.widget_container .headings
{
    margin-bottom: 16px;
    width: 85%;
    display: flex;
    margin: 24px auto 16px auto;
    justify-content: space-between;
}
.widget_container .widget_heading
{
    color: #68737D;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    font-family: 'AmericaMedium';
    text-align: left;
}
.widget_container .label
{
    color: #3D474F;
    font-size: 15px;
    line-height: 24px;
    font-weight: normal;
}
.widget
{
    padding-left: 32px;
    padding-top: 0px;
    padding-right: 32px;
}
.project_performance .label, .project_models .label, .city_performance .label
{
    color: #3D474F;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
}
.project_overview .widget_container, .project_performance .widget_container, .project_models .widget_container, .city_performance .widget_container, .climate_risk .widget_container
{
    text-align: initial;
    margin-left: 15px;
}
.project_overview .widget_container .headings, .project_performance .widget_container .headings, .project_models .widget_container .headings, .city_performance .widget_container .headings
{
    width: initial;
}
.project_overview, .project_performance, .totalscore, .energyscore, .waterscore, .wastescore, .transportationscore, .humanexperiencescore, .basescoremr12, .project_models, .portfolio_overviewprojects, .overviewportfolio, .city_performance, .climate_risk, .advanced_scoring, .play_to_zero, .project_report, .efficiency_analysis
{
    background-color: #F4F5F8 !important;
}
.black-color
{
    color: #28323B !important;
}
.projectListWrapper th:hover, .advanced_scoring_overview .unselectable th:hover
{
    text-decoration: underline;
}
#DataTables_Table_1_paginate
{
    margin: 25px 25px;
    padding-right: 32px;
    font-family: "AmericaMedium";
}
.page-title
{
    color: #28323B;
    font-size: 24px;
    font-weight: 500;
    padding-left: 32px;
    padding-top: 5px;
    margin-bottom: 25px;
    font-family: "AmericaMedium";
    margin-top: 20px;
}
.projectSetup
{
    background-color: #F4F5F8 !important;
}
.row
{
    margin-left: 0px;
    margin-right: 0px;
}
.content-wrap {
    position: relative !important;
}
.notifications
{
    width: 100%;
}

.project_list_options a
{
    border-bottom: none !important;
    border-top: none !important;
    padding-left: initial !important;
    color: #6B7881 !important;
    padding: 0px !important;
}
.sidebar-nav > li > a:hover
{
    background-color: inherit !important;
}
.project_list_options .sidebar-nav li ul, .sidebar-nav > li ul > li > a:hover
{
    font-size: 15px;
    background-color: inherit !important;
}
.sidebar-nav
{
    padding-left: 25px;
    list-style: none;
    padding-top: 0px;
}

.project_list_options::-webkit-scrollbar {
    -webkit-appearance: none;
    appearance: none;
    width: 5px;
    height: 10px;
  }
  
  .project_list_options::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0px;
  }
  
  .project_list_options::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.25);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }
  
  .project_list_options::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.15);
  }
  
  .project_list_options::-webkit-scrollbar-thumb:hover {
    background: rgba(128, 135, 139, 0.8);
  }
  .pl45
  {
    padding-left: 45px !important;
  }

.static_info
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    font-size: 15px;
    line-height: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid #D8DCDE;
}
.static_info .info
{
    margin: 0px 0px 12px 0px;
    display: flex;
}
.static_info .info .title
{
    color: #68737D;
    width: 50%;
}
.static_info .info .value::first-letter
{
    text-transform: uppercase;
}
.static_info .info .value
{
    color: #28323B;
    width: 40%;
    text-overflow: ellipsis;
    overflow: hidden;
}
.project_info
{
    padding-top: 16px;
    margin-left: 32px;
    font-size: 15px;
    width: 80%;
}
.project_info h4
{
    font-weight: 500;
    font-family: 'AmericaMedium';
    line-height: 32px;
    color: #28323B;
    margin-bottom: 16px;
    margin-top: 0px;
}
.project_info .form
{
    width:50%;
    padding-top: 7px;
}
.project_info .radio_buttons .head
{
    font-size: 16px;
    line-height: 24px; 
}
span.checkcontainer
{
    font-weight: 500 !important;
}
.project_info .circular-loader
{
    height: 19px;
    width: 3%;
    top: 11px;
    left: -23px;
}
#advanced_scoring_custom_target .circular-loader{
    height: 20px;
    position: relative;
    top: 10px;
    left: 12px;
}
.ml32
{
    margin-left: 32px;
}
.mr32
{
    margin-right: 32px;
}
.manage_teams table th, #billings-page table th, .manage_agreements table th, .init_text_transform, #impacted_by_covid_table th, #admin_pre_pay_table th
{
    text-transform: initial !important;
}
.manage_teams .initials
{
    font-size: 12px;
    padding: 9px;
    float: left;
}
.edit_buttons button:hover
{
    color: #2164B2;
    background-color: white;
}
.edit_buttons button
{
    color: #1775D2;
    font-size: 15px;
    border: none;
    padding: 0px;
}
.permission_request h4, .team_invite h4
{
    margin: 0px;
    font-size: 18px;
    font-weight: 500;
    font-family: 'AmericaMedium';
    color: #28323B;
    margin-bottom: 24px;
}
.permission_request table td
{
    padding: 12px 0px !important;
}
.permission_request
{
    padding: 24px;
    border: 1px solid #D8DCDE;
    border-radius: 2px;
}
.team_invite h4
{
    margin-bottom: 40px;
}
.team_invite
{
    padding: 24px 32px;
    border: 1px solid #D8DCDE;
    border-radius: 2px;
    margin-left: 32px;
}
#invite_team
{
    background-color: #1E88E5;
    border: none;
    font-weight: 500;
    font-family: AmericaMedium;
    font-size: 16px;
    line-height: 24px;
    min-width: 92px;
}
#billings-page table td, .manage_agreements table td
{
    padding: 12px 12px 12px 0px !important;
}
.switchery-small
{
    height: 14px;
    width: 34px;
}
.switchery-small > small
{
    left: 15px;
    top: -3px;
}
.manage_settings
{
    border-top: 1px solid #D8DCDE;
}
.settings_row .desc
{
    color: #68737D;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0px;
}
.settings_row .label
{
    color: #28323B;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: 'AmericaMedium';
    margin-bottom: 2px;
    padding: 0px !important;
}
.settings_row .heading_label
{
    color: #28323B;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    font-family: 'AmericaMedium';
    margin-bottom: 10px;
    padding: 0px !important;
}
.settings_row
{
    padding: 16px 0px;
    border-bottom: 1px solid #D8DCDE;
}
.settings_row .fieldset
{
    display: flex;
    justify-content: space-between;
}
.settings_resources .help
{
    font-size: 16px;
    color: #28323B;
    line-height: 24px;
    margin-bottom: 16px;
}
.settings_resources input
{
    color: #68737D;
    font-size: 14px !important;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: white;
    padding-right: 30px !important;
}
.settings_resources .fa-link
{
    font-size: 17px;
    position: absolute;
    top: 11px;
    right: 10px;
    cursor: pointer;
    color: #68737D;
}
.settings_resources
{
    padding: 0px 0px 0px 24px;
}
.settings_resources h4
{
    margin-top: 0px;
    color: #28323B;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    font-family: 'AmericaMedium';
    margin-bottom: 8px;
}
.link_parent 
{
    position: relative;
}
.settings_resources .link:hover
{
    text-decoration: underline;
}
.td_underline{
    text-decoration: underline !important;
}
.settings_resources .link
{
    color: #1775D2;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}
#apps-page
{
    color: #28323B;
}
.claim_school
{
    padding: 15px 20px;
    background-color: #F4F5F8;
}
.dropdown_v2 a
{
    font-size: 14px;
    padding: 10px 15px !important;
    color: #28323B !important;
}
.filter-menu .checkbox
{
    font-size: 14px;
    margin: 15px 15px 10px 15px !important;
    color: #28323B !important;
}
.dropdown_v2 .dropdown-menu.left
{
    right: -1px;
    left: auto;
    top: -3px;
    border: none;
    border-radius: 0px;
    padding: 0px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.23);
}

.searchDropdown .emptyData
{
    text-align: center;
    font-size: 15px;
    color: #68737D;
}

.search-wrapper {
    position: absolute;
    transform: translate(-55%, -50%);
    top:50%;
    left:50%;
}

.search-wrapper .input-holder {    
    width: 40px;
    height: 40px;
    overflow: hidden;
    background-color: #28323B;
    position: relative;
    transition: all 0.3s ease-in-out;
}
.search-wrapper.active .input-holder {
    width: 900px;
    background: white;
    transition: all .5s cubic-bezier(0.000, 0.105, 0.035, 1.570);
}
.search-wrapper .input-holder .search-input {
    width:100%;
    height: 40px;
    padding: 0px 20px 0 40px;
    opacity: 0;
    position: absolute;
    top:0px;
    left:0px;
    background: transparent;
    box-sizing: border-box;
    border:none !important;
    outline:none !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color:#68737D;
    transform: translate(0, 60px);
    transition: all .3s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    transition-delay: 0.3s;
}
input::-ms-clear{
    display: none;
}
.search-wrapper.active .input-holder .search-input {
    opacity: 1;
    transform: translate(0, 0px);
}
.search-wrapper .input-holder .search-icon {
    width: 40px;
    height: 40px;
    border:none;
    border-radius:6px;
    background-color: #28323B;
    padding:0px;
    outline:none;
    position: relative;
    z-index: 2;
    float:right;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: none !important;
}
.search-wrapper.active .input-holder .search-icon
{
    padding: 0px 0px 0px 12px;
    position: absolute;
}
.search-wrapper.active .input-holder .search-icon {
    width: 40px;
    height: 40px;
    border-radius: 0px;
    background-color: white;
    display: block !important;
}
.search-wrapper .input-holder .search-icon span {
    width:22px;
    height:22px;
    display: inline-block;
    vertical-align: middle;
    position:relative;
    transform: rotate(45deg);
    transition: all .4s cubic-bezier(0.650, -0.600, 0.240, 1.650);
}
.search-wrapper.active .input-holder .search-icon span {
    transform: rotate(-45deg);
}
.search-wrapper .input-holder .search-icon span::before, .search-wrapper .input-holder .search-icon span::after {
    position: absolute; 
    content:'';
}
.search-wrapper .input-holder .search-icon span::before {
    width: 3px;
    height: 9px;
    left: 6px;
    top: 10px;
    border-radius: 2px;
    background: #BAC1CA;
}
.search-wrapper .input-holder .search-icon span::after {
    width: 15px;
    height: 15px;
    left: 0px;
    top: -4px;
    border-radius: 16px;
    border: 3px solid #BAC1CA;
}
.search-wrapper.active .input-holder .search-icon span::before
{
    background: #3D474F;
}
.search-wrapper.active .input-holder .search-icon span::after
{
    border: 3px solid #3D474F;
}
.search-wrapper .close {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transform: rotate(-180deg);
    transition: all .3s cubic-bezier(0.285, -0.450, 0.935, 0.110);
    transition-delay: 0.2s;
    display: none !important;
}
.search-wrapper.active .close
{
    display: block !important;
}
.close
{
    opacity: 1;
}
.search-wrapper.active .close {
    transform: rotate(45deg);
    transition: all .6s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    transition-delay: 0.5s;
}
.search-wrapper .close::before, .search-wrapper .close::after {
    position:absolute;
    content:'';
    background: #3D474F;
    border-radius: 2px;
}
.search-wrapper .close::before {
    width: 3px;
    height: 15px;
    left: 9px;
    top: 4px;
}
.search-wrapper .close::after {
    width: 15px;
    height: 3px;
    left: 3px;
    top: 10px;
}
.ellipsis_0w
{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/*Certification/Review CSS starts*/

.recent-certification {
    color: #28323B;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}

.recent-certification-card {
    padding: 24px 24px;
    box-sizing: border-box;
    width: 332px;
    border: 1px solid #D8DCDE;
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.12);
}

.certification_number {
    color: #808285;
    font-size: 36px;
    font-weight: 500;
    line-height: 40px;
}

#logo_leed{
    background-image: url(../images/logo/leed.png);
    background-repeat: no-repeat;
    background-size: 160px;
    height: 108px;
    width: 108px;
    background-position: -37px 0px;
    padding-left: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.certification-number-container{
    display: flex;
    align-items: center;
}

.certification_number_text{
    height: 52px;
    width: 268px;
    color: #3D474F;
    font-size: 15px;
    line-height: 24px;
}

.anchor_blue_color {
    color: #397DCC;
}
.hover_underline:hover
{
    text-decoration: underline;
}

.cert_list{
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 80px;
    border: 1px solid #D8DCDE;
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.12);
}

.cert_list_rs{
    color: #28323B;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}

.vertical_center_flex{
    display: flex;
    align-items: center;
}

.cert_list_image{
    width: 100px;
    margin: -34px;
    margin-right: -10px;
}

.review_list_image{
    width: 100px;
    margin-left: -34px;
    margin-right: -10px;
}


.cert_expiration{
    display: grid;
}

.diplay_grid{
    display: grid;
}

.review_container {
    overflow: auto;
    border: 1px solid #D8DCDE;
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.12);
    padding: 24px;
    margin-bottom: 30px;
}

.review_button {
    min-height: 40px;
    border-radius: 2px;
    background-color: #1E88E5;
    border: none;
}

.white_color{
    color: #FFFFFF;
}

.divider_bottom{
    border-bottom: 1px solid #E9EBED;
}

.divider_right{
    border-right: 1px solid #E9EBED;
}

.cert_score_item{
    padding: 4px 0px;
    display: flex;
    justify-content: space-between;
}

.cert_score_category{
    font-size: 16px;
    line-height: 24px;
    color: #28323B;
}

.cert_unit{
    color: #949EAB;
}

.cert_reading {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.recert_guidance{
    color: #1775D2;
}

.cert_review_label {
    color: #28323B;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
}

.cert_selection_container_field {
    padding: 10px 10px 8px 0px;
    min-width: 284px;
    border: none;
    border-bottom: 2px solid #CACFD5;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    color: #28323B;
}

.cert_selection_container .border_radius_zero {
    -webkit-border-radius: 0px; 
    -moz-border-radius: 0px; 
    border-radius: 0px; 
}

.cert_selection_container .border, .review_success .border, .certifications_parent .border {
    border: 1px solid #D8DCDE;
}

.cert_selection_container .light_gray, .review_payment .light_gray, .review_success .light_gray, .light_gray {
    color: #68737D;
}

.cert_selection_container #end_review_date{
    padding: 10px 10px 8px 0px;
    min-width: 0px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    color: #28323B;
}

.cert_calendar{
    width: 20px;
}

.border_bottom_gray{
    border-bottom: 2px solid #CACFD5;
}

.cert_selection_container .color_blue{
    color: #1465C0;
}

.cert_upload{
    color: #68737D;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    height: 32px;
    width: 104px;
    border: 1px solid #BAC1CA;
    border-radius: 3px;
}

.cert_selection_container .btn-primary, .review_payment .btn-primary, .review_success .btn-primary {
    background-color: #1E88E5;
    border-color: #1E88E5;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    min-width: 120px;
}

.cert_selection_container .btn-default, .review_payment .btn-defaults, .review_success .btn-defaults {
    background-color: #FFFFFF;
    border: 1px solid #BAC1CA;
    color: #68737D;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    min-width: 100px;
}

.opacity_0_5{
    opacity: 0.5 !important;
}

.review_payment{
    background-color: #F4F5F8 !important;
}


.review_payment_main{
    margin: 0px 125px !important;
}

.review_payment .payment-card{
    padding: 25px 30px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.26) !important;
}

.border_bottom_gray_1{
    border-bottom: 1px solid #E9EBED;
}

.extend_cert_list {
  box-shadow: none !important;
  border-bottom: none !important;
  margin-bottom: 0px;
}

.border_lrb{
  border-left: 1px solid #D8DCDE;
  border-top: none;
  border-right: 1px solid #D8DCDE;
  border-bottom: 1px solid #D8DCDE;
}

.border_bottom_shadow{
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.12);
}

.review_not_allowed_modal .btn-default
{
    background-color: white;
}
.review_not_allowed_modal .btn-md
{
    font-weight: 600;
    padding: 10px 25px;
    border-radius: 3px;
}
.datacoverage_item{
    float: right;
    padding-right: 45px;
}
.w200px{
    width: 200px;
}
.w280px{
    width: 280px;
}

/*Certification/Review CSS ends*/

.light-theme.tippy-tooltip {
    color: black;
    padding: 15px;
    text-align: left;
    padding: 10px;
    background-color: white;
  }
  .light-theme .tippy-backdrop {
    background-color: white;
    color: black !important;
  }
  
  .light-theme .fa-circle
  {
    color: lightgrey;
    font-size: 6px;
    margin-bottom: 3px;
    margin-right: 5px;
  }
  .light-theme {
    font-size: 13px;
    box-shadow: rgba(0, 0, 0, 0.23) 0px 3px 21px 5px;
  }
  .tippy-content br {
    line-height: 25px;
  }
  .tippy-content {
    font-size: 13px;
  }
  
  .light-theme .error_list {
    color: black !important;
  }
  
.portfolio_settings
{
    margin-left: 32px;
    border: 1px solid #D8DCDE;
    padding: 16px 24px;
    overflow: auto;
    margin-right: 32px;
}
.btn-primary
{
    background-color: #1E88E5;
    border-radius: 2px;
}
.portfolio_settings .editPort, .portfolio_settings .savePort
{
    background-color: #1E88E5;
    color: white;
    position: relative;
    top: -4px;
}
.portfolio_settings .savePort
{
    position: relative;
    top: -4px;
}
.portfolio_settings .editPort, .portfolio_settings .savePort
{
    border-radius: 3px;
    width: 73px;
    height: 32px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    font-family: AmericaMedium;
    text-align: center;
    border: none;
}
.portfolio_settings section
{
    display: flex;
    justify-content: space-between;
}
.portfolio_settings h4
{
    margin-top: 0px;
    margin-bottom: 16px;
    font-weight: 500;
    font-family: AmericaMedium;
    line-height: 24px;
}
.port_table.manage .port_label, .small_label
{
    color: #68737D;
    font-size: 14px;
    line-height: 24px;
}
.port_table.manage tr
{
    border-bottom: 12px solid white;
}
.ui-version-banner{
    background: #1b1e20;
    color: #BAC1CA;
    border: none;
    text-align: center;
    font-size: 14px;
    padding: 11px 10px;
    top: 0px;
    font-weight: 400;
    height: 40px;
    position: fixed;
    z-index: 2;
    width: 100%;
}
.ui-version-banner u{
    text-decoration: none !important;
}
.can_switch_ui .navbar_info {
    top: 96px;
}
.admin_tool_content{
    position: relative;
    padding: 50px 40px 40px !important;
    background-color: #eee !important;
    top: 6px !important;
    min-height: 100%;
}
.admin_tool_content .widget{
    position: relative;
    margin-bottom: 30px;
    padding: 15px 20px;
    background: #fff;
    border-radius: 3px;
}
.plus_icon svg
{
    overflow: visible;
    width: 20px;
    height: 20px;
}
.plus_icon
{
    left: 9px;
    top: 5px;
    position: absolute;
    fill: #1775D2;
}
button.outline:hover .plus_icon
{
    fill: white;
}
.last_12_month
{
    border: 1px solid #D8DCDE;
    margin-right: 25px;
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.12);
    width: 122px;
    font-size: 14px !important;
    color: #68737D !important;
    padding: 5px 10px;
    background-color: white;
    margin-left: 8px;
}

/*Leadership CSS starts*/

.leadership_nav{
    padding-bottom: 3px;
    margin-bottom: 0px;
    padding-top: 3px;
}
.leadership_heading{
    font-size: 28px;
    font-weight: bold;
    line-height: 40px;
    padding-left: 40px;
    padding-right: 40px;
}
.leadership_subheading{
    font-size: 17px;
    line-height: 26px;
    padding-top: 8px;
    padding-left: 80px;
    padding-right: 80px;
}
.leadership_cart{
    padding: 32px 0px;
    background-color: #FAFBFC;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.26);
}
.leadership_links{
    font-size: 16px;
    line-height: 24px;
    color: #1775D2;
}

/*Leadership CSS ends*/
.nav_options_spinner
{
    position: relative;
}
.nav_options_spinner circle
{
    stroke: white !important;
    stroke-width: 10px !important;
}
.nav_options_spinner .materialSpinner-svg
{
    width: 15px !important;
    height: 15px !important;
}
.nav_options_spinner .materialSpinner--wrapper
{
    position: absolute !important;
    left: -20px !important;
    top: inherit !important;
}

#cert_score_bar, #cert_score_bar_1
{
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.26);
}
.label_frame
{
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 32px;
}

#certificate_list
{
    overflow: auto;
    width: 100%;
}
#certificate_list .certificate
{
    border: 1px solid #D8DCDE;
    padding: 18px 24px;
    margin-bottom: 24px;
}
#certificate_list .header
{
    padding-bottom: 18px;
    margin-bottom: 18px;
    border-bottom: 1px solid #D8DCDE;
}
#certificate_list p
{
    font-family: AmericaMedium;
    font-weight: 500;
}
#certificate_list h4
{
    margin: 0px;
    font-weight: 500;
    position: relative;
    top: 5px;
    font-family: AmericaMedium;
}
#certificate_list .category_icon
{
    background-position: 8px 6px;
    background-size: 35%;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 10px;
    position: relative;
    top: -2px;
}
.energy_white_icon
{
    background: url(../images/icons/energy_white.png) no-repeat;
    background-color: #D0DD3D;
}
.water_white_icon
{
    background: url(../images/icons/walter_white.png) no-repeat;
    background-color: #51C2F7;
    background-position: 8px 4px !important;
    background-size: 32% !important;
}
.waste_white_icon
{
    background: url(../images/icons/waste_white.png) no-repeat;
    background-color: #84CCB0;
    background-position: 6px 6px !important;
    background-size: 50% !important;
}
.transportation_white_icon
{
    background: url(../images/icons/transport_white.png) no-repeat;
    background-color: #A39F92;
    background-position: 6px 7px !important;
    background-size: 50% !important;
}
.human_experience_white_icon
{
    background: url(../images/icons/human_white.png) no-repeat;
    background-color: #F2AC41;
    background-position: 8px 5px !important;
    background-size: 28% !important;
}
.purchase_certificate.top46
{
    top: 0px !important;
}
.certificate_pay .water_white_icon
{
    background-position: 11px 6px !important;
}
.certificate_pay .waste_white_icon
{
    background-position: 8px 8px !important;
}
.certificate_pay .transportation_white_icon
{
    background-position: 8px 8px !important;
}
.certificate_pay .human_experience_white_icon
{
    background-position: 11px 5px !important;
}
.certificate_pay .category_icon
{
    background-position: 11px 8px;
    background-size: 36%;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    margin-right: 10px;
    position: relative;
    top: -2px;
}
.certificate_reporting_period .reset
{
    color: #1465C0;
    font-size: 14px;
    line-height: 24px;
}
.certificate_reporting_period
{
    padding: 32px 32px;
    border: 1px solid #D8DCDE;
    width: 70%;
}
.requirements .category
{
    color: #252C32;
    font-size: 12px;
    font-family: AmericaMedium;
    font-weight: 500;
    line-height: 25px;
    padding-top: 5px;
    margin-bottom: 5px;
    border-top: 1px solid #D8DCDE;
}
.requirements .maxima
{
    color: #949EAB;
}
.requirements .status
{
    position: relative;
    top: -1px;
}
.requirements .reading
{
    display: flex;
}
.requirements .value
{
    font-size: 18px;
    font-family: AmericaMedium;
    font-weight: 500;
    line-height: 24px;
    margin-right: 5px;
}
.data_metric {
    width: 25%;
}
.mb35
{
    margin-bottom: 35px;
}
.btn-primary.inverse
{
    border: 1px solid #1775D2;
    color: #1775D2;
    background-color: white;
}
.btn-primary.inverse:hover
{
    background-color: #1775D2;
    color: white;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 26%);
}
.frame_wrapper iframe
{
    padding: 0px 10px;
    border: none;
}
.certificate_modal button
{
    padding: 9px 17px;
    font-size: 15px;
}
#past_certificates th
{
    text-transform: initial;
    font-size: 14px;
    padding-top: 0px;
}
#past_certificates td
{
    padding: 15px 10px;
}
.create_cert
{
    position: absolute;
    right: 0px;
}
.data_metric .fa-paperclip
{
    font-size: 17px;
    transform: rotate(0deg);
    color: darkgray;
    position: relative;
    top: 3px;
}
.cert_status
{
    float: right;
    line-height: 22px;
    padding: 7px 0px;
}
.test_project
{
    margin-top: -15px;
    margin-bottom: 20px;
}
.internet-connection
{
    background: #1b1e20;
    color: #D84315;
    border: none;
    text-align: center;
    font-size: 14px;
    padding: 11px 10px;
    top: 0px;
    font-weight: 400;
    height: 40px;
    position: fixed;
    z-index: 2;
    width: 100%;
    display: none;
}
.user-dropdown li:hover
{
    background-color: #eee;
}
.user-dropdown
{
    width: 220px;
}
.user-dropdown li
{
    padding: 10px 16px;
    line-height: 24px;
    cursor: pointer;
}
.border-bottom
{
    border-bottom: 1px solid #D8DCDE !important;
}
.border-top
{
    border-top: 1px solid #D8DCDE;
}
.arc_logo_racetrack
{
    background-image: url(../images/arc_black.svg);
    height: 24px;
    width: 24px;
    background-size: 16px;
    background-position: 4.5px 4px;
    float: left;
    background-color: black;
    border-radius: 12px;
    margin-right: 8px;
    background-repeat: no-repeat;
    position: relative;
    top: -1px;
}
.arc_logo_racetrack_big
{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-size: 25px;
    background-position: 8px 8px;
}
.arc_pro_button a
{
    color: #1775D2 !important;
}
.arc_pro_button
{
    padding: 0px 12px !important;
    background-color: white;
}
.pr7
{
    padding-right: 7px;
}
.rotate50
{
    transform: rotate(50deg);
}
.account_image_wrapper
{
    border: 1px solid #D8DCDE;
    padding: 5px;
    display: inline-block;
    height: 162px;
    width: 165px;
}
.account_image
{
    height: 150px;
    width: 150px;
    text-align: center;
    background-color: #BAC1CA;
    padding-top: 35px;
    display: inline-block;
}
.account_image svg
{
    width: 80px;
    height: 80px;
}
.inline-100
{
    display: inline-block;
    width: 100%;
}
.lh40
{
    line-height: 40px;
}
.account_data
{
    margin-left: 24px;
    width: 100%;
}
.account_info
{
    display: flex;
}
.data_row
{
    margin-bottom: 12px;
    display: flex;
}
.data_value
{
    width: 60%;
}
.data_value.no_value
{
    color: #68737D;
}
.data_label
{
    color: #68737D;
    width: 40%;
}
.data_label, .data_value
{
    font-size: 16px;
    line-height: 24px;
    display: inline;
}
.arc_pro .widget
{
    border-right: 1px solid #DDDDDD;
}
.account_upgrade_info .header_image.upgrade
{
    background: url(../images/Arc_Premium_Dark_375_92px@2x.png) no-repeat;
    height: 92px;
    background-size: cover;
}
.account_upgrade_info .header_image
{
    /* height: 150px; */
    background-color: #F4F5F8;
}
.account_upgrade_info
{
    width: 28%;
    /* margin-top: 24px; */
    background-color: transparent;
}
.account_upgrade_data
{
    padding: 16px 24px;
    border-right: 1px solid #D8DCDE;
    border-left: 1px solid #D8DCDE;
    background-color: white;
    border-bottom: 1px solid #D8DCDE;
}
.climate_risk_widget .account_upgrade_data
{
    border: none !important;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 26%);
}
.advancedScoringWidgets--wrapper
{
    border: none !important;
    box-shadow: 0 0px 1px 0 rgb(0 0 0 / 26%);
}
.learn_more_link:hover
{
    text-decoration: underline;
}
.learn_more_link
{
    margin-top: 24px;
    color: #1775D2;
    text-align: right;
    cursor: pointer;
    font-family: 'AmericaMedium';
}
.my_reports
{
    margin-top: 24px;
    padding: 29px 32px;
    border-top: 1px solid #D8DCDE;
}
.darkgray
{
    color: darkgray;
}
.subs_billing
{
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 24px;
    width: 100%;
    float: left;
}
.subs_billing .b_item
{
    float: left;
    width: 33%;
}
.pt24
{
    padding-top: 24px;
}
.fit-content
{
    height: fit-content !important;
}
.flex-end
{
    justify-content: flex-end;
}
.internet-connection, .maintenance-banner
{
    background: #1b1e20;
    color: #D84315;
    border: none;
    text-align: center;
    font-size: 14px;
    padding: 11px 10px;
    top: 0px;
    font-weight: 400;
    height: 40px;
    position: fixed;
    z-index: 2;
    width: 100%;
    display: none;
}
#inactive_certificate ul
{
    padding-left: 24px;
}
#inactive_certificate li
{
    margin-bottom: 5px;
}
#inactive_certificate
{
    float: left;
    padding: 24px;
    border: 1px solid #D8DCDE;
    width: 51%;
}
.financial_models #inactive_certificate
{
    width: 60% !important;
}
.more_done_icon
{
    background-image: url(../images/more_done_icon.svg);
    width: 24px;
    height: 24px;
    fill: #50AE54;
}
.mb24
{
    margin-bottom: 24px;
}
#cert_subscription_details
{
    border: 1px solid #D8DCDE;
    padding: 24px;
    width: 34%;
}

/*Sliders and tables starts*/
.slider_upgrade_btn{
    height: 32px;
    font-size: 15px;
    border-radius: 3px;
    font-weight: 600;
    position: relative;
}
#energy-slider-widget, #water-slider-widget, #waste-slider-widget, #transportation-slider-widget, #human-experience-slider-widget, #energy-table-widget, #water-table-widget, #waste-table-widget, #transportation-table-widget, #human-experience-table-widget{
    box-shadow: none !important;
}
.model_banner_img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    height: 160px;
    background-image: url(../images/Models_Banner_Dark_570px@2x.png);
    background-color: #28323B;
}
.cert_banner_img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 160px;
    background-image: url(../images/Certificates_Banner_Blue_570px@2x.png);
    float: left;
    width: 51%;
}

.models_divider {
    height: 1px;
    background-color: #D8DCDE;
}
.arc_models_labels{
    background: url(../images/report/Arc_Banner_Light_375px@2x.svg) no-repeat;
    background-color: #F4F5F8;
    height: 100%;
    background-position: center;
}
.models_how_arc_works{
    background-color: #F4F5F8 !important;
    height: 160px;
}
.models_border{
    border: 1px solid #D8DCDE;
}
.model_vertical_divider{
    width: 1px;
    transform: scaleX(-1);
    background-color: #D8DCDE;
    height: 1164px;
    position: fixed;
    right: 28%;
    top: 0;
}
/*SLiders and tables ends*/
.title_images
{
    cursor: pointer;
    width: 80px;
    height: 80px;
    border: 1px solid #BAC1CA;
    margin-right: 16px;
}
.title_images .placeholder svg
{
    fill: #BAC1CA;
}
.title_images .placeholder
{
    width: 100%;
    height: 100%;
    padding: 26px;
}
.icon_wrapper img
{
    vertical-align: middle;
    width: 80px;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.icon_wrapper
{
    white-space: nowrap;
    text-align: center;
    width: 78px;
    height: 78px;
    position: relative;
}
#report_preview .white_bg
{
    border-left: 1px solid #BAC1CA;
    border-bottom: 1px solid #BAC1CA;
    border-top: 1px solid #BAC1CA;
    border-right: 1px solid #BAC1CA;
}
#report_preview .banner
{
    background-color: rgb(19, 66, 114);
    height: 80px;
    /* padding-top: 10px; */
}
#report_preview
{
    width: 200px;
    height: 262px;
}
.modal .semi-md
{
    width: 630px;
    background-color: white;
}
.title_images:hover .remote_title
{
    display: block;
}
.remote_title:hover
{
    background-color: #6B7882;
    border-radius: 23px;
}
.remote_title
{
    position: absolute;
    top: 0px;
    left: 0px;
    display: none;
    z-index: 999;
}
#select_title .title_images:hover img, #select_title .title_images.active img
{
    max-height: 73px;
    max-width: 73px;
}
#select_title .title_images:hover, #select_title .title_images.active
{
    border: 4px solid #1E88E5;
}
#select_title .title_images .check_title
{
    position: absolute;
    right: 0px;
    top: 0px;
    display: none;
}
#select_title .title_images.active .check_title
{
    display: block;
}
.report_content
{
    padding: 16px 28px;
    border-left: 1px solid #BAC1CA;
    border-bottom: 1px solid #BAC1CA;
    border-right: 1px solid #BAC1CA;
    height: 162px;
}
.report_content .fat
{
    height: 9.3px;
    background-color: #D8D8D8;
}
.report_content .thin
{
    height: 4.65px;
    background-color: #D8D8D8;
}
#report_preview .banner .title_images
{
    border: none;
}
#reports_table th, #advanced_scoring_container th
{
    text-transform: initial;
}
.report_images img
{
    height: 56px; 
}
.arc_logo_white-sm
{
    height: 56px;
    width: 56px;
    background-size: 40px;
    background-color: rgb(19, 66, 114);
}
#arc_premium_dark .btn-default
{
    border: 1px solid #FFFFFF;
    background-color: transparent;
    color: white;
}
#arc_premium_dark .banner_content
{
    padding: 25px;
    width: 50%;
    display: flex;
}
#arc_premium_dark .banner_image
{
    background-image: url(../images/Arc_Premium_Dark_375_92px@2x.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    width: 50%;
    height: 120px;
}
#arc_premium_dark
{
    height: fit-content;
    width: 85%;
    margin: 0 auto;
    margin-bottom: 15px;
    color: white;
    background-color: #28323B;
    /* padding: 32px; */
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.26);
}
.loader-sm
{
    left: 25px !important;
    width: 25px;
    height: 25px;
    top: 22px !important;
}
.report_banner_img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 160px;
    background-image: url(../images/Reports_Banner_570px@2x.svg);
}
.arc_pro_purchase .checkbox label:before {
    margin-top: 3px;
}
.arc_pro_purchase #details-form .checkbox label::after {
    top: -1px;
}
.delete_report
{
    width: 20px;
    height: 24px;
    float: left;
    background: url(https://static-app.arconline.io/data-input-widget-assets-nonprd/svg/trash_icon.svg) no-repeat;
    background-size: cover;
    cursor: pointer;
}
.arc_pro_learn_more_parent #content
{
    padding: 19px 0px 0px 0px !important;
    top: 46px;
}
.arc_pro_learn_more_parent
{
    width: 100%;
}
.top_header
{
    /* padding-left: 128px; */
    /* padding-top: 82px; */
    height: 520px;
    background-color: #FAFAFA;
    display: flex;
}
.pl26
{
    padding-left: 26px;
}
.block
{
    display: block !important;
}
.mr12
{
    margin-right: 12px;
}
.pro_banner_img_lm
{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../images/arc_pro_learn_more/Arc_Premium_Banner_img@2x.png);
    height: 608px;
    /* width: 791px; */
}
.cert_banner_img_lm
{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../images/arc_pro_learn_more/Arc_Performance_Cert_Banner_img@2x.jpg);
    height: 530px;
    /* width: 791px; */
}
.info1_img
{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../images/arc_pro_learn_more/Arc_Performance_Cert_Banner_img@2x.jpg);
    height: 440px;
    width: 520px;
}
.info3_img
{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../images/arc_pro_learn_more/info3_img.png);
    height: 440px;
    width: 520px;
}
.info2_img
{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../images/arc_pro_learn_more/info2_img.png);
    height: 440px;
    width: 520px;
}
.info4_img
{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../images/arc_pro_learn_more/info4_img.png);
    height: 440px;
    width: 520px;
}
.info5_img
{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../images/arc_pro_learn_more/info5_img.png);
    height: 440px;
    width: 520px;
}
.pt192
{
    padding-top: 192px;
}
.learn_more_fetures .feature
{
    margin-right: 40px;
}
.learn_more_fetures
{
    padding-left: 128px;
    display: flex;
}
.feature_heading
{
    font-size: 24px;
    line-height: 28px;
    margin-top: 23px;
    margin-bottom: 16px;
    font-weight: bold;
    font-family: 'AmericaMedium';
}
.feature_desc
{
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 92px;
}
.feature_logo
{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.feature_logo.Insights_Icon
{
    background-image: url(../images/arc_pro_learn_more/Insights_Icon.svg);
    height: 44px;
    width: 48px;
}
.feature_logo.Communication_Icon
{
    background-image: url(../images/arc_pro_learn_more/Communication_Icon.svg);
    height: 48px;
    width: 38px;
}
.feature_logo.communicate
{
    background-image: url(../images/arc_pro_learn_more/communicate_icon.svg);
    height: 44px;
    width: 48px;
}
.feature_logo.arc_any
{
    background-image: url(../images/arc_pro_learn_more/arc_any.svg);
    height: 48px;
    width: 48px;
}
.feature_logo.perf
{
    background-image: url(../images/arc_pro_learn_more/perf.svg);
    height: 48px;
    width: 48px;
}
.cta_clone .cta li
{
    margin-bottom: 13px;
}
.cta_clone .cta ul
{
    padding-left: 17px;
}
.cta_clone .cta button
{
    height: 48px;
    width: 308px;
}
.cta_clone .cta
{
    border: 1px solid #DDDDDD;
    background-color: #FFFFFF;
    padding: 24.5px 32.5px;
    width: 571px;
    margin: 0 auto;
    margin-bottom: 30px;
}
.cta_clone
{
    background-color: #FAFAFA;
    padding: 32px 0px
}
.info1
{
    padding: 80px 128px;
}
.vertical_center .vc_anchor
{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.vertical_center
{
    position: relative;
}
.pl120
{
    padding-left: 120px;
}
.lm_footer
{
    background-color: #FAFAFA;
    padding: 80px 0px;
    text-align: center;
}
.margin_init
{
    margin: 0px 25px 0px 0px !important;
}
.w-min
{
    width: min-content !important;
}
.factor_score .modal-content
{
    width: 150% !important;
}
.factor_score .modal-dialog
{
    right: 17% !important;
}
#event_widget .account_upgrade_data
{
    height: 395px;
}
#event_widget .account_upgrade_info
{
    width: 100%;
    height: 395px;
}
#event_widget
{
    float: left;
    width: 31.5%;
    height: 395px;
}
.survey-label
{
    color: #68737D;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 7px;
}
.survey-form-heading
{
    font-size: 20px;
    font-family: 'AmericaMedium';
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 32px;
}
.survey-question.survey-slider, .survey-question.radio-button, .survey-question.radio-button-group
{
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 24px;
    margin-bottom: 24px;
}
.survey-question .slider_val
{
    font-size: 14px;
    line-height: 24px;
    margin-right: 10px;
    font-family: 'AmericaMedium';
    font-weight: 500;
}
.survey-question .question
{
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
    font-family: 'AmericaMedium';
    font-weight: 500;
}
.survey-question .slider .rangeslider__fill, .survey-question .slider .rangeslider__handle
{
    background-color: #1A76D1;
}
.survey-question .slider .rangeslider--horizontal, .survey-question .slider.blank .rangeslider__fill
{
    background-color: #E4E7EA !important;
}
.slider_heading
{
    font-size: 16px;
    color: #1A76D1;
    line-height: 24px;
    margin-bottom: 17px;
    font-family: 'AmericaMedium';
    font-weight: 500;
    width: 68%;
    text-align: center;
}
.free-response textarea
{
    border: 1px solid #DDDDDD;
    width: 423px;
    height: 107px;
    margin-bottom: 4px;
    resize: none;
}
.free-response
{
    margin-top: 31px;
}
.free-response-label
{
    color: #28323B;
    line-height: 24px;
    font-size: 16px;
    margin-bottom: 15px;
    width: 423px;
}
.max_info
{
    font-size: 12px;
    color: #28323B;
    line-height: 24px;
}
.survey-question .radio_buttons
{
    font-weight: normal !important;
}
.survey-question .btn
{
    background-color: white;
}
.survey-link .fa-copy
{
    color: #68737D;
    position: relative;
    top: 3px;
    right: 15px;
    cursor: pointer;
}
.minimal_input
{
	text-overflow: ellipsis;
}
.survey-link input, .minimal_input
{
    border: none;
    width: 100%;
    border-bottom: 1px solid #CACFD5;
    padding: 0px 0px 7.5px 0px;
    color: #68737D;
    line-height: 24px;
}
.radio-button-group .radio-label
{
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
}
.error_underline
{
    border-bottom: 1px solid #EF4C00 !important;
}
#re_entry
{
    margin-bottom: 30px;
    padding: 24px;
    border: 1px solid #D8DCDE;
}
.re_entry_reading, .re_entry_unit
{
    font-size: 24px;
    font-weight: 500;
    font-family: 'AmericaMedium';
    line-height: 30px;
}
.re_entry_unit
{
    color: #949EAB;
}
.pl24
{
    padding-left: 24px;
}
.old_divider
{
	width: 100%;
    float: left;
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 24px;
    margin-bottom: 24px;
}
.copy_notif
{
  color: #52ae54;
  font-size: 12px;
  padding-top: 5px;
  display: none;
}
.tenant_inp
{
	padding: 9px 12px;
    border: 2px solid #EEE;
}
.icon_deleteDoc {
    width: 25px;
    height: 27px;
    margin-left: 5px;
    background: url(https://static-app.arconline.io/data-input-widget-assets-nonprd/svg/delete_icon.svg) no-repeat;
    background-size: cover;
    cursor: pointer;
}
.bottom-abs
{
    position: absolute;
    bottom: 0px;
}
.h180px
{
    height: 180px;
}
.h200px
{
    height: 200px;
}
.h250px
{
    height: 250px;
}
.h220px
{
    height: 220px;
}
.basic_practices
{
    background: url('../images/leed-logo-registered.png') no-repeat left;
    background-size: 60px;
    height: 62px;
    padding-left: 75px;
    padding-top: 9px;
}
.basic_practices_well{
    background: url('../images/cert-nolvl-solid.png') no-repeat left;
    background-size: 60px;
    height: 62px;
    padding-left: 75px;
    padding-top: 9px;
}
.re-entry-fm-box{
    padding: 1px 11px;
}
.re-entry-fm-box-header{
    height: 50px;
    padding-left: 50px;
}
.re-entry-fm-box a{
    text-decoration: underline !important;
    color: #4A90E2 !important;
}
.leed-logo-registered{
    background: url(../images/leed-logo-registered.png) no-repeat left;
    background-size: 40px;
    padding-top: 13px;
}
.cert-nolvl-solid{
    background: url(../images/cert-nolvl-solid.png) no-repeat left;
    background-size: 40px;
    padding-top: 13px;
}
.no-margin-15{
    margin-left: -15px;
    margin-right: -15px;
}
#arc_premium_dark .arc_logo_racetrack
{
    width: 70px;
    height: 70px;
    background-size: 40px;
    border-radius: 35px;
    background-position: 16px 16px;
    margin-top: 25px;
}
.arc_premium_light .banner_content
{
    width: 80% !important;
}
.arc_premium_light .btn-default
{
    color: #4a90e2 !important;
}
.gsa_panel_parent #content
{
    padding: 56px 0px 0px 0px;
}
.widget_mimic
{
    padding: 24px 32px;
    border: none;
    margin-bottom: 15px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.26);
    background-color: white;
}
.re_entry_cta
{
    background: url('../images/re_entry_cta.png') no-repeat left;
    background-size: cover;
    height: 120px;
    width: 42%;
}
.referer_bar #ui-version-banner
{
    top: 40px !important;
}
.referer_bar .navbar_info
{
    top: 96px !important;
}
.referer_bar .can_switch_ui .navbar_info
{
    top: 136px !important;
}
.referer_bar #nav_parent
{
    top: 80px !important;
}
.referer_bar #sidebar
{
    top: 161px !important;
}
.referer_bar #content
{
    top: 161px !important;
}
#LO_referer svg
{
    fill: #BAC1CA;
}
#LO_referer span:hover, #LO_referer svg:hover
{
    color: white;
    fill: white;
}
.popup_notification
{
    background-color: #1E88E5;
    width: 8px;
    height: 8px;
    position: absolute;
    right: 3px;
    top: 1px;
    border-radius: 4px;
}
.btn-border
{
    border: 1px solid #BAC1CA;
}
.gray_button:hover
{
    color: #344049;
    background-color: #F4F5F8;
}
.gray_button
{
    background-color: white;
    color: #6B7882;
    border-radius: 0px;
    position: relative;
}
.gray_button svg
{
    fill:  #6B7882;
}
.checkbox input[type=checkbox]:focus+label::before, .checkbox input[type=radio]:focus+label::before
{
    outline: none;
}

.blue_check input
{
    top: 6px;
    cursor: pointer;
    width: 18px;
    height: 18px;
    opacity: 0;
    z-index: 1;
}
.blue_check.checkbox label:before
{
    border: 2px solid #68737D;
    width: 18px;
    height: 18px;
}
.user-list .blue_check.checkbox label:before
{
    width: 16px;
    height: 16px;
}
.blue_check.ck_gray_border.checkbox label:before
{
    border: 2px solid #BAC1CA;
}
.blue_check.checkbox input:checked + label::after
{
    color: transparent;
    background-image: url(../images/check.svg);
    background-size: 19px;
}
.blue_check.blue_cross.checkbox input:checked + label::after
{
    color: transparent;
    background-image: url(../images/close_icon_white.svg);
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: 1.5px 2px;
}
.user-list .blue_check.checkbox input:checked + label::after
{
    background-size: 17px;
}
.blue_check.checkbox input:checked + label::before
{
    background-color: #1775D2;
    border-color: #1775D2;
}
.blue_check.blue_cross.checkbox input:checked + label::before
{
    background-color: #dd5826 !important;
    border-color: #dd5826 !important;
}
.mt-4
{
    margin-top: -4px;
}
#project_filters .state-list, #project_filters .spaceType-list, #project_filters .cert_name_filter-list, #portfolio_filters .name-list, #portfolio_filters .building_status-list
{
    top: 0px;
}
#project_filters .user-list, #portfolio_filters .user-list
{
    width: 269px;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.11);
    border: none;
    max-height: 224px;
    height: fit-content;
}
#project_filters svg, #portfolio_filters svg
{
    fill: #BAC1CA;
    height: 17px;
    width: 17px;
}
#project_filters .dropdown_v2 svg, #portfolio_filters .dropdown_v2 svg
{
    height: 17px;
    width: 17px;
    position: relative;
    left: -3px;
    top: 3px;
}
#project_filters .arc-pro-search, #portfolio_filters .arc-pro-search
{
    height: 34px;
    font-size: 15px;
    width: 269px;
    border-radius: 0px;
    margin-bottom: 0px;
}
#project_filters .dropdown, #portfolio_filters .dropdown
{
    width: 156px;
    height: 34px;
    font-size: 15px;
    border: 1px solid #BAC2CD;
    line-height: 16px;
    padding: 8px 12px;
}
#project_filters, #portfolio_filters
{
    position: absolute;
    top: 0px;
    width: 640px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,0.14);
    z-index: 1;
    padding: 12px 24px;
}
#AS_program_filters{
    position: absolute;
    top: 0px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 7px 0 rgb(0 0 0 / 14%);
    z-index: 1;
    padding: 12px 24px;
    left: 0;
}
#add_to_portfolio #portfolio_filters
{
    right: 0px;
    top: 15px !important;
}
#portfolio_filters .user-list
{
    position: inherit;
    border: none;
    width: 100%;
    box-shadow: none;
}
#portfolio_filters
{
    width: 400px;
}
.project_filter_item
{
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 16px;
    padding-top: 16px;
    display: flex;
}
.project_filter_header
{
    border-bottom: 1px solid #EDEDED;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    line-height: 24px;
    padding-bottom: 12px;
}
.select_check_mark
{
    background-image: url(../images/check.svg);
    background-size: 19px;
    height: 19px;
    width: 19px;
}
.state_name_filter, .space_type_name_filter, .cert_name_filter, .building_status_filter, .country_name_filter, .tags_filter
{
    width: 200px;
    height: 34px;
    font-size: 15px;
    border: 1px solid #BAC2CD;
    line-height: 16px;
    padding: 8px 12px;
    cursor: pointer;
}
.filter_stat
{
    color: #6B7882;
    padding: 5px 0px;
    font-size: 12px;
}
.country_search
{
    height: 34px;
    font-size: 15px;
    border: 1px solid #BAC2CD;
    line-height: 16px;
    padding: 8px 12px;
    width: 269px;
    margin-right: 25px;
}
.portfolio_create
{
    top: 0px !important;
}
.bottom_nav
{
    box-shadow: 2px 0px 4px 0px rgba(0,0,0,0.26);
    height: 64px;
    width: 100%;
    background-color: white;
    position: fixed;
    left: 0px;
    bottom: 0px;
    padding: 16px 115px;
}
.project_filter_name_icon
{
    top: 5px;
    width: 20px;
    height: 20px;
}
.project_filter_name
{
    font-size: 14px;
    height: 33px;
    border-radius: 0px;
}

.user-list::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 10px;
    appearance: none !important;
  }
  
  .user-list::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0px;
  }
  
  .user-list::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.25);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }
  
  .user-list::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.15);
  }
  
  .user-list::-webkit-scrollbar-thumb:hover {
    background: rgba(128, 135, 139, 0.8);
  }
.arbnwell_bg_full
{
    background-image: url(../images/arbnwell_bg_full.png);
    background-repeat: no-repeat;
    height: 317px;
    cursor: pointer;
    width: 100%;
    background-size: cover;
    border: 1px solid #D8DCDE;
}
.payByCheckIns p {
    margin-top: 18px;
}
.home_tab_white
{
    margin: -16px 15px 16px 15px;
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0px 32px 5px 0px;
}
#custom_cal, #custom_cal_performance, #custom_cal_improvement, #custom_cal_gresb, .custom_cal
{
    width: 400px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,0.14);
    position: absolute;
    right: 0px;
    display: none;
    z-index: 1000;
    border-radius: 2px;
}
.range_input.active
{
    color: #252C32;
    border-bottom: 2px solid #CACFD5;
    font-weight: bold !important;
    font-family: AmericaMedium;
}
.range_input
{
    color: #68737D;
    padding-bottom: 7.5px;
}
.p16
{
    padding: 16px;;
}
.range_month_selector .fa-angle-left
{
    font-size: 20px;
    margin-left: 24px;
    color: #68737D;
    cursor: pointer;
}
.range_month_selector .fa-angle-right
{
    font-size: 20px;
    margin-right: 24px;
    color: #68737D;
    cursor: pointer;
}.range_month_selector .fa-angle-right:hover, .range_month_selector .fa-angle-left:hover
{
    color: #252C32;
}
.range_month_selector
{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 24px;
}
.border_2x:hover
{
    color: #252C32;
    border-bottom: 2px solid #252C32;
}
.border_2x
{
    border-bottom: 2px solid #68737D;
}
.days_grid .grid_item:hover
{
    background-color: #eee;
}
.days_grid .grid_item.end
{
    border-radius: 0px 32px 32px 0px;
    background-color: #D2E4FC;
}
.days_grid .grid_item.start
{
    border-radius: 32px 0px 0px 32px;
    background-color: #D2E4FC;
}
.days_grid .grid_item.selected
{
    background-color: #D2E4FC;
}
.days_grid .grid_item.old
{
    color: darkgray;
}
.days_grid .grid_item
{
    padding: 5px 0px;
    font-size: 15px;
    line-height: 32px;
    cursor: pointer;
    width: 100%;
}
.days_grid
{
    /* display: grid;
    grid-template-columns: auto auto auto auto auto auto auto; */
    text-align: center;
    text-align: center;
    display: flex;
    justify-content: space-around;
}
#portfolio_map
{
    height: 630px;
    width: 98%;
    margin-left: 15px;
}
.emissions_banner
{
    background-image: url(../images/emissions_banner.png);
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0px;
    left: 0px;
}
.payByCheckIns p {
    margin-top: 18px;
}

.leed_response
{
/*    background: url('../images/leed_leaf.svg') no-repeat left;*/
    height: 50px;
    background-size: 60px;
}

/*New login screen*/

.login-container{
    width: 430px;
    margin: 0px auto;
}

.login-container a{
  color: #1775D2 !important;
}

.login-arc-logo {
    height: 79px;
    width: 125px;
}

.login-input-box {
    box-sizing: border-box;
    height: 49px;
    width: 100%;
    border: 1px solid #949EAB;
    border-radius: 2px;
    background-color: #FFFFFF;
    font-size: 16px;
    padding: 10px;
}

.ls0{
    letter-spacing: 0;
}

.login-button-new{
    height: 48px;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    border-radius: 4px;
    background-color: #1775D2;
    cursor: pointer;
    border: 1px solid #1775D2;
}

.consent-btn{
    height: 40px;
    width: 120px;
}

.consent-btn-container{
    margin: 0px auto;
    width: 300px;
}

.height-auto{
    height: auto;
}

.middle_ware_loading_text
{
    position: fixed;
    left: calc(50% - 145px);
    top: calc(40% + 80px);
}
.flex-center
{
    justify-content: center;
}
.dropdown-menu.address
{
    top: 42px !important;
}
.dropdown-menu.address>li>a.place_name
{
    color: black;
}
.dropdown-menu.address>li>a.formatted_address
{
    color: #555555;
}
.address_loader
{
    position: absolute;
    right: 10px;
    top: 20px;
    width: 15px;
    height: 15px;
}
.manage_note
{
    margin-bottom: 12.5px;
    padding-left: 32px;
    color: #68737D;
    font-size: 15px;
}
.preference_item
{
    margin-left: 186px;
    display: flex;
    margin-bottom: 12px;
}
.preference_label
{
    display: inline;
    padding-top: 7px;
    color: #68737D;
    width: 40%;
    font-size: 16px;
    line-height: 24px;
}
.purchase_certificate
{
    top: 64px !important;
}
.autocase_banner {
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80px;
    background-image: url(../images/autocase.svg);
    float: left;
    width: 100%;
    background-size: 200px;
}
.create_models
{
    padding-top: 59px !important;
    top: 0px !important;
}
#model-overview.arc-table td
{
    padding: 15px 0px 15px 10px !important;
}
#autocae_help
{
    float: right;
    width: 35%;
}
#autocae_help .bottom_info
{
    padding: 24px;
    border: 1px solid #D8DCDE;
}
.data_coverage_red
{
    background: url(https://static-app.arconline.io/data-input-widget-assets-nonprd/svg/q1.svg) no-repeat;
    height: 20px;
    width: 20px;
	background-size: 20px;
	margin-right: 8px;
}
.data_coverage_yellow
{
    background: url(https://static-app.arconline.io/data-input-widget-assets-nonprd/svg/q2.svg) no-repeat;
    height: 20px;
    width: 20px;
	background-size: 20px;
	margin-right: 8px;
}
.data_coverage_green
{
    background: url(https://static-app.arconline.io/data-input-widget-assets-nonprd/svg/q3.svg) no-repeat;
    height: 20px;
    width: 20px;
	background-size: 20px;
	margin-right: 8px;
}
.quality_tip p:nth-child(3)
{
	font-size: 14px;
	line-height: 21px;
	color: #25323C;
}
.quality_tip p:nth-child(2)
{
	font-size: 18px;
	line-height: 24px;
    font-weight: 500;
    font-family: 'AmericaMedium';
	margin-top: 10px;
	margin-bottom: 8px;
    color: #25323C;
}
.quality_tip p:nth-child(1)
{
	color: #68737D;
	font-size: 14px;
    font-weight: 500;
    font-family: 'AmericaMedium';
	line-height: 24px;
}
.quality_tip
{
    height: 220px;
    width: 268px;
    background-color: #FFFFFF;
}
.mb12
{
    margin-bottom: 12px;
}
.space-around
{
    justify-content: space-around;
}
.ml-5px
{
    margin-left: -5px;
}
.climate_risk_report
{
    background-color: white !important;
}
.preference_item
{
    margin-left: 186px;
    display: flex;
    margin-bottom: 12px;
}
.preference_label
{
    display: inline;
    padding-top: 7px;
    color: #68737D;
    width: 40%;
    font-size: 16px;
    line-height: 24px;
}
.purchase_certificate
{
    top: 64px !important;
}
.space-around
{
    justify-content: space-around;
}
.ml-5px
{
    margin-left: -5px;
}
.climate_risk_report
{
    background-color: white !important;
}
.autocae_help_logo
{
    background-color: #035bb1 !important;
    height: 140px;
    padding-top: 30px;
    text-align: center;
}
.sideBar--projectsDetailsModal .modal-dialog table
{
    width: 100%;
}
.sideBar--projectsDetailsModal .modal-dialog th, .sideBar--projectsDetailsModal .modal-dialog td
{
    border: 1px solid black;
    text-align: center;
    padding: 2px 0px;
}
.custom_header
{
    padding: 16px 32px 0px 32px;
}
.custom_body
{
    padding: 15px 32px 0px 32px;
}
.custom_footer
{
    padding: 20px 32px 20px 32px;
    display: flex;
    border-top: 1px solid #D8DCDE;
    justify-content: space-between;
    background-color: rgb(250, 250, 250);
    border-radius: 0px 0px 6px 6px;
}
.manage_team_modal .meterNav .meterNav--item span
{
    padding: 10px 0px;
}
.manage_team_modal .meterNav
{
    border-bottom: none;
}
.manage_team_modal .meterNav .meterNav--item span
{
    border-bottom: 5px solid #eeeeee;
    color: #68737D;
}
.manage_team_modal .meterNav .meterNav--item span.meterNav--item__active
{
    border-bottom: 5px solid #4A4A4A;
    color: #28323B;
}
.user_select_team
{
    background-color: white;
    color: #68737D;
    padding: 5px 15px 5px 14px;
    border-radius: 6px;
    float: left;
    border: 1px solid lightgray;
    margin-bottom: 10px;
}
.user_select_team svg
{
    height: 15px;
    width: 15px;
    position: relative;
    top: 2px;
    left: 4px;
}
.arrow_next
{
    background-image: url(../images/arrow_right_white.svg);
    height: 13px;
    width: 13px;
    display: inline-block;
    position: relative;
    top: 1px;
    right: -3px;
    background-size: 13px;
    cursor: pointer;
}
.arrow_down
{
    background-image: url(../images/arrow_down.svg);
    height: 20px;
    width: 20px;
    display: inline-block;
    position: relative;
    top: 1px;
    right: -3px;
    background-size: 20px;
    cursor: pointer;
}
.user_tab_wrapper
{
    border-radius: 6px;
    position: relative;
    border-bottom: 1px solid #eee;
}
.edit_sel
{
    position: absolute;
    right: 12px;
    top: 8px;
    font-size: 13px;
}
.projects_list_team
{
    background-color: #F4F5F8 !important;
    border: none !important;
    padding: 64px 0px 0px 0px !important;
    top: 0px !important;
}
.manage_team_modal .modal-lg
{
    width: 1140px;
}
.arc_japan_invoice
{
    padding: 0px 0px 0px 30px !important;
    top: 56px !important;
} 
.or_separator
{
    display: flex;
}
.or_separator div:nth-child(2)
{
    text-align: center;
    padding: 0px 10px;
}
.or_separator div:nth-child(1), .or_separator div:nth-child(3)
{
    width: 50%;
    border-top: 1px solid rgba(153, 153, 153, 0.2);
    margin-top: 8px;
}
.step:hover .step_val
{
    background-color: #1E88E5;
}
#tools a
{
    border-bottom: 1px solid;
}
.comparison_card
{
    width: 48%;
    border: 1px solid #CECECE;
    border-radius: 4px;
    padding: 20px 32px 30px 32px;
    position: relative;
    height: fit-content;
}
#tools .btn-primary.inverse
{
    font-size: 12px;
    padding: 5px 20px;
}
.comparison_card ul
{
    padding-left: 10px;
    margin-bottom: 10px;
}
.height-init
{
    height: inherit !important;
    height: initial !important;
}
.w48p
{
    width: 48%;
}
.climate_risk_help_modal .modal-lg
{
    width: 48% !important;
}
.physical_help
{
    position: absolute;
    top: -30px;
    left: calc(100% + 60px);
}
.maintenance_modal
{
    top: 64px !important;
}
#climate_risk .arc-breadcrumbs
{
    height: 4px;
    width: 55px;
    padding-top: 11.5px;
}
.pdf_icon
{
    width: 13px;
    position: relative;
    top: 3px;
    color: #707479;
    left: -2px;
}
.admin_option
{
    padding: 6px 20px 5px 15px;
    border-radius: 3px;
    background-color: lightgray;
    margin-right: 10px;
}
.admin_option .ml10:hover
{
    color: grey;
}
.schoolsclaimschools_parent #content
{
    top: 64px;
}
.as_chevron_down{
    color: #BAC1CA;
    margin-left: 10px;
    margin-top: 4px;
    float: right;
    width: 0.875em;
}
#advanced_scoring_preferences td{
    padding: 10px;
    padding-left: 15px;
}
#advanced_scoring_preferences th{
    padding: 20px 15px !important;
}

.ct_preference_td{
    display: flex;
    border-top: 0px;
    align-items: center;
    position: absolute;
    right: 20px;
}
#advanced_scoring_custom_target .ct_error, #register_for_leed .ct_error{
    color: #dd5826;
    font-size: 14px;
    margin-top: 2px;
}
.advanced_scoring_loader{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
}
.advanced_scoring_loader_relative, .advanced_scoring_loader_relative_165{
    position: relative;
}
.advanced_scoring_loader .materialSpinner--wrapper {
    position: absolute !important;
    left: calc(50% - 50px) !important;
    top: 250px !important;
    z-index: 9999999 !important;
}
.advanced_scoring_gresb{
    height: 300px;
    margin-bottom: 15px;
    border: 1px solid #EFEFEF;
    padding: 35px 15px;
}
.advanced_scoring_overview{
    margin-bottom: 15px;
    box-shadow: 0 0px 1px 0 rgb(0 0 0 / 26%);
    padding: 0px 15px;
}
.advanced_scoring_gresb .space_type_drp{
    border-radius: 1px;
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid #BAC1CA;
    background-color: #FFFFFF;
    text-align: right;
    white-space: nowrap;
}
.advanced_scoring_gresb td, .peer_table td, .advanced_scoring_overview td, .advanced_scoring_overview_container td{
    line-height: 2 !important;
}
.advanced_scoring_loader_relative .materialSpinner--wrapper {
    position: absolute !important;
    left: calc(50% - 50px) !important;
    top: 105px !important;
    z-index: 9999999 !important;
}
.advanced_scoring_loader_relative_165 .materialSpinner--wrapper {
    position: absolute !important;
    left: calc(50% - 50px) !important;
    top: 165px !important;
    z-index: 9999999 !important;
}
#custom_target_table td, #custom_target_table th{
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 10px;
}
#custom_target_table th{
    padding-bottom: 12px;
}
.custom_target_table_year_drp{
    height: 200px;
    overflow-y: auto;
}
.peer_table{
    border: 1px solid #EFEFEF;
    margin-bottom: 15px;
    height: 350px;
    padding-top: 20px;
}
.ellipsis_advanced_scoring{
    display: inline-block;
    width: 323px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.ellipsis_advanced_scoring_overview{
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.advanced_scoring_overview tr:hover{
    background: #F8F8F8;
    cursor: pointer;
    font-weight: 500;
    font-family: 'AmericaMedium';
}
.advanced_scoring_overview_container tr:hover{
    cursor: default;
}
.advanced_scoring_overview th, .advanced_scoring_overview_container th {
    font-size: 15px;
    font-weight: 500 !important;
    font-family: 'AmericaMedium' !important;
    text-transform: inherit !important;
}
.widget_container_advanced_scoring iframe {
    border: 1px solid #EFEFEF;
    margin-bottom: 15px;
}
.advanced_scoring_widget_modal .modal-header {
    color: #000000 !important;
}
.border-none {
    border: none !important;
}
.advanced_scoring_learn_more:hover{
    color: #68737d;
}
.advanced_scoring_learn_more svg{
    width: 22px;
    height: 22px;
}
.advanced_scoring_learn_more{
    padding-top: 1px;
}
.advanced_scoring_learn_more_sc{
    position: absolute;
    margin-top: 1px;
}
.advanced_scoring_learn_more_sc svg {
    width: 15px;
    margin-left: 5px;
}
.advanced_scoring .btn-default{
    border: 1px solid #BAC1CA;
    color: #68737D;
    background-color: #fff;
}
.as_label{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #3D474F;
}
.as_radio_border{
  border: 1px solid #949EAB;
  padding: 13px;
  padding-left: 36px !important;
}
.search_result .materialSpinner--wrapper {
    position: absolute;
    left: calc(50% - 50px);
    top: 40%;
    z-index: 9999999;
}
.scorecard_add_container{
    justify-content: center;
    align-items: center;
    display: flex;
    height: 300px;
}
.scorecard_add_container button
{
    outline: none;
    height: 32px;
    font-size: 15px;
    padding: 0px 22px 0px 42px;
    border-radius: 3px;
    font-weight: 600;
    position: relative;
}
.box-shadow-none{
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.spreadsheet_icon
{
    background: url(https://static-app.arconline.io/data-input-widget-assets-nonprd/svg/Upload_Spreadsheet_Icon_Medium.svg) no-repeat;
    background-size: 30px;
    margin-top: 5px;
    height: 35px;
    width: 35px;
    float: left;
}
.upload_button
{
    padding: 9px 10px !important;
}
.project_creation_modal .projectExcelName 
{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 200px;
    display: block;
}

#scoreTip
{
    display: none;
    background-color: #28323B;
    width: 360px;
    height: 56px;
    position: fixed;
    font-size: 17px;
    color: white;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
    bottom: 20px;
    right: 20px;
    border-radius: 5px;
}

#scoreTip .tipInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#tipCanceler:hover
{
  width: 60px;
}
#tipCanceler .fa-times-circle
{
  cursor: pointer;
}
#tipCanceler .fa-times-circle:hover
{
  color: white;
}
#tipCanceler
{
  font-size: 22px;
  font-weight: bold;
  padding-top: 12px;
  background-color: #707479;
  color: #28323B;
  border-bottom: 13px solid #707479;
  border-radius: 5px 0px 0px 5px;
  width: 10px;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
}
#scoreTip .uploadLoaderSpinner
{
    width: 15px;
    height: 15px;
    margin-top: 5px;
    margin-right: 15px;
}

/*GSA*/
.gsa_logo{
    background: url(../images/gsa/GSA_logo_Img@2x.png) no-repeat left;
    width: 60px;
    background-size: 100%;
    margin-left: -8px;
}
.gsa_logo_container{
    display: flex;
    padding-left: 32px;
    padding-top: 25px;
}
.arc_gsa_logo
{
    background-image: url(../images/gsa/GSA_logo_Img@2x.png);
    height: 40px;
    width: 40px;
    background-size: 40px;
    float: left;
    border-radius: 12px;
    background-repeat: no-repeat;
    position: relative;
    top: -10px;
}
.arc_gsa_logo_report
{
    background-image: url(../images/play_to_zero.jpg);
    height: 36px;
    width: 25px;
    background-size: 23px;
    float: left;
    border-radius: 12px;
    margin-right: 8px;
    background-repeat: no-repeat;
    position: relative;
    top: -4px;
    width: 100% !important;
}
.model_banner_img_gsa {
    background-position: center;
    background-size: cover;
    position: relative;
    height: 160px;
    background-image: url(../images/gsa/Project-Tool-Cover-Page.png);
    background-color: #28323B;
}
.ptz-badge-container {
    display: grid;
    /*grid-gap: 30px;*/
}
.ptz-badge-logo {
    background-size: contain;
    background-image: url(../images/gsa/ptz_gsa_logo.png);
    background-repeat: no-repeat;
    height: 50px;
    background-position: center;
}
.gsa-net-zero-logo{
    background-size: contain;
    background-image: url(../images/gsa/gsa_net_zero.png);
    background-repeat: no-repeat;
    height: 50px;
    background-position: center;
    height: 110px;
}
.color-6B7882{
    color: #6B7882;
}
.gsa_logo_banner {
    height: 80px;
    background: url(../images/play_to_zero.jpg) no-repeat center;
    background-size: 78px;
}
.gsa_icon_bg
{
    width: 72px;
    height: 75px;
    background-size: 100%;
    background-position: center;
    top: 0px;
}
.banner_format
{
    width: 200px !important;
    height: 80px !important;
}
.banner_format .logo_source
{
    width: 200px;
    background-size: cover;
    background-position: 0px 0px;
    clip: rect(0px,199px,79px,0px);
    position: absolute;
    left: 0px;
    height: auto;
}
.center_align_format
{
    margin: 0 auto;
}
.bs-gray-top-0{
  box-shadow: 0px 1px 1px 0 rgb(0 0 0 / 26%);
}
.bs-gray{
  box-shadow: 0px 0px 0px 1px #e1e1e1;
}
.gg20{
  grid-gap: 20px;
}

/*Tagify overrride*/
.project_info .tagify{
    border: none;
    border-bottom: 2px solid #CACFD5;
}
.tagify--focus{
    outline: none !important;
    border-color: #ccc !important;
}
.tagify__dropdown__wrapper{
    border-color: lightgrey !important;
    max-height: 400px !important;
}
.tagify__dropdown__item--active {
    background: #F4F5F8 !important;
    color: inherit !important;
}
.tag_error{
    color: #dd5826;
    font-size: 14px;
    font-family: AmericaMedium;
    font-weight: 500;
    margin-top: 5px;
}
.free_model_option
{
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 12px;
    border: 1px solid #3D474F;
}
.free_model_option.started
{
    width: 25px;
    height: 25px;
    border-radius: 13px;
    opacity: 1;
    background: repeating-linear-gradient( -45deg, #000000, #000000 1px, #f9f9fd 1px, #f9f9fd 4px );
    border: none;
}
.free_model_option.used
{
    background: #3D474F;
}
.arc-btn-white
{
    background-color: white;
    color: #28323B;
    padding: 8px 15px 11px 15px;
    border: 1px solid #BAC2CD;
}
.eff_analysis_date_wrapper
{
    margin-top: -6px;
    position: relative;
    top: -11px;
}
.efficiency_analysis .meterNav .meterNav--item span
{
    padding: 13px 0px;
}
.plr32
{
    padding-left: 32px;
    padding-right: 32px;
}
.max-content
{
    height: max-content;
}
.modal-backdrop
{
    background: rgba(40, 50, 59, 0.6);
    backdrop-filter: blur(3px);
    opacity: 1 !important;
}
.ea_header
{
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 26%);
    padding: 30px 35px;
    width: 49.3%;
    margin-top: 23px;
}
.dropdown-menu > li.selected > a
{
    background-color: #f5f5f5;
    background-color: #f5f5f5;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
}
.arc-input-ea
{
    font-size: 14px;
    font-weight: normal;
    color: #28323B;
    padding: 9px 15px 7px 15px;
    border: 1px solid #ccc;
    background: white;
    border-radius: 2px;
}
.premium_block ul
{
    color: white;
}
.premium_block
{
    position: absolute;
    top: 65px;
    left: 0px;
    width: 100%;
    height: calc(100% - 40px);
    background: rgba(40, 50, 59, 0.6);
    backdrop-filter: blur(4.5px);
    font-size: 15px;
    line-height: 24px;
    z-index: 1;
}
.ea_banner_img
{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 160px;
    background-image: url(../images/Certificates_Banner_Blue_570px@2x.svg);
    float: left;
    width: 51%;
}
.pb55
{
    padding-bottom: 55px;
}
.step_parent.active .step_name
{
    text-decoration: underline;
}
.justify-end
{
    justify-content: end
}
.btn-survey-unit:hover span
{ 
    text-decoration: underline;
}
.arc-input
{
    font-size: 14px;
    font-weight: normal;
    color: #28323B;
    padding: 7px 15px;
    width: 100%;
    border: 1px solid #ccc;
}
.reading_error
{
    border: 2px solid #D84315 !important;
}
.portfolio_score_progress .progress_item span
{
    font-family: 'AmericaCompressed';
    font-size: 16px;
    position: relative;
    top: 6px;
    right: 10px;
}
.portfolio_score_progress .progress_item
{
    margin-right: 1px;
}
.portfolio_score_progress
{
    height: 35px;
    display: flex;
    text-align: right;
}
.energy_portfolio_progress
{
    background-color: #D0DD3D;
    height: 35px;
}
.water_portfolio_progress
{
    background-color: #55CAF5;
    height: 35px;
}
.waste_portfolio_progress
{
    background-color: #84CCB0;
    height: 35px;
}
.transport_portfolio_progress
{
    background-color: #A39F92;
    height: 35px;
}
.human_experience_portfolio_progress
{
    background-color: #F2AC41;
    height: 35px;
}
.base_portfolio_progress
{
    background-color: #CACFD5;
    height: 35px;
}
.bold_underline
{
    font-weight: 500;
    font-family: 'AmericaMedium';
    text-decoration: underline;
    background-color: #f5f5f5;
}
.filter_selected_count
{
    color: #2164B2;
    border: 1px solid #2164B2;
    border-radius: 12px;
    padding: 0px 5px;
}
.filter_selected
{
    color: #2164B2;
}
.filter_portfolio_option
{
    font-size: 14px;
    padding: 10px 15px !important;
}
.payment_updated_content{
    margin: 0px 25px 25px 38px;
    width: 65%;
}
.payment_summary_card{
    position: fixed;
    width: 30%;
    margin-left: 15%;
    left: 52%;
}
.cursor-pointer-hover{
    cursor: pointer;
}
.cursor-pointer-hover:hover{
    background-color: #fafafa;
    border: 1px solid #ccc;
}
main.payment, main.review_payment {
    overflow: inherit !important;
}
.sticky_div {
    position: -webkit-sticky;
    position: sticky;
    top: -16px;
}
.title_images.active .icon_wrapper, .modal-content .title_images:hover .icon_wrapper
{
    width: 72px;
    height: 72px;
}
.regen_model
{
    width: 415px;
    margin-top: 15px;
}
a
{
  cursor: pointer;
}
.form-control:focus
{
  box-shadow: none;
}

.checkbox {
  padding-left: 20px;
  }
  .checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
  }
  .checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }
  .checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555;
  }
  .checkbox input[type="checkbox"],
  .checkbox input[type="radio"] {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  }
  .checkbox input[type="checkbox"]:focus + label::before,
  .checkbox input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  }
  .checkbox input[type="checkbox"]:checked + label::after,
  .checkbox input[type="radio"]:checked + label::after {
  font-family: "FontAwesome";
  content: "\f00c";
  }
  .checkbox input[type="checkbox"]:disabled + label,
  .checkbox input[type="radio"]:disabled + label {
  opacity: 0.65;
  }
  .checkbox input[type="checkbox"]:disabled + label::before,
  .checkbox input[type="radio"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
  }
  .checkbox.checkbox-circle label::before {
  border-radius: 50%;
  }
  .checkbox.checkbox-inline {
  margin-top: 0;
  }
  
  .checkbox-primary input[type="checkbox"]:checked + label::before,
  .checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #337ab7;
  border-color: #337ab7;
  }
  .checkbox-primary input[type="checkbox"]:checked + label::after,
  .checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff;
  }
  
  .checkbox-danger input[type="checkbox"]:checked + label::before,
  .checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f;
  }
  .checkbox-danger input[type="checkbox"]:checked + label::after,
  .checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff;
  }
  
  .checkbox-info input[type="checkbox"]:checked + label::before,
  .checkbox-info input[type="radio"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de;
  }
  .checkbox-info input[type="checkbox"]:checked + label::after,
  .checkbox-info input[type="radio"]:checked + label::after {
  color: #fff;
  }
  
  .checkbox-warning input[type="checkbox"]:checked + label::before,
  .checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  }
  .checkbox-warning input[type="checkbox"]:checked + label::after,
  .checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff;
  }
  
  .checkbox-success input[type="checkbox"]:checked + label::before,
  .checkbox-success input[type="radio"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c;
  }
  .checkbox-success input[type="checkbox"]:checked + label::after,
  .checkbox-success input[type="radio"]:checked + label::after {
  color: #fff;
  }
  
  .radio {
  padding-left: 20px;
  }
  .radio label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
  }
  .radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
  }
  .radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 11px;
  height: 11px;
  left: 3px;
  top: 3px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #555555;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  }
  .radio input[type="radio"] {
  opacity: 0;
  z-index: 1;
  }
  .radio input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  }
  .radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  }
  .radio input[type="radio"]:disabled + label {
  opacity: 0.65;
  }
  .radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
  }
  .radio.radio-inline {
  margin-top: 0;
  }
  
  .radio-primary input[type="radio"] + label::after {
  background-color: #337ab7;
  }
  .radio-primary input[type="radio"]:checked + label::before {
  border-color: #337ab7;
  }
  .radio-primary input[type="radio"]:checked + label::after {
  background-color: #337ab7;
  }
  
  .radio-danger input[type="radio"] + label::after {
  background-color: #d9534f;
  }
  .radio-danger input[type="radio"]:checked + label::before {
  border-color: #d9534f;
  }
  .radio-danger input[type="radio"]:checked + label::after {
  background-color: #d9534f;
  }
  
  .radio-info input[type="radio"] + label::after {
  background-color: #5bc0de;
  }
  .radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de;
  }
  .radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de;
  }
  
  .radio-warning input[type="radio"] + label::after {
  background-color: #f0ad4e;
  }
  .radio-warning input[type="radio"]:checked + label::before {
  border-color: #f0ad4e;
  }
  .radio-warning input[type="radio"]:checked + label::after {
  background-color: #f0ad4e;
  }
  
  .radio-success input[type="radio"] + label::after {
  background-color: #5cb85c;
  }
  .radio-success input[type="radio"]:checked + label::before {
  border-color: #5cb85c;
  }
  .radio-success input[type="radio"]:checked + label::after {
  background-color: #5cb85c;
  }
  
  input[type="checkbox"].styled:checked + label:after,
  input[type="radio"].styled:checked + label:after {
  font-family: 'FontAwesome';
  content: "\f00c";
  }
  input[type="checkbox"] .styled:checked + label::before,
  input[type="radio"] .styled:checked + label::before {
  color: #fff;
  }
  input[type="checkbox"] .styled:checked + label::after,
  input[type="radio"] .styled:checked + label::after {
  color: #fff;
  }
  
.dropdown-menu>li>a.active
{
    background-color: #f5f5f5;
}
.dropdown-menu>li>a
{
    padding: 10px 15px;
}
.dropdown-menu.address
{
    top: 42px !important;
}
.dropdown-menu
{
    /* width: 100%; */
    left: initial;
    top: -3px;
    border: none;
    border-radius: 0px;
    box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 23%);
}

ngb-modal-backdrop
{
    z-index: 11 !important;
}
ngb-modal-window.fade
{
    opacity: 1 !important;
}
.modal.fade .modal-dialog
{
    transform: inherit;
}
.modal-footer:before, .modal-footer:after
{
    display: none;
}
.display-inline[hidden]
{
    display: none;
}
.fa-trash-alt
{
    cursor: pointer;
}
.fa-trash-alt:hover
{
    color: black;
}
.modal-fullscreen
{
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}
#plaque-component .pie_chart_container
{
    position: absolute;
    z-index: 1;
    top: -25px;
    left: -25px;
}
.help-block
{
    float: left;
    width: 100%;
    font-size: 12px;
}
.arrow-up
{
    background: url(../images/arrow_up.svg);
    width: 12px;
    height: 12px;
    display: inline-block;
    background-size: 12px;
    background-position: 1px 1px;
    background-repeat: no-repeat;
}
.arrow-down
{
    background: url(../images/arrow-down.svg);
    width: 12px;
    height: 12px;
    display: inline-block;
    background-size: 12px;
    background-position: 0px 3px;
    background-repeat: no-repeat;
}
.btn-primary:hover
{
    background-color: #23416D !important;
    border-color: #23416D !important;
}
.button-disable, .not-active.btn
{
    cursor: default;
    pointer-events: none;
    text-decoration: none;
    background-color: #68737D !important;
    border-color: #68737D !important;
    opacity: 1 !important;
    color: white !important;
}
.btn
{
    border-radius: 2px !important;
}
.btn-primary
{
    border-color: #1e88e5 !important;
}
.btn-primary:hover
{
    background-color: #23416D !important;
    border-color: #23416D !important;
}
.ml45
{
    margin-left: 45px;
}

.line_height_16 {
    line-height: 16px !important;
}
.line_height_29 {
    line-height: 29px !important;
}

.w350p
{
    width: 350px;
}
#price_summary
{
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 32px 22px;
    width: 350px;
}
.total_price_eco
{
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    margin-bottom: 8px;
}
.p10_0
{
    padding: 10px 0px;
}

.ml24
{
    margin-left: 24px;
}
.mr24
{
    margin-right: 24px;
}
#caret_project_selection
{
    transition: 200ms ease all;
}
.rotate90
{
    transform: rotate(-90deg);
}
#project_selection_table td
{
    font-size: 15px;
    line-height: 24px !important;
    padding: 14px 0px !important;
}
.table_dis
{
    display: table;
}
.table_cell
{
    display: table-cell;
}
.sidebar_nav
{
    background: #f4f5f8;
    z-index: 3;

    height: 100%;
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
}
.sidebar_nav.open
{
    width: 50%;
}
.side_nav_option.active
{
    color: #4a4a4a;
    background-color: #e5e6ea;
    font-weight: 500;
    font-family: AmericaMedium;
}
.side_nav_option
{
    font-size: 15px;
    padding: 10px 15px;
    color: #6b7882;
}

.hub-button {
    background-color: #353535;
    color: #fff;
    border-radius: 24px;
    font-family: Source Sans Pro Bold;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: .01em;
    padding: 8px 32px;
    height: 40px;
    border: none;
}
.hub-button.flat:hover {
    background-color: rgba(0,0,0, 0.1);
}
.hub-button.flat {
    background-color: white;
    color: #353535;
    border: 1px solid #353535;
}
.hub-button:hover {
    opacity: .9;
}
.hub-button:active {
    opacity: .8;
}

.switchover_modal .modal-dialog {
    width: 800px;
}
.switchover_modal .modal-content {
    border-radius: 16px;
}