.rangeslider,
.rangeslider__fill {
    display: block;
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, .3);
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, .3);
    border-radius: 10px
}

.rangeslider {
    background: #e6e6e6;
    position: relative
}

.rangeslider--horizontal {
    height: 5px;
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: none;
}

.rangeslider--vertical {
    width: 20px;
    min-height: 150px;
    max-height: 100%
}

.rangeslider--disabled {
    filter: alpha(Opacity=40);
    opacity: .4
}

.rangeslider__fill {
    position: absolute;
    box-shadow: none;
}

.rangeslider--horizontal .rangeslider__fill {
    top: 0;
    height: 100%
}

.rangeslider--vertical .rangeslider__fill {
    bottom: 0;
    width: 100%
}

.rangeslider__handle {
    border: none;
    cursor: pointer;
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    background-size: 100%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    border-radius: 12px;
}

.rangeslider--active .rangeslider__handle,
.rangeslider__handle:active {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, rgba(0, 0, 0, .1)), color-stop(100%, rgba(0, 0, 0, .12)));
    background-image: -moz-linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .12));
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .12));
    background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .12))
}

.rangeslider--horizontal .rangeslider__handle {
    top: -10px;
    touch-action: pan-y;
    -ms-touch-action: pan-y
}

.rangeslider--vertical .rangeslider__handle {
    left: -10px;
    touch-action: pan-x;
    -ms-touch-action: pan-x
}

input[type=range]:focus+.rangeslider .rangeslider__handle {
    -moz-box-shadow: 0 0 8px rgba(255, 0, 255, .9);
    -webkit-box-shadow: 0 0 8px rgba(255, 0, 255, .9);
    box-shadow: 0 0 8px rgba(255, 0, 255, .9)
}


/*# sourceMappingURL=rangeslider.min.css.map */