/*****************************************************

0. Glossary for common things (Ellis to provide all details):
    font-color, font-style, button colors, border colors, category colors etc


CSS Contents (Center Body):
--------------------------
0. Common styles - wrappers for all containers
    0.1 side bar table for portfolio projects: can be used for other pages as well
1. Page title(s) - common for all pages ()
2. My Projects Page
3. Project Registration pages (including payment page)
3. Credit actions, Measures
4. Score pages
5. Analytics Pages
6. Manage pages
7. Project Registration
8. Portfolio Pages
9. Material Loader
10.Survey page

*****************************************************/

/** 0. Common styles - wrappers for all containers/Tables: Starts **/
.pageLoading__Opacity{
    opacity: 0.40;
}

.display--inline__block{
    display: inline-block;
}

.display--block{
    display: block;
}

.display--none{
    display: none;
}

.visibilty--hidden{
    visibility: hidden;
}

.pointer--none{
    pointer-events: none;
}

.clear__left{
    clear: left;
}

.text--notCapitalize{
    text-transform: none;
}

.hover-bg{
    background: #EEEEEE;
}

.noProjects-addProjects--container{
    background: #fff;
    width: 98%;
    margin: auto;
    margin-left: 15px;
    padding-top: 1px;
    padding-bottom: 50px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.26);
}
.modal-body .noProjects-addProjects--container
{
    padding-bottom: 30px;
    box-shadow: none;
}


.addProjects--buildingIcon{
    margin: auto;
    display: block;
    margin-top: 50px;
    height: 132px;
    width: 150px;
}

.noProjects-addProjects--title{
    clear: both;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    margin-top: 20px;
    color: #28323B;
}

.noProjects-addProjects--content{
    width: 97%;
    margin: auto;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #28323B;
    margin-top: 15px;
    margin-bottom: 32px;
}

.noProjects-addProjects-btn--container{
    width: 50%;
    margin: auto;
    text-align: center;
    margin-top: 20px;
}

.noProjects-addProjects-btn--container .btn{
    float: none;
}

.noDataForWidget--container{
    padding-left: 0px !important;
    padding-top: 30px !important;
    text-align: center;
}

.noDataForFilter--container{
    padding-left: 0px !important;
    padding-top: 40px !important;
}

.noDataForFilter--title,
.noDataForWidget--title{
    font-size: 16px;
    font-weight: normal;
    color: #28323B;
}

.noDataForFilter--clearFilter{
    font-weight: normal;
    color: #28323B;
}

.noDataForFilter--clearFilter--btn{
    font-weight: normal;
    color: #196cd0;
    cursor: pointer;
}

.noDataForFilter--clearFilter--btn:hover{
    text-decoration: underline;
}

.arcTbl{
    margin-bottom: 45px;
}

.arcTbl .arcTbl--header{
    color: #28323B;
    font-family: America;
    font-weight: 600 !important;
    line-height: 17px !important;
    padding-left: 0px;
    cursor: pointer;
}

.arcTbl--loadingMmsg{
    font-weight: normal;
    padding: 20px !important;
    background: #eee;
    border-radius: 4px;
    text-align: center;
}

.arcTbl .arcTbl--header span.fa{
    font-size: 16px !important;
}

.arcTbl .arcTbl--header span {
    pointer-events: none;
}

.arcTbl .arcTbl--leftHeader{
    text-align: left;
    padding-left: 0px;
}

.arcTbl .arcTbl--leftHeader span{
    color: #28323B;	
    font-family: "AmericaMedium";
    font-weight: 500;	
}

.arcTbl .arcTbl--rightHeader{
    text-align: right;
}

.arcTbl tr.arcTbl--row td {
    vertical-align: middle !important;
    cursor: pointer;
    padding-left: 0px;
    border-bottom: 1px solid #ddd;
}

.arcTbl--projectName{
    font-weight: 500;
}

.arcTbl--projectId,
.arcTbl--projectGeo{
    color: #28323B;
    font-weight: normal;
}

.arcTbl--projectCert{
    color: #FFFFFF;
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 6px 10px;
    border-radius: 2px;
    display: inline-block;
}

.arcTbl--projectCertParent{
    /* width: 120px; */
    display: inline-block;
}

.arcTbl .platinum, .modal-body .platinum{
    background-color: #818183;    
}

/*.arcTbl .certified,
.arcTbl .pre-certified{
    background-color: #A5BA47;
}*/

.arcTbl .certified, .modal-body .certified
{
    background-color: #A5BA47;
}
.arcTbl .pre-certified, .modal-body .pre-certified
{
    background-color: white;
    border: 2px solid #818183;
    color: #818183;    
}

.arcTbl .gold, .modal-body .gold{
    background-color: #d5ae00;
}

.arcTbl .silver, .modal-body .silver{
    background-color: #83bbc9;
}

.arcTbl--projectScoreWrapper{
    text-align: center;
    float: right;
    padding-top: 5px;
    height: 36px;
    width: 36px;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-color: rgb(137,138,137);
    background-size: 35px 33px;
    background-position: 1px 2px;
}

.arcTbl .arcTbl--projectScore{
    color: #FFFFFF;
    font-size: 20px;
    text-align: center;
    font-family: America;
}

.arcTbl .carbon--totalRawGhgValue{
    font-size: 16px;
    font-weight: bold;
}

.arcTbl .arcTbl--row .progress {
    margin-bottom: 0px;
    height: 35px;
    background: none;
    border-radius: 0px;
}

.arcTbl .arcTbl--row .progressBar--score,
.arcTbl .arcTbl--row .progressBar--carbon{
    border-left: 1px solid #ddd;
    padding-left: 0px;
}

.score--progressBar__marginRight{
    margin-right: 0.05pc;
    min-width: 20px;
}

.arcTbl .arcTbl--row .progressBar--totalEmissions{
    width: 8%;
    text-align: right;
}

.carbon--progressBar__marginRight{
    margin-right: 0.05pc;
    min-width: 9%;
    max-width: 72%;
}

.arcTbl .arcTbl--scoreheader{
    width: 7%;
}

.arcTbl .arcTbl--row .progress .progress-bar{
    border-radius: 0px;
}

.arcTbl .arcTbl--row .project--score{
    padding-right: 6px;
    text-align: right;
    padding-top: 7px;
    font-size: 16px;
    color: #28323B;
    font-weight: 500;
    font-family: AmericaMedium;
}

.arcTbl--pagination{
    width: 35%;
    /* position: absolute; */
    /* right: 0; */
    margin-bottom: 10px;
    margin-right: 15px;
    float: right;
    /* bottom: -10px;*/
}

.arcTbl--paginationLoader{
    float: left;
    margin-top: 5px;
    margin-right: 10px;
    border-radius: 23px;
    border-width: 12px;
    width: 9px;
    height: 9px;
    z-index: 2000;
    border: solid 2px transparent;
    border-top-color: darkgray;
    border-left-color: darkgray;
    -moz-animation: spin 400ms linear infinite;
    animation: spin 400ms linear infinite;
    margin-left: 10px;
}

.arcTbl--noOfRows{
    width: 50%;
    float: left;
}

.arcTbl--rowsPerPageTitle{
    color: #707479;
    line-height: 19px;
    float: left;
}

.arcTbl--pageCounter{
    color: #707479;
    width: 25%;
    float: left;
}

.arcTbl--pageSize{    
    color: #28323B;
}

.arcTbl--pageSizeDrp{
    display: inline;
    margin-left: 5px;
}

.arcTbl--pageSizeDrp .dropdown-menu{
    top: auto;
    bottom: 100%;
}

.arcTbl--pageSizeDrp-btn{
    border: none;
    background: white;
    padding-top: 0px;
    height: initial;
}

.arcTbl--paginationNav{
    width: 25%;
    text-align: right;
    display: flex;
    padding-top: 3px;
}

.arcTbl--nav-btn__prev{
    background: url(../../assets/images/arrow_left.svg) no-repeat; 
    width: 20px;
    height: 20px;
    border: none;
}

.arcTbl--nav-btn__next{
    background: url(../../assets/images/arrow_right.svg) no-repeat;
    width: 20px;
    height: 20px;
    border: none;
    margin-left: 20px;
}

.arc--exportIcon{
    background: url(../../assets/images/export_icon.svg) no-repeat;
    width: 30px;
    height: 30px;
    background-size: 25px;
}

.arc--closModalIcon{
    background: url(../../assets/images/close_icon.svg) no-repeat;
    width: 30px;
    height: 30px;
    background-size: 25px;
    cursor: pointer;
    float: right;
}

.projectList--toolsAndInfoWrapper{
    font-size: 15px;
    font-weight: 500;
}

.projectList--numOfProjectsWrapper{
    padding-left: 0px;
    padding-top: 10px;
}

.projectList--numOfProjectsLabel{
    color: #252C32;
    font-weight: normal;
}

.projectList--numOfProjectsCount{
    color: #252C32;
    font-weight: 500;
    font-family: 'AmericaMedium';
}

.projectList--toolsWrapper{
    padding-right: 0px;
}

.arcFilter--wrapper{
    float: right;
}

.arcFilter--wrapper__scoreView,
.arcFilter--wrapper__scoreSortView{
    margin-right: 30px;
}

.arcFilter--wrapper .arcFilter-btn{
    border: none;
    background: #fff;
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.arcFilter--wrapper .arcFilter-btn--title{
    color: #28323B;
    font-weight: normal;
}

.arcFilter--wrapper .arcFilter-btn--title__filterSelected{
    color: #2164B2;
    font-weight: normal;
}

.arcFilter--wrapper .arcFilter-btn--filterCount{
    color: #2164B2;
    border: 1px solid #2164B2;
    border-radius: 12px;
    padding: 0px 5px;
    font-weight: normal;
}

.arcFilter--wrapper .arcFilter-btn__downArrow{
    background: url(../../assets/images/arrow_down.svg) no-repeat;
    width: 12px;
    height: 20px;
    background-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
    float: right;
}

.arcFilter--wrapper ul.dropdown-menu-form{
    right: 0;
    left: inherit;
    padding: 15px 0px;
}

.arcFilter--wrapper__scoreView ul.dropdown-menu-form{
    min-width: 225px;
}

.arcFilter--wrapper__scoreSortView ul.dropdown-menu-form{
    min-width: 175px;
}

.arcFilter--wrapper ul.dropdown-menu-form .checkbox input[type="checkbox"],
.arcFilter--wrapper__scoreSortView ul.dropdown-menu-form .checkbox input[type="checkbox"],
.arcFilter--wrapper__scoreView ul.dropdown-menu-form .checkbox input[type="checkbox"]{
    cursor: pointer;
}

.arcFilter--scoreCategory ul.dropdown-menu-form{
    min-width: 200px;
}

.arcFilter--wrapper ul.dropdown-menu-form li.arcFilter--options__cert{
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: normal;
    cursor: pointer;
}

.arcFilter--wrapper ul.dropdown-menu-form li.arcFilter--options__scoreCategory{
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: normal;
    cursor: pointer;
}

.arcFilter--wrapper ul.dropdown-menu-form li.arcFilter--options__scoreView{
    text-align: left;
    padding: 5px 15px;
    font-weight: normal;
    cursor: pointer;
}

.arcFilter--wrapper ul.dropdown-menu-form li.arcFilter--options__cert:hover,
.arcFilter--wrapper ul.dropdown-menu-form li.arcFilter--options__scoreView:hover,
.arcFilter--wrapper ul.dropdown-menu-form li.arcFilter--options__scoreCategory:hover{
    background: #EEEEEE;
}

.arcFilter--wrapper ul.dropdown-menu-form li.arcFilter--options__cert div.checkbox{
    margin-left: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.arcFilter--wrapper ul.dropdown-menu-form li.arcFilter--options__scoreCategory div.checkbox{
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.arcFilter--wrapper ul.dropdown-menu-form li.arcFilter--options__cert:first-child div.checkbox{    
    margin-left: 0px;
}

.arcFilter--wrapper ul.dropdown-menu-form li.arcFilter--options__cert div.checkbox label,
.arcFilter--wrapper ul.dropdown-menu-form li.arcFilter--options__scoreCategory div.checkbox label{
    min-width: 100px;
    text-align: left;
    padding: 0px;
    margin: 0px;
    padding-left: 5px;
}

.scoreCategory--Wrapper{
    margin-bottom: 20px;
}

.scoreCategory--Wrapper > div{
    display: inline-block;
    margin-right: 20px;
}

.scoreCategory--base .scoreCategory--box,
.progress-bar.base_score,
.progress-bar.base{
    background-color: #CACFD5;
}

.scoreCategory--energy .scoreCategory--box,
.progress-bar.energy{
	background-color: #D6E06D;
}

.scoreCategory--water .scoreCategory--box,
.progress-bar.water{
    background-color: #55CAF5;
}

.scoreCategory--waste .scoreCategory--box,
.progress-bar.waste{
    background-color: #84CCB0;
}

.scoreCategory--transport .scoreCategory--box,
.progressBar--carbon .raw_transit-bg,
.progress-bar.transport{
    background-color: #A39F92;
}

.scoreCategory--human .scoreCategory--box,
.progress-bar.human_experience{
    background-color: #F2AC41;
}

.scoreCategory--scope1 .scoreCategory--box,
.progressBar--carbon .scope1_raw_ghg-bg{
    background-color: #E0E0E0;
}

.scoreCategory--scope2 .scoreCategory--box,
.progressBar--carbon .scope2_raw_ghg-bg{
    background-color: #BDBDBD;
}

.scoreCategory--box{
    width: 14px;
    height: 14px;
    display: inline-block;
}

.scoreCategory--title{
    font-weight: normal;
    display: inline-block;
    margin-left: 5px;
}

/** 0.1 side bar table for portfolio projects: can be used for other pages as well : Starts**/
.sideBar--projectsDetailsModal.fade .modal-dialog {
    right: 0px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.sideBar--projectsDetailsModal.fade.in .modal-dialog {
    right: 0px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.sideBar--projectsDetailsModal .modal-dialog {
    position: fixed;
    margin: auto;
    width: 35%;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.sideBar--projectsDetailsModal__occupantEmissions .modal-dialog{
    width: 55%;
}

.sideBar--projectsDetailsModal .modal-dialog .modal-header{
    border-bottom: 0;
    padding: 20px;
}

.sideBar--projectsDetailsModal .modal-header .modal-title{
    font-weight: normal;
    color: #252C32;
    font-size: 20px;
}

.sideBar--projectsDetailsModal .modal-dialog .modal-header .modal-title{
    float: left;
}

.sideBar--projectsDetailsModal .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 0px;
}
.sideBar--projectsDetailsModal .arc-tbl th.gross-sq-ft-lbl{
    padding-right: 0px;
}
.sideBar--projectsDetailsModal .modal-dialog .modal-body{
    padding: 20px; 
}

.sideBar--projectsDetailsModal .modal-body .arcTbl{
    margin-top: 10px;
}

.sideBar--projectsDetailsModal .modal-body .arcTbl .arcTbl--loadingMmsg{
    text-align: center;
    padding: 20px;
}

.sideBar--projectsDetailsModal .portfolioWidget-data--units{
    margin-left: 10px !important;
}
/** 0.1 side bar table for portfolio projects: can be used for other pages as well : Ends**/







/** 0. Common styles - wrappers for all containers/Tables: Ends **/


/** 1. Page title(s) - common for all pages (): Starts **/
.projectHeader{
    margin: 0 0 20px;
}

.projectHeader--title{
    font-size: 36px;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.1;
    color: inherit;
}

.projectHeader-btn--addProject{
    background-color: #64bd63; /* TBP by Ellis */
    border-color: #52b551; /* TBP by Ellis */
    float: right;
}

.projectHeader-btn--addPortfolio{
    background-color: #64bd63; /* TBP by Ellis */
    border-color: #52b551; /* TBP by Ellis */
    float: right;
    margin-left: 10px;
}

.projectListWrapper{
    position: relative;
    margin-bottom: 30px;
    padding: 20px;
    background: #fff;
    border-radius: 3px;
}

/* 1. Page title(s) - common for all pages (): Ends */



/* 8. Portfolio Pages: Starts */
.portfolioProjectHeader{
    margin: 0 0 20px;
}

.portfolioProjectHeader--title{
    color: #28323B;
    font-family: "America";
    font-size: 38px;
    font-weight: 400;
    line-height: 52px;
}

.portfolioProjectHeader-btn--addProject{
    width: 150px;	
    border-radius: 2px;	
    box-shadow: 0 1px 2px 0 #949EAB;
    float: right;
}

.portfolioProjectHeader-btn--addProjectIcon{
    height: 25px;
    width: 18px;
    color: #FFFFFF;
    font-family: "SS Air";
    font-size: 18px;
    line-height: 25px;
}

.portfolioProjectHeader-btn--addProjectTitle{
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
}

.portfolioNav .portfolioNav--item {
    /* float: left; */
    cursor: pointer;
    margin-right: 40px;
    font-family: 'AmericaMedium';
    font-weight: 500;
    color: #68737D;
}

.portfolioNav .portfolioNav--item:last-child {
    margin-right: 0px;
}

.portfolioNav {
    display: flex;
    margin-bottom: 15px;
    padding: 0;
    margin-left: 20px;
    margin-right: 20px;
    list-style: none;
    border-bottom: 2px solid #D8DCDE;
}

.portfolioNav .portfolioNav--item span {
    display: block;
    padding: 5px 0px 5px 0px;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
}

.portfolioNav .portfolioNav--item span:hover {
    border-bottom: 2px solid #28323B;
    margin-bottom: -2px;
    color: #28323B;
}

.portfolioNav .portfolioNav--item span.portfolioNav--item__active {
    border-bottom: 2px solid #28323B;
    color: #28323B;
    margin-bottom: -2px;
}

.portfolioWidgets--wrapper{
    clear: both;
    display: flex;
    width: 98%;
    margin-left: 15px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.26);
}

.portfolioWidgets--wrapper .portfolioWidget{
    width: 20%;;
}

.portfolioWidget{
    position: relative;
    padding: 20px 20px 15px 20px;
    background: #fff;
    cursor: pointer;
    border-right: 1px solid #E9EBED;
    height: 150px;
}

.portfolioWidget--wrapper__carbonPage .portfolioWidget{
    float: left;
    width: 16.66%;
}

.portfolioWidget--label{
    color: #28323B;
    font-family: "AmericaMedium";
    font-weight: 500 !important;
    font-size: 15px;
    line-height: 18px;
}

.portfolioWidget-data--wrapper{
    margin-top: 10px;
}

.portfolioWidget--unitSelector__downArrow{
    background: url(../../assets/images/arrow_down.svg) no-repeat;
    width: 20px;
    height: 20px;
    background-size: 12px;
    margin-top: 5px;
    margin-left: 5px;
    float: right;
}

.portfolioWidget-data--projectsIcon,
.portfolioWidget-data--floorAreaIcon,
.portfolioWidget-data--emissionsIcon,
.portfolioWidget-data--occupancyIcon,
.portfolioWidget-data--energyIcon,
.portfolioWidget-data--transportIcon{    
    width: 35px;
    height: 30px;
    float: left;
}

.portfolioWidget-data--projectsIcon{
    background: url(../../assets/images/more_building_icon.svg) no-repeat;
}

.portfolioWidget-data--floorAreaIcon{
    background: url(../../assets/images/ruler_icon.svg) no-repeat;
}

.portfolioWidget-data--emissionsIcon{
    background: url(../../assets/images/co2_icon.svg) no-repeat;
}

.portfolioWidget-data--occupancyIcon{
    background: url(../../assets/images/more_user_icon.svg) no-repeat;
}

.portfolioWidget-data--energyIcon{
    background: url(../../assets/images/energy_icon.svg) no-repeat;
}

.portfolioWidget-data--transportIcon{
    background: url(../../assets/images/transport_icon.svg) no-repeat;
}

.portfolioWidget-data--value{
    color: #252C32;
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 0px;
}

.portfolioWidget-data--units .dropdown button{
    border: none;
    background: #fff;
    padding-left: 0px;
}

.portfolioWidget-data--units .dropdown button.stop--default__behaviour span{
    pointer-events: none;
}

.portfolioWidget-data--units .dropdown-menu{
    min-width: 85px;
    padding: 5px;
    border-radius: 0px;
    margin-top: -4px;
    font-size: 14px;
}

.portfolioWidget-data--units .dropdown-menu li:hover{
    background: #8080801c;
}

.portfolioWidget-data--units span{
    color: #68737D;
    font-family: "America";
    line-height: 24px;
    cursor: pointer;
}

.portfolio--exportIcon
{
    float: right;
    margin-left: 20px;
    margin-top: 5px;
    cursor: pointer;
    /* display: none; */
}

.portfolio--exportIcon__sidebar{
    float: right;
    margin-right: -15px;
    display: none;
}

.portfolioContent--wrapper {
    display: block;
    position: relative;
    margin-bottom: 30px;
    padding: 20px 20px;
    background: #fff;
    border-radius: 3px;
    clear: both;
    min-height: 350px;
}



/* 8. Portfolio Pages: Ends */

/* Survey page*/
    .surveyPage
    {
        overflow: auto;

    }

    .surveyPage--languageSelection
    {
        float: right;
        font-weight: 400 !important; 
        font-size: 14px;
        margin-right: 10px;
        min-width: 150px;
        text-align: left;
        padding: 2px 2px 2px 2px;
        cursor: pointer;
    }

    .surveyPage--label{

        float: right;
        font-weight: 400 !important; 
        font-size: 17px;        
    }

    .surveyPage--transportHeader
    {
        text-align: center !important;
        font-weight: 400 !important; 
    }

    .surveyPage--transportMessage
    {
        text-align: center !important;
        color: #39B44A;
        font-weight: 100;
        font-size: 15px;
        margin-top: 10px;
        -webkit-font-smoothing: antialiased;
    }

    .showError
    {
        float: right;
        margin-top: -14px;
        margin-bottom: 10px;
        color: #dd5826;
        cursor: pointer;
        margin-right: 27px;
        font-size: 11px;
    }

    .language-selection 
    {
        min-width: 150px;
        text-align: left;
        padding: 2px 2px 2px 2px;
        cursor: pointer;
    }

    .addDataSource_survey
    {
        color: darkgrey;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid #E2DFDF;
        /* height: 160px;*/
        width: 140px;
        display: inline-block;
        margin-right: 15px;
        text-align: center;   
    }

    #survey_link_email_1, #survey_link_copy_1, #survey_link_email_2, #survey_link_copy_2
    {
        margin-right: 16px;
        border: 1px solid rgb(186, 193,202);
        background-color: white;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 100;
        width: 12%;
        height: 45px;
        text-align: center;
        margin: 0px;
        outline: none;
    }

    .V3_button
    {
        color: grey !important;
        background-color: white;
    }

    .V3_button:hover
    {
        background-color: #428bca !important;
        border: 1px solid #428bca !important;
        color: white !important;
    }

    .surveyLinkAddData
    {
        float: left;
        margin-top: 0px !important;
        width: 100% !important;
    }

    .linkCopied_2, .linkCopied_1
    {
        color: #95BF58;
        position: relative;
        bottom: 32px;
        left: 221px;
        font-size: 12px;
    }

    .linkCopied
    {
        color: #95BF58;
        position: relative;
        top: 15px;
        left: -10px;
        font-size: 12px;
    }

    .copySurveyInput
    {
        width: 100%;
        margin-top: 30px;
    }
    .surveyInputCopy
    {
        width: 85%;
        float: left;
        height: 33px;
        border-radius: 4px 0px 0px 4px;
    }

    .content_container
    {
        margin: 0 auto;
        width: 75%;
        /* margin-left: 0px; */
    }

    .choices
    {
        float: left;
        width: 100%;
        padding-left: 15px;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .feedback_option
    {
        height: 55px;
        width: 55px;
        float: left;
        background-size: 50px 50px !important;
        margin-right: 10px;
        border-radius: 3px;
        background-position: 2.5px 2.5px !important;
        cursor: pointer;
    }

    .happy
    {
        background: url(../../assets/images/happy.png) no-repeat;   
    }

    .okay
    {
        background: url(../../assets/images/okay.png) no-repeat;   
    }

    .unhappy
    {
        background: url(../../assets/images/unhappy.png) no-repeat;   
    }

    .happy.selected_choice
    {
        background-color: rgb(136, 193, 88); 
    }

    .okay.selected_choice
    {
        background-color: rgb(255, 221, 108); 
    }

    .unhappy.selected_choice
    {
        background-color: rgb(231, 112, 107);
    }

    .surveyWrapper
    {
        margin-left: initial !important;
    }

    .surveyContent
    {
        padding: 40px 40px 40px 40px !important; 
    }

    .surveyHighlight 
    {
        color: #39B44A;
        font-weight: 100;
        font-size: 15px;
        margin-top: 10px;
        -webkit-font-smoothing: antialiased;
    }

    .routeLabel
    {
        float: left;
        width: 20%;
        background: url(../images/route-label.png) no-repeat;     
        height: 38px;
        color: white;
        padding: 8px 34px;
        font-weight: 100;
        -webkit-font-smoothing: antialiased;
        margin-top: -3px;
        background-position: 22px -2px;
        background-size: 100px;
    }

    .routeLabelGermanLanguage 
    {
        float: left;
        width: 20%;
        background: url(../images/route-label.png) no-repeat;
        background-size: 117px;
        height: 45px;
        color: white;
        padding: 12px 34px;
        font-weight: 100;
        -webkit-font-smoothing: antialiased;
        margin-top: -1px;
        background-position: 22px 0px;
    }

    .routeTypes
    {
        float: right;
        width: 80%;
    }

    .surveyHeading
    {
        width: 100%;
    }

    .survey_fontsize 
    {
        float: left;
        width: 12.5%;
        text-align: center;
    }

    .unit_dropdown
    {
        float: left;
        width: 20%;
        padding-left: 25px;
    }

    .survey_input
    {
        width: 50% !important;
        margin: 0 auto;
    }

    /*.addRoute
    {
        float: left;
        width: 100%;
        padding-left: 44%;
    }*/

    .addRouteLabel
    {
        background: url(../images/add-route.png) no-repeat;
        width: 100%;
        padding-left: 40px;
        padding-top: 4px;
        margin-left: 45%;
        margin-top: 5%;
        padding-bottom: 10px;    
        cursor: pointer;
        float: left;
    }

    .addRouteLabel_german
    {
        background: url(../images/add-route.png) no-repeat;
        width: 100%;
        padding-left: 40px;
        padding-top: 4px;
        margin-left: 40%;
        margin-top: 5%;
        padding-bottom: 10px;    
        cursor: pointer;
        float: left;
    }

    .surveySeparator
    {
        border-top: 1px solid #dddddd !important;
        float: left;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .smiley
    {
        background: url(../images/smilies.png) no-repeat center center;
        height: 85px;
        width: 85px;
        margin: auto;
    }

    .satis1
    {
        background-position: -00px 0px;
    }

    .satis2
    {
        background-position: -100px 0px;
    }

    .satis3
    {
        background-position: -200px 0px;
    }

    .satis4
    {
        background-position: -300px 0px;
    }

    .satis5
    {
        background-position: -400px 0px;
    }

    .satis6
    {
        background-position: -500px 0px;
    }

    .satis7
    {
        background-position: -600px 0px;
    }

    .satis8
    {
        background-position: -700px 0px;
    }

    .satis9
    {
        background-position: -800px 0px;
    }

    .satis10
    {
        background-position: -900px 0px;
    }

    .satis11
    {
        background-position: -1000px 0px;
    }

    #surveySlider
    {
        display: inline-block;     
        appearance: none !important;
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        -ms-user-select: none !important;
        user-select: none !important;
        width: 100%;
        height: 8px;
        margin-top: 20px;
        margin: auto;
    }

    .sorry
    {
        width: 100%;
        float: left;
        background-size: 100% 100%;
    }

    .sorry_container
    {
        width: 80%;
        margin: 0 auto;
        margin-top: 90px;
        padding: 15px;
        border: 1px solid #dddddd;
        border-radius: 4px;
        overflow: auto;
    }

    .surveyOptionRow
    {
        width: 100%;
        float: left;
        /*text-align: center;*/
    }

    .surveyOptionRow .checkbox
    {
        float: left;
        width: 20%;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 10px;
    }

    .survey_other
    {
        float: center;
    }

    .survey_location
    {        
        float: center;
    }
    .survey_tenant_name
    {
        float: center;
    }
    #surveySlider .ui-slider-handle
    {
        background: url(../../assets/images/handle.png) no-repeat;
        width: 35px;
        height: 35px;
        border: none;
        margin-top: -7px;
        background-size: 35px;
        cursor: pointer;
    }
    .feedbackStatus
    {
        font-size: 10px;
        margin-top: 9px;
        float: left;
    }

    .removeOpacity
    {
        opacity: 1 !important;
    }

    .manage_unit_selected
    {
        font-size: 11px;
        color: grey;
    }

    .survey_label
    {
        background: url(../../assets/images/survey.png) no-repeat;
    }

    .survey_label
    {
        display: inline-block;
        width: 12%;
        height: 105px;
        padding: 0;
        margin: 2px;
        vertical-align: middle;
        text-align: center;
        cursor: default;
        text-shadow: 0px 1px #ffffff;
        font-weight: normal !important;
    }

    .survey_label.walk
    {
        background-position: 49% -75px;
    }

    .survey_label.bus
    {
        background-position: 64% -1852px;
    }

    .survey_label.light_rail
    {
        background-position: 70% -1451px;
    }

    .survey_label.heavy_rail
    {
        background-position: 67% -651px;
    }
    .survey_label.motorcycle
    {
        background-position: 70% -1713px;
    }
    .survey_label.car
    {
        background-position: 75% -1980px;
    }
    .survey_label.car23
    {
        background-position: 75% -1580px;
    }
    .survey_label.cars4
    {
        background-position: 70% -1182px;
    }

    .survey_label.extra_height
    {
        display: inline-block;
        width: 12%;
        height: 125px;
        padding: 0;
        margin: 2px;
        vertical-align: middle;
        text-align: center;
        cursor: default;
        text-shadow: 0px 1px #ffffff;
        font-weight: normal !important;
    }

    .survey_label.walk.extra_height
    {
        background-position: 49% -55px !important;
    }

    .survey_label.bus.extra_height
    {
        background-position: 64% -1832px !important;
    }

    .survey_label.light_rail.extra_height
    {
        background-position: 70% -1431px !important;
    }

    .survey_label.heavy_rail.extra_height
    {
        background-position: 67% -631px !important;
    }
    .survey_label.motorcycle.extra_height
    {
        background-position: 70% -1693px !important;
    }
    .survey_label.car.extra_height
    {
        background-position: 75% -1960px !important;
    }
    .survey_label.car23.extra_height
    {
        background-position: 75% -1560px !important;
    }
    .survey_label.cars4.extra_height
    {
        background-position: 70% -1162px !important;
    }

    .routeTypes_mobile
    {
        
        width: 100%;
    }
    .surveyHeading_mobile
    {
        width: 70%;      
    }
    .survey_fontsize_mobile 
    {        
        width: 20%;
        height: 80px;
        text-align: top;           
        font-size: 14px;
    }

    .survey_fontsize_mobiles
    {        
        text-align: center;
    }

    .unit_dropdown_mobile
    {
        float: right;
        width: 20%;
        padding-left: 25px;
    }

    .addRoute_mobile
    {        
        float: left;
        width: 100%;
        padding-left: 25%;        
    }

    .addRouteLabel_mobile
    {
        background: url(../images/add-route.png) no-repeat;
        padding-left: 40px;
        padding-top: 4px;
        padding-bottom: 10px;
        cursor: pointer;
        float: left;
    }

    .sliderLabelFirst {
        font-weight: 500;
        width: 15%;
        float: left;
        text-align: left;
    }

    .sliderLabelFirst_mobile {
        font-weight: 500;
        font-size: 10px;
        width: 5%;
        float: left;
        text-align: left;
    }

    .sliderLabelSecond {
        font-weight: 500;
        width: 15%;
        float: right;
        margin: 2px;
        margin-right: 0px;
        text-align: right;
    }

    #surveySliderMobile
    {
        display: inline-block; 
        -webkit-appearance: none !important;
        appearance: none !important;
        -moz-appearance: none !important;
        -ms-user-select: none !important;
        user-select: none !important;
        width: 70%;
        height: 10px;
        margin: auto;
    }

    .sliderLabelSecond_mobile
    {
        position: relative;
        margin-right: 20px;
        font-weight: 500;
        font-size: 10px;
        width:5%;
        float:right;
        text-align: right;
    }

    .surveyOptionRow_mobile
    {
        width: 100%;
        float: left;        
    }

    .surveyOptionRow_mobile .checkbox
    {
        float: left;
        width: 50%;
        margin-top: 20px;        
    }

    .survey_other_mobile
    {
        margin: 0 auto;
        width: 70%;
        font-size: 12px;
    }

    .survey_location_mobile
    {
        margin: 0 auto;
        width: 70%;
        font-size: 12px;
    }

    .survey_label_mobile
    {    
        height: 50px;    
        width: 80px;      
        vertical-align: bottom;     
    }

    .survey_label_mobile.walk
    {
        background: url(../../assets/images/walk.png) no-repeat;
        
    }

    .survey_label_mobile.bus
    {
        background-position: 55% -1852px;
        background: url(../../assets/images/bus.png) no-repeat;
    }

    .survey_label_mobile.light_rail
    {
        background-position: 57% -1451px;
        background: url(../../assets/images/light_rail.png) no-repeat;
    }

    .survey_label_mobile.heavy_rail
    {
        background-position: 58% -651px;
        background: url(../../assets/images/heavy_rail.png) no-repeat;
    }

    .survey_label_mobile.motorcycle
    {
        background-position: 57% -1713px;
        background: url(../../assets/images/motorcycle.png) no-repeat;
    }

    .survey_label_mobile.car
    {
        background-position: 56% -1980px;
        background: url(../../assets/images/car.png) no-repeat;
    }

    .survey_label_mobile.car23
    {
        background-position: 56% -1580px;
        background: url(../../assets/images/car23.png) no-repeat;
    }

    .survey_label_mobile.cars4
    {
        background-position: 57% -1182px;
        background: url(../../assets/images/cars4.png) no-repeat;
    }

    .routeLabel_mobile
    {          
        display: inline;
        float: left;
        width: 55%;
        background: url(../images/route-label.png) no-repeat;
        height: 40px;
        color: white;
        padding: 8px 34px;
        font-weight: 100;
        position:absolute;    
        background-position: 22px -2px;
        margin-top: -3px;
        background-size: 100px;
    }

    .mood {
      text-align: center;
      height: 170px;
      width: 100%;
      float: left;
    }

    /* line 1341, ../scss/main.scss */
    .mood .labels {
      position: relative;
      font-size: 12px;
      top: 30px;
      left: 50%;
      text-align: center;
    }

    /* line 1349, ../scss/main.scss */
    .mood .low {
      position: absolute;
      margin-left: -389px;
    }
    /* line 1354, ../scss/main.scss */
    .mood .high {
      position: absolute;
      margin-left: 333px;
    }
    /* line 1359, ../scss/main.scss */
    .mood .smiley {
      position: relative;
      left: 50%;
      margin-left: -31px;
      margin-top: 70px;
      height: 85px;
      width: 85px;
      background: url("../images/smilies.png") -500px 0px no-repeat;
    }

    .animationButton {
        width: 15%;
        border-radius: 0px 4px 4px 0px;
    }

    #project-info {
        font-family: 'Montserrat';
    }

/* Survey page ends; */

/* Survey page for v4.1  */

    .v2--surveyPage
    {
        overflow: auto;
        width: 50%;
        height: auto;
        min-height: 600px;
        padding: 10px 10px 70px 10px;
        box-shadow: 0 0 0px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
    }

    .v2--surveyPage--heading
    {
        /*        
        letter-spacing: normal;
        .945-park-ave-new-yo {*/
        height: 30px;   
        /*width: 640px;*/
        color: #28323B;
        margin-top: 12px;
        /*margin-bottom: 10px;*/
        font-family: "America";
        font-size: 16px;
        line-height: 30px;
        text-align: center;
        margin-left: 10px;
    }

    .v2--border--line
    {
        margin-bottom: 10px;
        box-sizing: border-box;
        height: 0.2%;
        width: 100%;
        border: 1px solid #E8E9ED;
    }

    .languageSelection
    {
        float: right;
        font-weight: 400 !important; 
        font-size: 13px;
        font-style: "America";
        margin-top: 7px;
        margin-right: 25px;
        min-width: 150px;
        text-align: left;
        /*padding: 2px 2px 2px 2px;*/
        cursor: pointer;
    }

    .languageLabel{

        float: right;
        margin-top: 9px;
        font-weight: 400 !important; 
        font-size: 14px;     
    }

    .v2--surveyPage--transportMessage
    {
        text-align: left !important;
        line-height: 30px;
        padding-left: 10px;
        letter-spacing: normal;
        font-style: "America";
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
    }

    .v2--box_travel {
        box-sizing: border-box;
        width: 462px;
        min-height: 130px;
        height: auto;
        border: 1px solid #DDDDDD;
        border-radius: 2px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.12);
        overflow:auto;
    }

    
    .v2--surveyHighlight 
    {
        text-align: left !important;
        line-height: 30px;
        padding-left: 10px;
        letter-spacing: normal;
        font-family: "America";
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        /*text-align: left;
        color: #39B44A;
        font-weight: 100;
        font-size: 15px;
        margin-top: 10px;
        -webkit-font-smoothing: antialiased;*/
    }

    .v2--routeLabel
    {
        float: left;
        height: 24px;   
        /*width: 52px; */ 
        /*margin-left: 33px; */ 
        margin-top: 10px;
        margin-left: 15px;
        color: #28323B; 
        font-family: "AmericaMedium";   
        font-size: 14px;    
        font-weight: 500;   
        line-height: 24px;
    }

    .v2--selectTravelMethod
    {
        height: 25px;   
        width: 186px;   
        color: #28323B; 
        font-family: "AmericaMedium";  
        font-weight: 500; 
        font-size: 14px;    
        line-height: 24px;
    }

    .v2--survey_input
    {
        width: 50% !important;
        margin: 0 auto;
    }

    .v2--addRouteLabel
    {        
        margin-top: 5%;
        cursor: pointer;
    }

    .sliderLabel--mood
    {
        height: 24px;
        width: 560px;
        color: #28323B;
        font-family: "America";
        font-size: 18px;
        line-height: 24px;
        text-align: center;
    }

    .sorry
    {
        width: 100%;
        float: left;
        background-size: 100% 100%;
    }

    /* .happy
    {
        width: 100%;
        float: left;
    } */

    .surveyOptionRow .checkbox
    {
        float: left;
        width: 20%;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 10px;
    }


    .unit_dropdown_mobile
    {
        float: right;
        width: 20%;
        padding-left: 25px;
    }

    .sliderLabelFirst {
        font-weight: 500;
        width: 15%;
        float: left;
        text-align: left;
    }

    .sliderLabelSecond {
        font-weight: 500;
        width: 15%;
        float: right;
        margin: 2px;
        text-align: right;
    }

    .project-modal .modal-content{
        border-radius: 0px 0px 8px 8px;
    }

    .project-modal .modal-header{ 
        background: #333;
    }
    .travel-modal .modal-header
    {
        background: none;
    }

    .walkIcon,
    .telecomuteIcon,
    .bikeIcon,
    .busIcon,
    .carIcon,
    .carpoolIcon,
    .motorcycleIcon,
    .tramIcon,
    .railIcon,
    .altCarIcon
    {    
        margin-top: 12px;
        /*background-size: 26px;*/
        background-size: 18px;
        height: 28px;
        width: 32px;
        float: left;
    }

    .walkIcon
    {
        background: url(../../assets/images/walk_icon.svg) no-repeat;
    }

    .telecomuteIcon
    {
        background: url(../../assets/images/telecomute_icon.svg) no-repeat;
    }

    .bikeIcon{
        background: url(../../assets/images/bike_icon.svg) no-repeat;        
    }
    .busIcon{
        background: url(../../assets/images/bus.svg) no-repeat;        
    }
    .carIcon{
        background: url(../../assets/images/car_icon.svg) no-repeat;        
    }
    .carpoolIcon{
        background: url(../../assets/images/carpool_icon.svg) no-repeat;        
    }
    .motorcycleIcon{
        background: url(../../assets/images/motorcycle_icon.svg) no-repeat;        
    }
    .tramIcon{
        background: url(../../assets/images/tram_icon.svg) no-repeat;        
    }
    .railIcon{
        background: url(../../assets/images/rail_icon.svg) no-repeat;        
    }
    .altCarIcon{
        background: url(../../assets/images/alt_car_icon.svg) no-repeat;        
    }

    #v2--surveySlider
    {
        -webkit-appearance: none;
        appearance: none;
        width: 80%;
        height: 6px;
        border-radius: 5px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
        margin-top: 30px;
        margin: auto;  
    }

    #v2--surveySlider .ui-slider-handle
    {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #000000;
        cursor: pointer;
    }

    #mouth-box {
      width: 60px; 
      height: 20px; 
      left: 20px; 
      top: 30px; 
      overflow: hidden; 
      background: white; 
      position: relative; 
    }

    #mouth { 
      width: 60px; 
      height: 60px; 
      border-radius: 30px; 
      border: 2px solid orange; 
      position: absolute; 
      top: 0; 
      left: 0; 
    }

    #mouth.straight {
      height: 0px !important;
      top: 7px !important;
      border-width: 1px;
      bottom: auto !important;
    }

    .satisfaction_plus3,
    .satisfaction_plus2,
    .satisfaction_plus1,
    .satisfaction_0,
    .satisfaction_minus1,
    .satisfaction_minus2,
    .satisfaction_minus3
    {
        /* background-size: 20%; */
        /* margin-left: 40%; */
        padding-top: 120px;
        background-position-x: 50% !important;
        /* margin-right: 40%; */
        /* height: 100%; */
        /* width: 69%; */
    }

    .satisfaction_plus3
    {
        background: url(../../assets/images/Satisfaction_Plus3.svg) no-repeat;        
    }
    .satisfaction_plus2
    {
        background: url(../../assets/images/Satisfaction_Plus2.svg) no-repeat; 
    }
    .satisfaction_plus1
    {
        background: url(../../assets/images/Satisfaction_Plus1.svg) no-repeat;        
    }
    
    .satisfaction_0
    {
        background: url(../../assets/images/Satisfaction_0.svg) no-repeat;        
    }
    
    .satisfaction_minus1
    {
        background: url(../../assets/images/Satisfaction_Minus1.svg) no-repeat;        
    }
    .satisfaction_minus2
    {
        background: url(../../assets/images/Satisfaction_Minus2.svg) no-repeat;        
    }
    .satisfaction_minus3
    {
        background: url(../../assets/images/Satisfaction_Minus3.svg) no-repeat;        
    }

    .compliments--label
    {
        color: #28323B; 
        font-family: 'AmericaMedium'; 
        font-size: 14px; 
        font-weight: 500; 
    }

    .buildings_green
    {
        background: url(../../assets/images/buildings_green.svg) no-repeat;
        background-size: 20%;
        /* margin-left: 40%; */
        /* margin-right: 40%; */
        padding-top: 104px;
        /* height: 100%; */
        /* width: 100%; */
        background-position-x: 50% !important;
    }

    

/* Survey page for v4.1 ends; */



.history_table th
{
    font-weight: 500;
    font-family: 'AmericaMedium';
    line-height: 22px;
    color: #28323B;
}
.history_table td
{
    padding: 13px 0px;
    border-bottom: 1px solid #E9EBED;
    vertical-align: middle;
}
.history_table tr
{
    padding: 13px 0px;
}
.initials
{
    font-weight: 500;
    font-family: 'AmericaMedium';
    font-size: 15px;
    line-height: 16px;
    color: #FFFFFF;
    height: 32px;
    width: 32px;
    padding: 8.5px 7px;
    border-radius: 16px;
    background-color: #7D8691;
    margin-right: 8px;
}
.history_table .info
{
    font-size: 15px;
    line-height: 24px;
    padding-top: 5px;
}
/* .history_table .project
{
    color: #1775D2;
    cursor: pointer;
}
.history_table .project:hover
{
    text-decoration: underline;
} */
.blank_port_option
{
    border: 1px solid #DDDDDD;
    padding: 24px;
    margin-right: 15px;
    width: 350px;
}