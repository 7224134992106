/* New Payment Registration UI Starts*/
@font-face 
{
    font-family: America;
    src: url(../../assets/fonts/GT-America-Standard-Regular.ttf);
}
.payment-card
{
    background-color: white;
    padding: 25px 40px;
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.26);
}
.arc-logo
{
    width: 50px;
    height: 50px;
    background-color: #eee;
    border-radius: 25px;
}
.card-header
{
    font-family: "America";
    color: black;
}
.grey
{
    color: #707479;
}
.table-margin-20
{
    border-bottom: 20px solid white;
}
.table-margin-right-10
{
    border-right: 10px solid white;
    vertical-align: top;
}
.payment-option:hover
{
    background-color: #46739E;
    color: white;
    border: 2px solid #46739E;
    border-radius: 3px;
}

.payment-option
{
    border-radius: 3px;
    padding: 20px;
    cursor: pointer;
    border: 1px solid #D9D9D9;
}

.payment-option.active
{
    background-color: #46739E;
    color: white;
    border: 2px solid #46739E;
    border-radius: 3px;
}
.payment-option h2, .payment-option p
{
    margin: 0px !important;
    font-weight: 600;
    text-align: center;
}
.payment-header
{
    margin-top: 5px;
    font-weight: 600;
    color: black;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0px;
}
.payment-sub-header.green
{
    font-weight: 600;
    color: rgb(163, 212, 165) !important;
}
.payment-sub-header.cancel
{
    text-decoration: line-through;
}
.payment-sub-header
{
    font-size: 13px;
    color: #707479;
    margin-bottom: 0px;
}
.best_value_label
{
    background-color: rgb(224,241,224);
    padding: 4px 6px;
    border-radius: 3px;
}
.payment_option.left
{
    border-radius: 3px 0px 0px 3px;
}
.payment_option.right
{
    border-radius: 0px 3px 3px 0px;
}
.payment_option.active
{
    background-color: #46739E;
    color: white;
    border-color: #46739E;
}
.payment_option
{
    padding: 10px 20px;
    border: 1px solid lightgrey;
    color: black;
    font-weight: 500;
}

.payment-new-content
{
    margin: 0px 25px 25px 38px;
    width: 72%;
    margin-top: 25px;
}
.payment-new-content .header
{
    font-weight: 600;
    color: #707479;
    margin-bottom: 12px;
    font-size: 20px;
}
.payment-new-full-screen
{
    padding: 25px 135px;
}
.payment-new-full-screen .title-bar
{
    font-weight: 400;
}
.payment_mode_option 
{
    padding: 10px 20px;
    cursor: pointer;
}
.payment_mode
{
    border-radius: 3px;
    display: flex;
    font-size: 15px;
    border: 1px solid #949EAB;
}
.payment_mode_option.active, .payment_mode_option:hover
{
    background-color: #46739E;
    color: white;
}
.pb0
{
    padding-bottom: 0px !important;
}

/* New Payment Registration UI Ends*/

/* New Payment Details UI Starts */
.tab-header div
{
    margin-right: 10px;
    width: 50%;
    padding-bottom: 10px;
    border-bottom: 8px solid #EEEEEE;
}
.tab-header
{
    margin-bottom: 10px;
    display: flex;
    font-weight: 600;
    color: #707479;
    cursor: pointer;
    /* width: 72%; */
    font-size: 16px;
}
.tab-header div.active
{
    color: black;
    border-bottom: 8px solid black;
}
#payment-details #details-form
{
    padding-top: 18px;
}
#details-form .group .error
{
    color: #dd5826;
    font-size: 14px;
    font-family: AmericaMedium;
    font-weight: 500;
    margin-top: 5px;
    display: none;
}
#details-form .group.has-error input, #details-form .group.has-error select
{
    border-bottom: 2px solid #dd5826
}
#details-form .group.has-error .bar:before, #details-form .group.has-error .bar:after
{
    background: #dd5826;
}
#details-form .group.has-error .error
{
    display: block;
}
#details-form .group 
{
    position:relative;
    padding-bottom: 40px;
    font-weight: 400;
    color: black;
}
#details-form select
{
    padding: 10px 10px 8px 0px;
    display:block;
    width:100%;
    border:none;
    border-bottom:2px solid #CACFD5;
    outline: none;
    font-size: 16px;
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    -webkit-border-radius: 0px; 
    -moz-border-radius: 0px; 
    border-radius: 0px; 
}
#details-form input
{
    font-size: 16px;
    padding:10px 10px 10px 0px;
    display:block;
    width:100%;
    border:none;
    border-bottom:2px solid #CACFD5;
    box-shadow: none;
    z-index: 1;
}
#details-form input:focus 
{
    outline: none !important;
    border: none !important;
}
#details-form .group label 
{
    font-size: 16px;
    color: #707479;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left: 0px;
    top:10px;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
}
#details-form input:focus ~ label, #details-form input:valid ~ label, #details-form .select-group label,  #details-form input.occ_valid ~ label
{
    top:-10px;
    font-size: 12px;
    color: #707479;
}
.project_create #details-form .select-group label, .portfolio_create #details-form .select-group label
{
    top: 10px;
    font-size: 16px;
}
#details-form .select-group label.selected_val
{
    top:-10px !important;
    font-size: 12px !important;
}
#payment-details #details-form table
{
    width: 46%;
}
#details-form .bar 
{
    position:relative;
    display:block;
    width:100%;
}
#details-form .bar:before, #details-form .bar:after 
{
    content:'';
    height:2px;
    width:0;
    bottom:1px;
    position:absolute;
    background:#949EAB;
    transition:0.2s ease all;
    -moz-transition:0.2s ease all;
    -webkit-transition:0.2s ease all;
}
#details-form .bar:before 
{
    left:50%;
}
#details-form .bar:after 
{
    right:50%;
}
#details-form input:focus ~ .bar:before, #details-form input:focus ~ .bar:after 
{
    width:50%;
}
#details-form .highlight 
{
    position:absolute;
    height:60%;
    width:100px;
    top:25%;
    left:0;
    pointer-events:none;
    opacity:0.5;
}
#details-form .checkbox input
{
    top: -10px;
}
#details-form .btn-primary span
{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
#details-form .btn-primary
{
    /* background-color: #1E88E5; */
    border-color: #1E88E5;
    font-family: AmericaMedium;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
}
.label_location_help
{
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 20px;
}
.label_location
{
    position: relative;
    top: -8px;
}
.geolocation-picker
{
    width: 49%;
    margin-left: 50px;
    padding: 25px 30px;
    border: 1px solid #DDDDDD;
    height: 100%;
}
/* New Payment Details UI Ends */

/* Payment options starts */

#payment-options-form .btn-primary
{
    color: white;
    background-color: #4A90E2;
    border: 2px solid #4A90E2;
    width: 117px;
}
#payment-options-form .btn-primary:hover
{
    color: #4A90E2;
    background-color: white;
}
#payment-options-form .btn-success span, #payment-options-form .btn-primary span
{
    font-size: 16px;
    line-height: 24px;
}
#payment-options-form .btn-success
{
    background-color: white;
    color: #50AE54;
    border: 2px solid #50AE54;
    /*width: 220px;*/
}
#payment-options-form .btn-success:hover
{
    background-color: #50AE54;
    color: white;
}
#payment-options-form
{
    display: flex;
    /* width: 72%; */
}
.full-access .icon-shopping-cart
{
    color: #4A90E2;
    font-size: 24px;
    padding: 10px;
    border-radius: 24px;
    border: 1px solid;
}
.limited-access
{
    border-right: 1px solid #DDDDDD;
    margin-right: 10px;
}
.limited-access .icon-calendar
{
    color: #50AE54;
    font-size: 24px;
    padding: 10px;
    border-radius: 24px;
    border: 1px solid;
}
.limited-access, .full-access
{
    width: 50%;
    padding: 25px 10px;
    text-align: center;
}
.arc-list.green .list-dot
{
    background-color: #50AE54;
}
.arc-list.blue .list-dot
{
    background-color: #4A90E2;
}
.arc-list .list-dot
{
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-top: 6px;
    margin-right: 10px;
}
.arc-list .arc-list-item
{
    margin-bottom: 10px;
    display: flex;
    text-align: left;
    /* justify-content: center; */
}

/* Payment options Ends */

.mb50
{
    margin-bottom: 50px;
}
.mb16
{
    margin-bottom: 16px;
}
.arc-list
{
    padding-left: 20px;
}
.error-lg-msg
{
    font-size: 24px;
}
.error_symbol_trial
{
    background-size: 35px;
    width: 35px;
    height: 35px;
    background-position: -5px;
    background: url(../../assets/images/alert_circle_icon.svg) no-repeat;
}
#paynow_trial
{
    font-weight: 600;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    border-color: #4A90E2;
    background-color: #4A90E2;
    padding: 6px 25px;
    border-radius: 3px;

}
.fontSize15
{
    font-size: 15px;
}
.payment_info
{
    height: fit-content;
    margin-top: 65px;
    padding: 19px 24px;
    width: 21%;
} 
.payment_info .access 
{	
    height: 20px;	
    width: 203px;	
    color: #707479;	
    font-size: 14px;	
    font-weight: 600;	
    line-height: 20px;
}
.access-fee-includes {
	color: #28323B;
	font-size: 14px;
    line-height: 22px;
    font-weight: 400;
}
.grid
{
    margin: 10px;
    padding: 0px;
    width: 15%;
}
.question_help
{
    font-size: 25px !important;
    cursor: pointer;
    float: left;
    padding-top: 13px;
    color: #F2BE34;
}

#arc_help .arch
{
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    display: block;
}
#arc_help .arcp
{
    font-size: 15px;
    line-height: 30px;
    display: block;
    font-weight: 400;
}
#arc_help .cancel
{
    cursor: pointer;
    font-size: 22px;
}
#arc_help .title
{
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
}
#arc_help .header_container
{
    background-color: #F8F8F8;
    padding: 20px 35px;
    display: flex;
    justify-content: space-between;
}
.select-group .fa-caret-down
{
    position: absolute;
    top: 13px;
    right: 10px;
    color: #949EAB;
    font-size: 17px;
    pointer-events: none;
}
.remove_promo
{
    color: #949EAB;
    position: relative;
    font-size: 20px;
    padding-top: 11px;
    left: 10px;
}

.radio_buttons
{
  font-weight: 600;
}

.checkcontainer 
{
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.checkcontainer input 
{
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkmark 
{
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}
.checkcontainer:hover input ~ .checkmark 
{
    background-color: #ccc;
}
.checkcontainer input:checked ~ .checkmark 
{
    background-color: #4A90E2;
}
.checkmark:after 
{
    content: "";
    position: absolute;
    display: none;
}
.checkcontainer input:checked ~ .checkmark:after 
{
    display: block;
}
.checkcontainer .checkmark:after
{
    left: 10px;
    top: 6px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.radiobtn 
{
    position: absolute;
    top: 1px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #BAC1CA;
    border-radius: 50%;
}
.checkcontainer:hover input ~ .radiobtn 
{
    background-color: #ccc;
}
.checkcontainer input:checked ~ .radiobtn, .bgblue
{
    background-color: #4A90E2;
}
.radiobtn:after 
{
    content: "";
    position: absolute;
    display: none;
}
.radiobtn:after
{
    display: block;
}  
.checkcontainer input:checked ~ .radiobtn:after 
{
    top: 6px;
    left: 6px;
    width: 6px;
    height: 6px;
    border-radius: 57%;
    background: white;
}
.checkcontainer .radiobtn:after 
{
    top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 57%;
    background: white;
}
#portfolio_text
{
    height: 220px;
    resize: none;
    border: 2px solid rgb(202, 207, 213);
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    padding: 10px 15px;
}
.projectRegistrationNew
{
    padding: 64px 0px 0px 0px !important;
    top: 0px !important;
}
.arc_pro_purchase, .payment_success, .payment_cancel, .portfolio_myprojects_list 
{
    background-color: #F4F5F8 !important;
    border: none !important;
    padding: 64px 0px 0px 0px !important;
    top: 0px !important;
}
.sign_up
{
    height: fit-content;
}
.billing-cycle .checkcontainer
{
    padding-left: 26px !important;
}
.discount
{
    color: #50AE54;
    font-size: 14px;
    padding-left: 26px;
    line-height: 16px;
    margin-top: 5px;
}
.price_val
{
    font-size: 16px;
    line-height: 24px;
    font-family: AmericaMedium;
}
.duration_val
{
    font-size: 12px;
    line-height: 16px;
}
.arc-pro-users th
{
    font-size: 14px !important;
    text-transform: initial !important;
}
.arc-pro-search
{
    font-size: 16px;
    line-height: 24px;
    padding: 8px 12px;
    border: 1px solid #BAC1CA;
    border-radius: 3px;
    width: 300px;
    margin-bottom: 5px;
}
.arc-pro-search-icon
{
    position: relative;
    right: 36px;
    top: 7px;
    fill: #68737D;
}
.user-list
{
    border: 1px solid #BAC1CA;
    width: 600px;
    background-color: white;
    position: absolute;
    padding: 10px 25px;
    height: 261px;
    overflow: auto;
    z-index: 2;
}
.user-item button:hover
{
    background-color: #1775D2;
    color: white;
}
.user-item
{
    display: flex;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #E9EBED;
    padding: 9px 0px;
    justify-content: space-between;
}
.add-user-button
{
    color: #1775D2;
    border: 1px solid #1775D2;
    box-shadow: none;
    background-color: white;
    line-height: 25px;
    padding: 1px 15px;
    font-family: AmericaMedium;
    border-radius: 3px;
    font-size: 14px;
}
.sign_up_info
{
    padding: 24px;
    width: 32%;
    height: fit-content;
}
.underline
{
    text-decoration: underline;
}