.options_header .option_selection.active, .options_header .option_selection:hover
{
    color: #28323B;
}
.options_header .option_selection
{
    margin-right: 40px;
    line-height: 30px;
    padding: 16px 0px;
    color: #68737D;
    cursor: pointer;
}
.options_header svg
{
    position: relative;
    top: 2px;
    fill: #68737D;
    margin-right: 5px;
}
.options_header .active svg, .options_header svg:hover
{
    fill: #28323B;
}
.options_header
{
    display: flex;
    font-size: 16px;
    font-family: AmericaMedium;
    font-weight: 500;
    color: #68737D;
}
.dropdown .fa-angle-down
{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    color: #68737D;
}
.dropdown-toggle-it, .add-dropdown-toggle .dropdown-toggle
{
    width: 100%;
    text-align: left;
    border-radius: 2px;
    height: 40px;
    padding: 0px 10px;
    background-color: white;
    white-space: normal;
    font-size: 15px;
    border: 1px solid #BAC2CD;
}
.dropdown-menu
{
    left: initial;
    top: -3px;
    border: none;
    border-radius: 0px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.23);
}
.dropdown-menu>li>a
{
    padding: 10px 15px;
}
.input_param_header_project
{
    background-color: white;
    /* display: flex;
    height: 64px; */
    padding: 12px;
}
.input_param_header
{
    padding: 12px 24px;
    display: flex;
    width: 100%;
    background-color: white;
    z-index: 1;
}
.input_item .search-icon
{
    position: absolute;
    right: 10px;
    top: 10px;
    height: 20px;
    width: 20px;
    fill: #BAC1CA;
}
.input_item .circular-loader
{
    height: 15px;
    width: 15px;
    position: absolute;
    margin: 0px;
    right: 10px;
    top: 12px;
    left: initial;
}
.input_item
{
    width: 200px;
    margin-right: 12px;
}
.dropdown-menu.normal
{
    top: 38px;
    width: 100%;
}
.dropdown-menu-fixed
{
    max-height: 250px;
    overflow: auto;
}
.insight_input
{
    border: 1px solid #BAC2CD;
    border-radius: 2px;
    background-color: #FFFFFF;
    height: 40px;
    font-size: 15px;
    padding: 0px 10px;
    width: 100%;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 40px;
}
.world_map_wrapper
{
    width: 48%;
    float: left;
    /* position: fixed; */
    /* height: 662px; */
}
#world_map, #portfolio_map
{
    border-radius: 3px;
    border: 1px solid #ccc;
}
#world_map
{
    height: 100%;
    width: 100%;
}
.map_and_projects
{
    padding-left: 24px;
    padding-right: 24px;
    /* padding-top: 65px; */
}
.project_metrics
{
    width: 50%;
    float: right;
}
.project_metrics_header div.active, .project_metrics_header div:hover
{
    border-bottom: 2px solid #28323B;
    color: #28323B;
}
.project_metrics_header div
{
    font-family: AmericaMedium;
    color: #68737D;
    position: relative;
    font-size: 16px;
    top: 2px;
    padding-bottom: 9px;
    margin-right: 40px;
    line-height: 24px;
    cursor: pointer;
}
.project_metrics_header
{
    display: flex;
    border-bottom: 2px solid #DDDDDD;
}
.ui.label.Certified
{
    background-color: #a5ba48 !important;
}
.ui.label.Gold
{
    background-color: #d5ae00;
}
.ui.label.Silver
{
    background-color: #83bbc9;
}
.ui.label.Platinum
{
    background-color: #818183;
}
.cert_item
{
    padding: 14px 0px;
    border-bottom: 1px solid #D8DCDE;
    position: relative;
}
.abs_r
{
    position: absolute;
    top: 14px;
    right: 0px;
}
.grey_color_light
{
    color: #949EAB;
}
.grey_color
{
    color: #68737D;
}
.ui.label
{
    position: absolute;
    width: 5px;
    border-radius: 0px;
    height: 14px;
    display: block;
    top: 16px;
}
.projects_meta 
{
    margin-bottom: 16px;
    margin-top: 30px;
}
.arc-table-insight td
{
    padding: 15px 15px 15px 0px !important;
}
.arc-table-insight th
{
    text-transform: initial !important;
}
.arc-table-insight tbody
{
    font-size: 15px;
}
.arc-table-insight
{
    color: #28323B;
    font-size: 14px !important;
}
.ellipsis_insight
{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    width: 180px;
}
.ellipsis_120
{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    width: 120px;
}
.metric_info_header
{
    padding: 16px 0px;
    border-bottom: 1px solid #DDDDDD;
    font-size: 16px;
}
.metric_overview 
{
    display: flex;
    margin-top: 16px;
}
.metric_item
{
    border-right: 1px solid #E9EBED;
    width: 33%;
}
.metric_type
{
    font-size: 15px;
    font-weight: 500;
    font-family: AmericaMedium;
    line-height: 18px;
    margin-bottom: 22px;
}
.metric_value
{
    font-size: 30px;
    line-height: 32px;
    margin-bottom: 3px;

}
.metric_label
{
    color: #68737D;
    font-size: 14px;
    line-height: 24px;
}
#zepi_chart, #water_reduction_chart, #waste_diversion_chart
{
    width: 100%;
    height: 300px;
}
.ballon
{
    padding: 10px 15px;
    width: 220px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.27);
}
.ballon .heading
{
    font-size: 14px;
    font-weight: 500;
    font-family: 'AmericaMedium';
    line-height: 24px;
    color: #68737D;
    margin: 0px;
    text-align: left;
    padding-bottom: 8px;
}
.ballon .values
{
    padding-top: 8px;
    padding-bottom: 8px;
    border-top: 1px solid #E9EBED;
    display: flex;
    justify-content: space-between;
}
.ballon .chart_reading
{
    font-weight: 500;
    font-family: 'AmericaMedium';
}
.ballon .chart_reading, .ballon .chart_label
{
    color: #28323B;
    font-size: 14px;
}
.energy_chart
{
    margin-top: 30px;
    margin-bottom: 40px;
}
.zepi_labels
{
    width: 100%;
}
.zepi_gradient
{
    height: 8px;
    width: 100%;
    background: linear-gradient(90deg, #EF6B00 0%, #7CB341 100%);
    position: absolute;
}
.insights_mode_navbar
{
    z-index: 2;
    position: fixed;
}
tspan
{
    font-family: America;
}
.zepi_gradient_label
{
    color: #3D474F;
    font-size: 12px;
    position: absolute;
    padding-top: 10px;
}
.zepi_gradient_label p 
{
    margin-bottom: 0px;
}
.mt90
{
    margin-top: 90px;
}
.credit_row
{
    display: flex;
    justify-content: space-between;
    line-height: 24px;
    border-top: 1px solid #DDDDDD;
    padding: 8px 0px;
}
.credit_value
{
    display: flex;
}
.mini_progress_bar
{
    height: 10px;
    width: 100px;
    background-color: #E9EBED;
    margin: 7px 10px;
}
.mini_progress_bar .credits_achieved.WE
{
    height: inherit;
    background-color: #7DBBA6;
}
.mini_progress_bar .credits_achieved.SS
{
    height: inherit;
    background-color: #A8BF33;
}
.mini_progress_bar .credits_achieved.EA
{
    height: inherit;
    background-color: #F3B329;
}
.mini_progress_bar .credits_achieved.LT
{
    height: inherit;
    background-color: #3c5a70;
}
.mini_progress_bar .credits_achieved.MR
{
    height: inherit;
    background-color: #74a534;
}
.mini_progress_bar .credits_achieved.EQ
{
    height: inherit;
    background-color: #37a7cd;
}
#search_overview span
{
    padding-top: 3px;
}
#search_overview.btn-default svg
{
    fill: black;
}
#search_overview.btn-primary svg
{
    fill: white;
}
#search_overview svg
{
    width: 20px;
    height: 26px;
    margin-right: 5px;
}
.model_create_parent #content
{
    top: -1px !important;
}
.models_parent #content, .model_create_parent #content
{
    padding: 0px 0px 0px 0px;
}
.models_parent
{
    top: 135px;
}
.models_wrapper h3
{
    margin: 0px;
    line-height: 40px;
    padding: 24px;
}
.hoverable tbody tr:hover
{
    background-color: #eee !important;
}
.models_list_header div
{
    width: 20%;
    font-weight: 500;
    font-family: AmericaMedium;
    padding: 0px 24px 15px 24px;
    border-bottom: 1px solid #D8DCDE;
}
.models_list_header
{
    display: flex;
}
.models_list_row .data_item
{
    width: 20%;
    border-bottom: 1px solid #D8DCDE;
    padding: 20px 24px;
    font-size: 15px;
}
.models_list_row
{
    cursor: pointer;
    display: flex;
}
.version_list .models_list_header div
{
    width: 20%;
    font-size: 12px;
    font-weight: 500;
    font-family: AmericaMedium;
    color: #68737D;
    line-height: 24px;
    border-bottom: 1px solid #E9EBED;
}
.parent_model_list .version_list
{
    display: none;
}
.parent_model_list.show .models_list_row .data_item
{
    border-bottom: none;
}
.parent_model_list.show
{
    border-top: 16px solid #F4F5F8;
    border-bottom: 16px solid #F4F5F8;
    transition: border-width 0.1s linear;
}
.parent_model_list.show .version_list
{
    display: block;
}
.version_list .version_name
{
    color: #1775D2;
    font-size: 15px;
    line-height: 24px;
    padding: 16px 24px;
}
.model_create_nav_btn
{
    position: relative;
    top: -5px;
}
.model_create_nav
{
    box-shadow: 2px 0 4px 0 rgba(0,0,0,0.26);
    padding: 20px 0px;
    position: fixed;
    width: 100%;
    left: 0px;
    background-color: white;
    top: 0px;
    z-index: 2;
    height: 64px;
    display: flex;
    justify-content: space-evenly;
}
.progress_create
{
    display: flex;
    justify-content: space-around;
    width: 75%;
}
.step_name
{
    font-size: 16px;
    font-family: AmericaMedium;
    line-height: 24px;
    color: #68737D;
}
.step_val
{
    width: 24px;
    height: 24px;
    color: white;
    font-size: 16px;
    background-color:#68737D;
    border-radius: 12px;
    padding: 1px 8px;
    margin-right: 8px;
}
.step.active .step_name
{
    color: #28323B;
}
.step.active .step_val
{
    background-color: #1E88E5;
}
.form_wrapper
{
    padding-top: 64px;
    padding-left: 30px;
}
#model_details
{
    width: 70% !important;
}
.scorecard_icon
{
    width: 53px !important;
    height: 53px !important;
    position: absolute;
    right: -30px;
    top: -15px;
}
.credit_icon
{
    position: relative;
}
.credit_name_header .fa-caret-down
{
    padding-left: 7px;
    margin-right: 20px;
}
.credit_name
{
    font-size: 16px;
    font-family: AmericaMedium;
    line-height: 24px;
}
.credit_name_header
{
    padding: 12px;
    border-radius: 3px;
    background-color: #EDEDED;
    display: flex;
    justify-content: space-between;
}
.credit_points
{
    margin-right: 50px;
    padding-top: 3px;
    font-family: AmericaMedium;
}
#credit_items .dropdown-toggle-it
{
    height: 30px;
}
#credit_items .dropdown .fa-angle-down
{
    right: 7px;
    top: 8px;
    font-size: 15px;
}
#credit_items .dropdown
{
    width: 64px;
    height: 30px;
    margin: 10px 0px;
}
#credit_items thead th
{
    padding: 13px 0px;
    font-size: 14px;
}
#credit_items tr
{
    border-bottom: 1px solid #EDEDED;
    line-height: 24px;
}
.summary_box
{
    padding: 24px;
    border: 1px solid #E1E1E1;
    width: 398px;
    height: fit-content;
}
.model_strategies#content, .model_reduction#content
{
    top: -6px !important;
    padding: 80px 0px 0px 0px !important;
}
.summary_row
{
    font-size: 18px;
    line-height: 32px;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #D8DCDE;
}
.model_name_nav
{
    top: 120px !important;
    border-top: 1px solid #DDDDDD !important;
}
.project_reduction#content, .project_strategies#content, .project_comparison#content
{
    top: 178px !important;
    background-color: #F4F5F8 !important;
}
.manage_model .meterNav
{
    width: 97%;
}
.manage_model .insight_widget
{
    float: left;
    width: 100%;
    margin-top: 0px;
    padding-top: 0px;
    box-shadow: none;
}
.project_zepi_score .insight_widget, .project_team .insight_widget
{
    box-shadow: none;
}
.project_zepi_score#content, .manage_model#content, .project_team#content
{
    top: 178px !important;
    background-color: white;
    border-left: 1px solid #DDDDDD;
}
.insight_widget
{
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.26);
    background-color: white;
}
.insight_project_reduction
{
    padding: 25px 32px;
    margin-top: 16px;
}
.legend_wrapper
{
    display: flex;
    padding-bottom: 15px;
}
.legend_wrapper > div
{
    margin-right: 32px;
    position: relative;
    line-height: 24px;
}
.estimated_legend .small_sprite
{
    background-color: #28323B;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin: 3px;
}
.range_legend .sprite
{
    width: 30px;
    height: 20px;
    background-color: #EBEDED;
    position: absolute;
}
.average_legend .sprite
{
    height: 13px;
    width: 13px;
    background-color: #00A5C8;
    border-radius: 7px;
    position: absolute;
    top: 5px;
}
.estimated_legend .sprite
{
    border: 2px solid #28323B;
    height: 22px;
    width: 22px;
    position: absolute;
    top: 1px;
    border-radius: 11px;
}
.range_legend span
{
    padding-left: 40px;
}
.average_legend span
{
    padding-left: 23px;
}
.estimated_legend span
{
    padding-left: 30px;
}
.reduction_category
{
    padding: 40px 0px;
    border-top: 1px solid #E9EBED;
    display: flex;
    justify-content: space-between;
}
.reduction_category .chart
{
    width: 70%;
	height: 80px;
}
.p80_0px
{
    padding: 80px 0px;
}
#strategies_project_table td img
{
    position: relative;
    top: -3px;
    margin-right: 16px;
}
#strategies_project_table .points_td
{
    border-right: 1px solid #E9EBED;
    border-left: 1px solid #E9EBED;
    text-align: center;
}
#strategies_project_table .chart
{
    height: 80px;
    width: 100%;
    left: 10px;
    position: relative;
}
#strategies_project_table td
{
    padding: 40px 0px !important;
}
.comparison_project
{
    padding: 0px 15px;
    border-left: 1px solid #EDEDED;
    width: 22%;
}
.w34p
{
    width: 34%;
}
.insight_widget #details-form .group
{
    top: 15px !important;
}
.water_plq_icon_circle
{
    background-image: url("../../assets/images/icons/water_plq_icon_circle.svg");
    width: 28px;
    height: 28px;
    background-size: 100% 100%;
}
.waste_plq_icon_circle
{
    background-image: url("../../assets/images/icons/waste_plq_icon_circle.svg");
    width: 28px;
    height: 28px;
    background-size: 100% 100%;
}
.energy_plq_icon_circle
{
    background-image: url("../../assets/images/icons/energy_plq_icon_circle.svg");
    width: 28px;
    height: 28px;
    background-size: 100% 100%;
}
#comparison_strategies td, #comparison_performance td
{
    border-top: none !important;
}

/* duplicate with arc */
.dropdown-clean, .dropdown-clean:hover {
    border: none;
    background-color: white;
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    padding: 0px;
    padding-bottom: 3px;
    font-family: 'AmericaMedium';
}
#content.insights_mode
{
    top: -1px;
}
/* #sidebar
{
    z-index: 2;
    top: 166px;
} */
.zepi_bottom_line
{
    border-bottom: 1px solid #EDEDED;
}
.manage_model .summary_box
{
    right: 32px;
    position: absolute;
}
.model_template_content
{
    padding-left: 30px;
    padding-right: 30px;
}
.manage_model .model_template_label
{
    margin-top: 0px;
}
.manage_model .model_template_content
{
    padding-left: 0px;
    padding-right: 0px;
}
.manage_model .save_project_button, .manage_model .model_create_nav, .manage_model .model_template_heading
{
    display: none;
}
.ml32
{
    margin-left: 32px;
}
.pl32
{
    padding-left: 32px;
}
.pr32
{
    padding-right: 32px;
}
.model_options
{
    height: 100% !important;
}
.stick_sidebar
{
    position: fixed !important;
    top: 110px !important;
}
.stick_nav
{
    position: fixed !important;
    top: 52px !important;
}
.init_text_transform
{
    text-transform: initial !important;
}
.pt0
{
    padding-top: 0px !important;
}
.insight_table td
{
    line-height: 24px !important;
    padding: 12px 0px !important;
}
.insight_table button
{
    color: #28323B !important;
}
.position-initial
{
    position: initial !important;
}
/* #content
{
    z-index: 3;
} */
.blue-color-1775D2
{
    color: #1775D2;
}
.mt12
{
    margin-top: 12px;
}
.cancel_text
{
    text-decoration: line-through;
}
.insight_market_banner
{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 160px;
    background-image: url(../../assets/images/insights/Markets_banner@2x.svg);
}
.insight_model_banner
{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 160px;
    background-image: url(../../assets/images/insights/Model_Banner_Blue@2x.svg);
}
#content.insight_home
{
    background-color: transparent;
    top: 120px !important;
}
.insight_home_card
{
    background-color: #FFFFFF;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.26);
    width: 50%;
}
#details-form.insight_card_content select
{
    padding: 6px 16px;
    border: 1px solid rgb(186, 194, 205);
    height: 38px;
    font-size: 15px;
    line-height: 24px;
}
.insight_card_content
{
    padding: 24px 32px;
}
.insight_home_card .banner
{
    padding: 25px 32px;
    color: white;
    font-size: 24px;
    line-height: 24px;
}
.model_card:hover
{
    font-family: AmericaMedium;
    font-weight: 500;
}
.model_card
{
    font-size: 15px;
    line-height: 24px;
    color: #1775D2;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #E9EBED;
    cursor: pointer;
}
.blue_0071BB
{
    color: #0071BB;
}
.insight_market
{
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.26);
    background-color: #FFFFFF;
    width: 30%;
    /* float: left; */
    margin-right: 15px;
    margin-bottom: 16px;
    cursor: pointer;
    display: inline-block;
}
.market_image.stroke
{
    background-image: url(../../assets/images/insights/Ucsbuniversitycenterandstorketower.jpg);
}
.market_image.california
{
    background-image: url(../../assets/images/insights/Santa_Barbara_County_Courthouse_CA.jpg);
}
.market_image.ohio
{
    background-image: url(../../assets/images/insights/Ohio_Statehouse_front.jpg);
}
.market_image.wdc
{
    background-image: url(../../assets/images/insights/washington_dc.jpg);
}
.market_image
{
    height: 164px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
.market_meta
{
    padding: 8px 32px;
}
.project_type_wrapper button
{
    overflow: hidden;
}
.project_type_wrapper .filter-option
{
    position: absolute !important;
    top: 7px !important;
    left: 10px !important;
}
.insight-left
{
    right: 30px !important;
}
.current_version_label
{
    background-color: #21BA45 !important;
    border-color: #21BA45 !important;
    color: #FFFFFF !important;
    padding: 4px 13px;
    font-size: 10px;
    border-radius: 3px;
    margin: 0px 10px;
    position: relative;
    top: -1px;
}
.insight_modal .insight_header
{
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    display: flex;
    justify-content: space-between;
}
.insight_modal .insight_content, .insight_modal .insight_footer
{
    line-height: 1.4;
    padding: 2rem;
}
.survey_icons
{
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 35px;
    color: darkgrey;
    display: flex !important;
    justify-content: space-between !important;
}
.survey_icons .align-center, .survey_icons_static .align-center
{
    cursor: pointer;
    outline: none;
}
.survey_icons .frown_div:hover, .survey_icons .frown_div.active, .survey_negative, .survey_icons_static .frown_div.active
{
    color: #E7706B;
}
.survey_icons .smile_div:hover, .survey_icons .smile_div.active, .survey_positive, .survey_icons_static .smile_div.active
{
    color: #88C158;
}
.survey_icons .meh_div:hover, .survey_icons .meh_div.active, .survey_neutral, .survey_icons_static .meh_div.active
{
    color: #FFDD6C;
}
.insight_modal textarea
{
    width: 100%;
    resize: none;
}
.ui.error.message, .ui.attached.error.message
{
    -webkit-box-shadow: 0px 0px 0px 1px #E0B4B4 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 1px #E0B4B4 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0);
}
.ui.message
{
    margin: 1em 0em;
    padding: 1em 1.5em;
    line-height: 1.4285em;
    background-color: #FFF6F6;
    color: #9F3A38;
    border-radius: 0.28571429rem;
}
.reduction_trigger, .strategies_trigger
{
    background-color: white !important;
}
#content.models
{
    top: 0px;
}