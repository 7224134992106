/* ----------- iPhone 4 and 4S starts ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    #performance_report{
      padding-left: 14px;
      padding-right: 14px;
      padding-top: 0px;
    }

    /* Submit for review css starts */

    #submit_for_review div.eligibility_title,
    #submit_for_review div.documentation_title{
        padding-left: 0px;
        padding-right: 0px;
    }

    #submit_for_review div.eligibility_title h3,
    #submit_for_review div.documentation_title h3{
        padding-left: 15px !important;
    }

    #submit_for_review div.score_container{
      margin-left: 0px;
    }

    #submit_for_review .summary_title p{
      padding-left: 5px !important;
    }

    #submit_for_review .summary_data,
    #submit_for_review .req_title_div,
    #submit_for_review .prereq_title_div,
    #submit_for_review .prereq_list_div,
    #submit_for_review .basepoints_title_div,
    #submit_for_review .basepoints_list_div {
      padding-left: 0px !important;
    }

    #submit_for_review .prereq_list_div table,
    #submit_for_review .basepoints_list_div table{
      margin-left: 15px;
    }

    #submit_for_review .prereq_list_div table .credit_desc{
      float: right;
      width: 76%;
    }

    #submit_for_review .basepoints_list_div table .credit_desc{
      float: right;
      width: 71%;
    }

    #submit_for_review .hr-summary-divider{
      margin-left: 0px;
      margin-right: 0px;
    }

    #submit_for_review .pre_cert_info{
        padding-left: 0px;
    }

    #submit_for_review .pre_cert_info h4,
    #submit_for_review .req_title_div p
    #submit_for_review .prereq_title_div h4{
        padding-left: 15px !important;
    }

    #submit_for_review .pre_cert_info p{
        padding-left: 15px !important;
    }

    #submit_for_review .pull-right-container div.gray_bg{
      padding-top: 10px !important;
      padding-left: 25px !important;
      padding-right: 25px !important;
    }

    #submit_for_review .pull-right-container .enter_promo_div input{
      padding-left: 4px !important;
    }

    #submit_for_review .one_time_payment > div{
      padding-right: 10px;
    }

    #submit_for_review .five_time_payment{
      margin-left: 0px;
      margin-top: 15px;
    }

    #submit_for_review .five_time_payment > div{
      padding-right: 10px;
    }

    #submit_for_review .check_payment{
        margin-top: 15px;
        margin-left: 0px;
    }

    #submit_for_review .cc_payment{
        margin-left: 0px;
    }

    #submit_for_review .cc_images{
      margin-top: 40px!important;
    }

    /* Submit for review css ends */

    .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }
    /*  Portfolio pages: project, score and carbon page css starts */

    /*  Portfolio pages: project, score and carbon page css starts  */

    /** New styles.css**/
    .projectHeader-btn--addPortfolio {
        float: none;
        margin-top: 15px;
    }

    .arcTbl--projectCert{
       font-size: 10px;
     }

    .arcTbl--projectCertParent{
        width: 82px;
    }

    #performance_report .total_score{
      font-size: 100px;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (orientation: portrait) {

    #content
    {
      padding: 60px 0px !important;
    }

    .grid{
      width:100%;
    }

    .bill-input{
      width:200px;
    }

    .bill-bar{
      width:200px;
    }

    .bill-select {
      width: 200px;
    }
     
    .bill-container  { 
      font-family:"Open Sans", sans-serif;
      margin-left: 0px;
      margin-top: -30px;
      font-size:16px;
      box-sizing: border-box;
    }

    .bill-cancel-button {
      margin-top: 20px;
    }



    .card-input {
      width:210px;
    }

    .card-bar{
      width: 210px;
    }

    .certification-cards .row{
      padding-left: 10px;
      padding-right: 10px;
    }

    .promo{

    width: 170px !important;
    margin-left: 20px;
    }

    .promostyle{
   vertical-align: top;
   padding-top: 0px;
    }

    .single-article hr {
    margin: 30px -20px 20px;

   margin-left: -30px;
    }

    .totaltext{

   margin-left: 0px;
  }

    .ajaxLoader{
      padding-left: 30%;
    }

    .add-new-data-field{
      margin-left: 10px;
      margin-top: 10px;
    }

    #waste_generated_data_tbl .year-data .w55{
        padding: 5px;
        width: 35px !important;      
    }

    #waste_generated_data_tbl .field-data{
      margin-left: 0px;
    }

    #waste_generated_data_tbl .field-data-value input{
      width: 50px;
    }

    #waste_generated_data_tbl .unit-data input{
      width: 50px;
    }

    #waste_generated_data_tbl .add-new-data-field{
      margin-left: 20px;
    }

    #waste_generated_data_tbl .add-new-data-field .fa-times{
      margin-left: 2px;
    }

    .surveyWrapper{
      left: 0px;
    }

    main.surveyContent{
      padding-left: 15px !important;
      padding-right: 15px !important;
      padding-top: 20px !important;
    }

    #performance_report .arc_logo_colored{
      float: left;
      margin-left: -55px;
      margin-top: -10px;
      background-size: 100px
    }

    #performance_report #carbon_section table{
      font-size: 10px;
    }

    .manage_agreements{
      padding-left: 9px;
    }

    .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }
    /*  Portfolio pages: project, score and carbon page css starts */

    .portfolio-widget{
        padding: 7px 5px;
        -moz-box-shadow: 0px 0px 5px 5px #8888881c;
        -webkit-box-shadow: 0px 0px 5px 5px #8888881c;
        box-shadow: 0px 0px 5px 5px #8888881c;
        height: 75px;
    }

    /*  Portfolio pages: project, score and carbon page css starts  */
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {    
    .ajaxLoader{
      padding-left: 35%;
    }

    #waste_generated_data_tbl .add-new-data-field{
      margin-left: 10px;
      margin-top: 10px;
    }
    .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }
    #occupants-widget div,
    #emissions-widget div,
    #project-widget div,
    #certified-project-widget div,
    #total-certified-widget div,
    #total-floor-widget div,
    #emissions-widget div
     {
        padding-bottom: 2px;
        /*text-align: center;*/
    }
    .portfolio-widget{
        /*padding: 7px 5px;*/
        -moz-box-shadow: 0px 0px 5px 5px #8888881c;
        -webkit-box-shadow: 0px 0px 5px 5px #8888881c;
        box-shadow: 0px 0px 5px 5px #8888881c;
        height: 75px;
    }

}

/* ----------- iPhone 4 and 4S ends ----------- */

/* ----------- iPhone 5 and 5S starts ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px) {    

    .table-project-lists tbody tr{
      height: 65px;
    }

    .table-project-lists tbody tr td.angle-right{
      vertical-align: middle;
    }

    #performance_report .mt70{
      margin-top: 30px;
    }

    .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }
    /*  Portfolio pages: project, score and carbon page css starts */

    

    /*  Portfolio pages: project, score and carbon page css starts  */


    /** New styles.css**/
    .projectHeader-btn--addPortfolio {
        float: none;
        margin-top: 15px;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (orientation: portrait) {

    .survey-card
    {
      padding: 20px 20px !important;
    }

    .survey-logo
    {
      height: 50px;
      background-size: 76%;
      background-position-x: 35%;
    }

    .survey-header
    {
      height: 50px !important;
    }

    .mode-list
    {
      padding: 0px 15px;
    }

    .route-icon
    {
      margin-right: 5px;
    }

    #content
    {
      padding: 60px 0px !important;
    }

    #billings-page section.widget{
        padding-left: 10px;
        padding-right: 10px;
    }

    #billings-page tr,
    #billings-page td{
      font-size: 11px;
    }

    .certification-cards .col-sm-6{
      padding-left: 0px;
      padding-right: 0px;
    }

    .ajaxLoader{
      padding-left: 30%;
    }

    #performance_report table.certification_green .date_awarded,
    #performance_report table.certification_green .other_cert_dates{
      display: none;
    }

    .manage_agreements{
      padding-left: 9px;
    }

    .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }
    
    /*  Portfolio pages: project, score and carbon page css starts */

    .portfolio-widget{
        padding: 7px 5px;
        -moz-box-shadow: 0px 0px 5px 5px #8888881c;
        -webkit-box-shadow: 0px 0px 5px 5px #8888881c;
        box-shadow: 0px 0px 5px 5px #8888881c;
        height: 75px;
    }

    /*  Portfolio pages: project, score and carbon page css starts  */
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (orientation: landscape) {
    .ajaxLoader{
      padding-left: 35%;
    }

    #waste_generated_data_tbl .add-new-data-field{
      margin-left: 15px;
      margin-top: 10px;
    }

    .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }
    #occupants-widget div,
    #emissions-widget div,
    #project-widget div,
    #certified-project-widget div,
    #total-certified-widget div,
    #total-floor-widget div,
    #emissions-widget div
     {
        padding-bottom: 2px;
        /*text-align: center;*/
    }
    .portfolio-widget{
        /*padding: 7px 5px;*/
        -moz-box-shadow: 0px 0px 5px 5px #8888881c;
        -webkit-box-shadow: 0px 0px 5px 5px #8888881c;
        box-shadow: 0px 0px 5px 5px #8888881c;
        height: 75px;
    }
}

/* ----------- iPhone 5 and 5S ends ----------- */

/* ----------- iPhone 6 starts ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    h1,
    .pageHeader--title{
      font-size: 26px;
    }

    .table-project-lists tbody tr{
      height: 65px;
    }

    .table-project-lists tbody tr td.angle-right{
      vertical-align: middle;
    }

    .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }
    /** New styles.css**/
    .projectHeader-btn--addPortfolio {
        float: none;
        margin-top: 15px;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (orientation: portrait) {    

    #content
    {
      padding: 60px 0px !important;
    }
      
    .certification-cards .row{
      padding-left: 10px;
      padding-right: 10px;
    }

    .ajaxLoader{
      padding-left: 30%;
    }

    #performance_report table.certification_green .date_awarded,
    #performance_report table.certification_green .other_cert_dates{
      display: none;
    }

    .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }
    /*  Portfolio pages: project, score and carbon page css starts */

    .portfolio-widget{
        padding: 7px 5px;
        -moz-box-shadow: 0px 0px 5px 5px #8888881c;
        -webkit-box-shadow: 0px 0px 5px 5px #8888881c;
        box-shadow: 0px 0px 5px 5px #8888881c;
        height: 75px;
    }

    /*  Portfolio pages: project, score and carbon page css starts  */

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (orientation: landscape) { 
            
    .ajaxLoader{
      padding-left: 40%;
    }

    #waste_generated_data_tbl .add-new-data-field{
      margin-left: 15px;
      margin-top: 10px;
    }

    .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }
    
    /*  Portfolio pages: project, score and carbon page css starts */

    
    .portfolio-widget{
        /*padding: 7px 5px;*/
        -moz-box-shadow: 0px 0px 5px 5px #8888881c;
        -webkit-box-shadow: 0px 0px 5px 5px #8888881c;
        box-shadow: 0px 0px 5px 5px #8888881c;
        height: 75px;
    }
    #occupants-widget div,
    #emissions-widget div,
    #project-widget div,
    #certified-project-widget div,
    #total-certified-widget div,
    #total-floor-widget div,
    #emissions-widget div
     {
        padding-bottom: 2px;
        /*text-align: center;*/
    }


    /*  Portfolio pages: project, score and carbon page css starts  */
}

/* ----------- iPhone 6 ends ----------- */

/* ----------- iPhone 6 plus starts ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

    /*  Portfolio pages: project, score and carbon page css starts */

    .portfolio-widget{
        padding: 7px 5px;
        -moz-box-shadow: 0px 0px 5px 5px #8888881c;
        -webkit-box-shadow: 0px 0px 5px 5px #8888881c;
        box-shadow: 0px 0px 5px 5px #8888881c;
        height: 75px;
    }

    /*  Portfolio pages: project, score and carbon page css ends  */

    /** New styles.css**/
    .projectHeader-btn--addPortfolio {
        float: none;
        margin-top: 15px;
    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

    #content
    {
      padding: 60px 0px !important;
    }
    .ajaxLoader{
      padding-left: 30%;
    }
    
    #performance_report table.certification_green .date_awarded,
    #performance_report table.certification_green .other_cert_dates{
      display: none;
    }
    /*  Portfolio pages: project, score and carbon page css starts */

    /*  Portfolio pages: project, score and carbon page css starts  */
      
    .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) {       
    .ajaxLoader{
      padding-left: 40%;
    }   

    .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    } 
    /*  Portfolio pages: project, score and carbon page css starts */

    .-font{

        font-family: SourceSansPro-Regular;
        font-weight: 300 !important;
        color: #000000b5;
        font-size: 12px;
    }
    
    .portfolio-widget{
        /*padding: 7px 5px;*/
        -moz-box-shadow: 0px 0px 5px 5px #8888881c;
        -webkit-box-shadow: 0px 0px 5px 5px #8888881c;
        box-shadow: 0px 0px 5px 5px #8888881c;
        height: 75px;
    }
    #occupants-widget div,
    #emissions-widget div,
    #project-widget div,
    #certified-project-widget div,
    #total-certified-widget div,
    #total-floor-widget div,
    #emissions-widget div
     {
        padding-bottom: 2px;
        /*text-align: center;*/
    }

    /*  Portfolio pages: project, score and carbon page css starts  */
}
/* ----------- iPhone 6 plus ends ----------- */

/* ----------- Nexus 5X starts ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 412px) 
  and (max-device-width: 732px){ 

    /*  Portfolio pages: project, score and carbon page css starts */

    

    /*  Portfolio pages: project, score and carbon page css starts  */

    /** New styles.css**/
    .projectHeader-btn--addPortfolio {
        float: none;
        margin-top: 15px;
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 412px) 
  and (max-device-width: 732px){ 

    /* #content
    {
      padding: 60px 0px !important;
    } */

    #performance_report table.certification_green .date_awarded,
    #performance_report table.certification_green .other_cert_dates{
      display: none;
    }

    .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }
    /*  Portfolio pages: project, score and carbon page css starts */

    

    /*  Portfolio pages: project, score and carbon page css starts  */
}

/* Landscape */
@media only screen 
  and (min-device-width: 412px) 
  and (max-device-width: 732px)
  and (orientation: landscape) { 

    /*  Portfolio pages: project, score and carbon page css starts */

    

    /*  Portfolio pages: project, score and carbon page css starts  */

}

/* ----------- Nexus 5X ends ----------- */




/* All mobile devices */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 736px){
  h1,
  .pageHeader--title{
      font-size: 22px;
  }

  .visible-xs-inline{
    display: inline !important;
  }

  .mln20{
    margin-left: 0px;
    margin-top: 15px;
  }

  #apps-page .row .col-md-3{
    margin-top: 35px;
  }

  #apps-page .project-apps{
    height: 235px;
  }

  #apps-page .project-apps div{
    margin-top: 20px !important;    
    margin-bottom: -20px;
  }

  #apps-page #energy_star_title, 
  #apps-page #dropbox_title, 
  #apps-page #onedrive_title, 
  #apps-page #google_title{
    margin-bottom: 0px;
  }

  #city_additional_details .col-xs-3{
    width: 20%;
    font-size: 11px;
  }

  #city_energy_data .col-xs-3,
  #city_energy_data .col-xs-8{
    font-size: 12px;
  }

  .action-cards-xs{
      margin-left: -20px;
      margin-right: -18px;
  }

  #analysis_overall{
    margin-left: 60px;
  }

  #performance_report .category_heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  #performance_report img.analytics_icon {
    height: 25px;
    float: left;
    margin-top: 5px;
    margin-right: 10px;
  }

  #performance_report table.certification_green{
    font-size: 11px;
  }

  .manage_team{
    width: 100%;
  }

  .geocode{
    width: 70%;
    margin-left: 0px;
    margin-bottom: -10px;
  }

  .card_expiry{
    padding-right: 0px;
  }

  .card_cvv{
    padding-left: 0px;
    margin-top: 20px;
  }

  .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }
  /*  Portfolio pages: project, score and carbon page css starts */

    .portfolio-widget-label{

        font-family: SourceSansPro-Regular;
        font-weight: 300 !important;
        color: #000000b5;
        font-size: 10px;
    }

    .portfolio-widget-value{

        font-weight: 500 !important;
        font-size: 14px;
    }
    .portfolio-widget-unit{

        font-size: 8px;
    }

    /*  Portfolio pages: project, score and carbon page css ends  */

    /** New styles.css**/
    .projectHeader-btn--addPortfolio {
        float: none;
        margin-top: 15px;
    }

    .login-container{
        width: 90%;
    }
}


/* iPads (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
  h1,
  .pageHeader--title{
    font-size: 28px;
  }
  .content-wrap
  {
    width: 100%;
  }
  .navbar-default
  {
    width: 100%;
  }

  .split_screen_vertically .card
  {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
  }
  
}

/* iPads (portrait) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
    
    .unpacking_score 
    {
        top: 900px !important;
    }
    
    
  .appLogo{
    background: url(../../assets/images/arc-logo.png) no-repeat;    
    float: left;
    background-position: 49% 0%;
    width: 100%;
    height: 52px;
    margin-top: 20px;
    background-size: 26%;
  }

  .nav-collapsed .appLogo
  {
      background-size: 80%;
  }
  
  .mln20{
    margin-left: 0px;
    margin-top: 15px;
  }

  #apps-page .row{
    margin-top: -20px;
    width: 95%;
    margin-left: 10px;
  }

  #apps-page .row .col-md-3{
    margin-top: 35px;
  }

  #apps-page .project-apps{
    height: 225px;
  }

  #apps-page .project-apps div{
    margin-top: 20px !important;    
    margin-bottom: -20px;
  }

  #apps-page #energy_star_title, 
  #apps-page #dropbox_title, 
  #apps-page #onedrive_title, 
  #apps-page #google_title{
    margin-bottom: 0px;
  }

  /* Submit for review css starts */
  .hr-summary-divider {
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid #ddd;
    margin-left: 10px;
    margin-right: 0px;
  }
  /* Submit for review css ends */
 
  .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }

  /*  Portfolio pages: project, score and carbon page css starts */

    
    /*  Portfolio pages: project, score and carbon page css starts  */
  
}

/* iPads (landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
    
    .unpacking_score 
    {
        top: 720px !important;
    }
    
  .appLogo{
    background: url(../../assets/images/arc-logo.png) no-repeat;
    background-size: 26%;
    float: left;
    background-position: 49% 0%;
    width: 100%;
    height: 52px;
    margin-top: 20px;
  }

  .nav-collapsed .appLogo
  {
      background-size: 60%;
  }

  .hidden-sm {
    display: none !important;
  }

  .manage-project-details .col-md-8{
    width: 100% !important;
  }

  #apps-page .project-apps{
    height: 300px;
  }

  #apps-page #energy_star_img{
    width: 130px;
  }

  #apps-page #dropbox_img,
  #apps-page #onedrive_img,
  #apps-page #google_drive_img{
    width: 125px;
  }

  #certification-div,
  #accomplishments-div{
    width: 100%;
    float: none;
  }

  /* Submit for review css starts */

  #submit_for_review #documentation_nav_item{
    width: 28%;
  }

  #submit_for_review #submit_nav_item{
    width: 22%;
  }

  #submit_for_review .pull-left-container .cert_goal{
    width: 34%;
  }

  #submit_for_review .pull-left-container .current_status{
    width: 24%;
  }

  #submit_for_review .pull-left-container .summary_data .summary_data_status{
    width: 75%;
    padding-left: 20px;
  }

  #submit_for_review .hr-summary-divider {
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid #ddd;
    margin-left: 10px;
    margin-right: 5px;
  }

  #submit_for_review .pull-left-container .credit_desc{
    float: right;
    width: 82%;
  }

   #submit_for_review .pull-left-container .credit_status span{
    float: right;
    width: 66%;
  }

  #submit_for_review .pull-right-container .numOfCreditsSelected_title{
      float: left;
      margin-left: 35px;
      margin-top: -35px;
  }

    #submit_for_review .pull-right-container div.gray_bg{
      padding-top: 10px !important;
      padding-left: 25px !important;
      padding-right: 25px !important;
    }

    #submit_for_review .pull-right-container .enter_promo_div,
    #submit_for_review .pull-right-container .apply_promo_div{
      width: 100%;
    }

    #submit_for_review .pull-right-container .enter_promo_div{
      padding-bottom: 0px !important;
    }

    #submit_for_review .pull-right-container .apply_promo_div{
        padding-left: 20px;
        padding-right: 15px;
    }

    #submit_for_review .pull-right-container .cert_icon{
      padding-left: 5px;
    }

    #submit_for_review .pull-right-container .cert_level_text{
      padding-left: 0px;
      float: right;
      width: 65%;
    }

    #submit_for_review .pull-right-container .total-price-title{
      width: 60%;
    }

    #submit_for_review .pull-right-container .total-price{
      width: 40%;
    }

  /* Submit for review css ends */

  .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }
    
    /*  Portfolio pages: project, score and carbon page css starts */

    #occupants-widget div,
    #emissions-widget div,
    #project-widget div,
    #certified-project-widget div,
    #total-certified-widget div,
    #total-floor-widget div,
    #emissions-widget div
     {
        padding-bottom: 2px;
        /*text-align: center;*/
    }
    .portfolio-widget{
        /*padding: 7px 5px;*/
        -moz-box-shadow: 0px 0px 5px 5px #8888881c;
        -webkit-box-shadow: 0px 0px 5px 5px #8888881c;
        box-shadow: 0px 0px 5px 5px #8888881c;
        height: 100px;
    }

    /*  Portfolio pages: project, score and carbon page css starts  */
}


/** I-PAD PRO **/

/** portrait **/
@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1366px) 
and (orientation : portrait){ 

  /* Submit for review css starts */

  #submit_for_review #documentation_nav_item{
    width: 28%;
  }

  #submit_for_review #submit_nav_item{
    width: 22%;
  }

  #submit_for_review .pull-left-container .cert_goal{
    width: 34%;
  }

  #submit_for_review .pull-left-container .current_status{
    width: 24%;
  }

  #submit_for_review .pull-left-container .summary_data .summary_data_status{
    width: 75%;
    padding-left: 20px;
  }

  #submit_for_review .hr-summary-divider {
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid #ddd;
    margin-left: 10px;
    margin-right: 5px;
  }

  #submit_for_review .pull-left-container .credit_desc{
    float: right;
    width: 82%;
  }

  #submit_for_review .pull-left-container .credit_status span{
    float: right;
    width: 66%;
  }

  #submit_for_review .pull-right-container .numOfCreditsSelected_title{
      float: left;
      margin-left: 35px;
      margin-top: -35px;
  }

    #submit_for_review .pull-right-container div.gray_bg{
      padding-top: 10px !important;
      padding-left: 25px !important;
      padding-right: 25px !important;
    }

    #submit_for_review .pull-right-container .enter_promo_div,
    #submit_for_review .pull-right-container .apply_promo_div{
      width: 100%;
    }

    #submit_for_review .pull-right-container .enter_promo_div{
      padding-bottom: 0px !important;
    }

    #submit_for_review .pull-right-container .apply_promo_div{
        padding-left: 20px;
        padding-right: 15px;
    }

    #submit_for_review .pull-right-container .cert_icon{
      padding-left: 5px;
    }

    #submit_for_review .pull-right-container .cert_level_text{
      padding-left: 0px;
      float: right;
      width: 65%;
    }

    #submit_for_review .pull-right-container .total-price-title{
      width: 60%;
    }

    #submit_for_review .pull-right-container .total-price{
      width: 40%;
    }

  /* Submit for review css ends */

  .split_screen_vertically .card
    {
      height: 100%;width: 100%;margin-bottom: 40px;display: block;
    }
    /*  Portfolio pages: project, score and carbon page css starts */

    

    /*  Portfolio pages: project, score and carbon page css starts  */
  
}

/** landscape **/
@media only screen 
and (min-device-width : 1024px) 
and (max-device-width : 1366px) 
and (orientation : landscape){ 
 

 /* Submit for review css starts */

  #submit_for_review .hr-summary-divider {
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid #ddd;
    margin-left: 10px;
    margin-right: 5px;
  }

    #submit_for_review .pull-right-container div.gray_bg{
      padding-top: 10px !important;
      padding-left: 25px !important;
      padding-right: 25px !important;
    }

    #submit_for_review .pull-right-container .enter_promo_div,
    #submit_for_review .pull-right-container .apply_promo_div{
      width: 100%;
    }

    #submit_for_review .pull-right-container .enter_promo_div{
      padding-bottom: 0px !important;
    }

    #submit_for_review .pull-right-container .apply_promo_div{
        padding-left: 20px;
        padding-right: 15px;
    }

    #submit_for_review .pull-right-container .cert_level_text{
      float: left;
      width: 65%;
      padding-left: 15px;
    }

    #submit_for_review .pull-right-container .total-price-title{
      width: 60%;
    }

    #submit_for_review .pull-right-container .total-price{
      width: 40%;
    }

  /* Submit for review css ends */
    /*  Portfolio pages: project, score and carbon page css starts */
    

    /*  Portfolio pages: project, score and carbon page css starts  */
}

.hamburger
{
	display: none;
  cursor: pointer;
  margin-right: 30px;
}
.home_tab_dropdown
{
  margin: 0px 15px;
  border: 1px solid #ddd;
  padding: 5px 10px;
  background-color: white;
  font-size: 15px;
  width: fit-content;
  text-transform: capitalize;
  display: none;
}
.home_tab_dropdown .dropdown_v2 .dropdown-menu.left
{
  right: inherit !important;
  top: -13px !important;
  left: -11px !important;
}
.home_tab_dropdown .dropdown-menu > .active > a
{
  background-color: #eee !important;
}

.responsive_sidebar_fixed
{
  display: none;
}
.show900, .nav_buttons_dropdown
{
  display: none;
}
#sidebar.responsive
{
  display: none;
}

@media only screen and (max-width: 900px)
{
  .hamburger
  {
    display: block;
  }
  .portfolio-add-res .addProjects--buildingIcon
  {
    margin-top: 0px !important;
  }
  .portfolio-add-res .center-div
  {
    display: inline;
  }
  .portfolio-add-res .blank_port_option
  {
    width: auto;
    margin-bottom: 15px;
  }
  .nav_buttons_dropdown
  {
    display: block;
  }
  .align_suboptions div.option
  {
    font-size: 14px;
    padding-left: 35px !important;
    border: none !important;
  }
  .responsive_sidebar_fixed
  {
    display: block;
  }
  .sidebar.responsive .project_list_options .option
  {
    border-bottom: 1px solid #DDD;
    padding: 10px 25px;
  }
  .sidebar.responsive .project_list_options
  {
    padding-top: 0px;
  }
  .sidebar.responsive
  {
    z-index: 5;
    top: 0px !important;
    width: 75%;
    display: none;
    box-shadow: 0px 1px 9px 0px rgba(0,0,0,0.26);
    overflow: auto;
  }
	.hamburger
	{
		display: block;
  }
  .project_name_banner
  {
    font-size: 14px !important;
    margin-top: 8px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    width: 100%;
  }
  .arc_logo_racetrack
  {
    height: 21px;
    width: 21px;
    background-size: 12px;
  }
	.widget_container iframe.mr15
	{
		margin-right: 0px !important;
	}
	.widget_container iframe
	{
		width: 100%;
	}
  #arcoverview iframe
	{
		height: 280px !important;
	}
	.widget_container
	{
    text-align: center !important;
    margin-left: 0px !important;    
    margin-right: 0px !important;
  }
  .widget_mimic
  {
    width: 100% !important;
  }
	.home_overview
	{
		height: 280px;
	}
	#content
	{
		padding: 0px !important;
		top: 113px;
	}
	.nav_options, .navbar_info .plus_icon, .project_performance .home_tab, .nav_buttons, .portfolio_overviewprojects .home_tab
	{
		display: none;
	}
	.navbar_info h3
	{
		line-height: 34px;
		font-size: 20px;
	}
	.navbar_info
	{
		height: 56px;
		padding-left: 10px;
		padding-right: 10px;
	}
	.navbar_info button
	{
    font-size: 10px;
    padding: 0px 5px 0px 5px;
    height: 35px;
    margin-top: 10px !important;
  }
  .arc_essential_label
  {
    margin-left: 0px !important;
    margin-top: 7px !important;
  }
	.appLogo
	{
		margin: 12px 0px 12px 15px;
	}
	.user_options
	{
		margin-right: 10px;
	}
	.search-wrapper
	{
		left: 55%;
		z-index: 2;
	}
	.search-wrapper.active .input-holder
	{
		width: 440px;
	}
	.widget_container .headings
	{
		width: 100%;
		display: block;
		margin: 0px 0px 16px 0px !important;
	}
	.content-wrap
	{
		left: 0%;
		width: 100%;
	}
	.projectListWrapper
	{
		padding: 20px 5px;
	}
	.project_list_status
	{
		margin-bottom: 10px;
	}
	.plaque_widget
	{
		height: 700px;
  }
  #payment-details #details-form table, .project_info .form
  {
      width: 100%;
  }
  .static_info, .project_info
  {
    width: inherit;
    margin-right: 32px;
  }
  .static_info .w50p
  { 
    width: 100%;
  }
  .static_info .info
  {
    justify-content: space-between;
  }
  .static_info .info .value
  {
    text-align: right;
  }
  .permission_request
  {
    width: 89%;
  }
  .team_invite
  {
    width: 87%;
  }
  .settings_resources
  {
    padding: 16px 15px 0px 0px;
  }
  ul.sidebar-nav li
  {
    font-size: 14px !important;
    border: none !important;
  }
  ul.sidebar-nav
  {
    padding-left: 35px;
  }
  #sidebar-projects.collapse, .home_tab_dropdown
  {
    display: block;
    margin: 15px;
  }
  #payment-details
  {
    margin-top: 0px !important;
  }
  #content.projectRegistrationNew, #content.portfolio_create
  {
    top: 57px !important;
  }
  .top46
  {
      top: 150px !important;
  }
  #ui-version-banner
  {
    font-size: 11px;
    padding: 11px 0px;
  }
  .desktop_flex
  {
      display: block;
  }
  .geolocation-picker
  {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 30px;
  }
  .hide900
  {
    display: none;
  }
  .show900
  {
    display: block;
  }
  .project_zip_code
  {
    width: 100% !important;
    padding-right: 0px !important;
  }
  .leadership_cart .align-right
  {
    text-align: center !important;
    margin-bottom: 10px;
  }
  .leadership_cart .align-left
  {
    text-align: center !important;
  }
  .create_cert {
    position: initial;
  }
  .certificate_pay
  {
    padding-top: 140px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .improvement_web
  {
    height: 535px !important;
  }
  .improvement_web.energy
  {
    height: 950px !important;
  }
  .improvement_web.waste
  {
    height: 740px !important;
  }
  .certificate_reporting_period, .purchase_certificate iframe
  {
    width: 100% !important;
  }
  .certificate_reporting_period .requirements
  {
    display: block !important;
    margin-top: 15px;
  }
  .data_metric {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #D8DCDE;
  }
  .requirements .reading
  {
    padding-top: 5px;
  }
  .requirements .category
  {
    border-top: none;
    font-size: 14px;
  }
  .requirements .value
  {
    font-size: 14px !important;
  }
  .project_list_options
  {
    height: calc(100vh - 50px);
  }
  .qualifiers .fs18
  {
    font-size: 14px !important;
  }
  .qualifiers .mr20
  {
    display: flex;
    margin-right: 0px;
    justify-content: space-between;
  }
  .qualifiers
  {
    display: block !important;
  }
  #certificate_list h4
  {
    margin-bottom: 15px;
  }
  #certificate_list .left-right-align, .res_flex
  {
    display: block !important;
  }

  .widget_container .widget_heading
  {
    text-align: center;
  } 
  .widget_container .headings 
  {
    display: flex;
  }
  .dropdown .fa-angle-down
  {
    right: 0px !important;
    top: 1px !important;
  }
  .cert_banner_img, #inactive_certificate
  {
    width: 100% !important;
  }
  .res_block
  {
    display: block !important;
  }
  #re_entry .h220px 
  {
    height: auto;
    border-bottom: 1px solid rgba(153, 153, 153, 0.2);
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-right: none;
  }
  #re_entry .w30p 
  {
    width: 100%;
  }
  #re_entry .bottom-abs .mb20
  {
    margin-bottom: 5px;
  }
  #re_entry .bottom-abs
  {
    right: 0px;
    top: 0px;
    bottom: initial;
  }
  #re_entry .pl24 
  {
    padding-left: 0px;
  }
  .resw100p
  {
    width: 100% !important;
  }
  .respl32
  {
    padding-left: 32px;
  }
  .arc_pro_purchase 
  {
    top: 34px !important;
  }
  .resml0
  {
    margin-left: 0px !important;
  }
  .project_models .home_tab
  {
    display: none;
  }
}

@media (min-width: 768px){
  .xl-modal{
    width: 98%;
  }
  #advanced_scoring_overview .modal-lg{
    width: 80%;
  }
}

.visible-xs.certification-cards .row
{
  padding: 10px 15px !important;
}
.visible-xs.certification-cards
{
  padding: 0px 20px !important;
}

@media only screen and (max-width: 1000px)
{
    .content-wrap
    {
      left: 0% !important;
      margin-left: 0 !important;
      width: 100% !important;
    }
}